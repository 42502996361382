import { useEffect, useMemo, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-outline';
import barchatFill from '@iconify/icons-eva/bar-chart-fill';
import moreFill from '@iconify/icons-eva/more-horizontal-fill';
import emailFill from '@iconify/icons-eva/email-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import favoriteOutlined from '@iconify/icons-eva/star-outline';
import favoriteFill from '@iconify/icons-eva/star-fill';
import { fr as LocalFr } from 'date-fns/locale';
import { Avatar, Box, Button, IconButton, Stack, Tooltip as MTooltip, Typography, Checkbox } from '@mui/material';
import { Tooltip } from 'antd';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../routes/paths';
import { useDispatch } from 'src/redux/store';
import {
  projectActuSelected,
  selectProject,
  updateProjectColor,
  updateProjectFavorite,
  updateProjectLatestOpen
} from 'src/redux/slices/kanban';
import useToggle, { useToggleV2 } from '../../hooks/useToggle';
import MenuPopover from '../../components/MenuPopover';
import { useSnackbar } from 'notistack';
import { deleteProject } from '../../redux/slices/kanban';
import { MAvatar } from '../../components/@material-extend';
import createAvatar from 'src/utils/createAvatar';
import ProgressView from 'src/components/ProgressView';
import { styled, useTheme } from '@mui/material/styles';
import FolderSvg from '../../helpers/FolderSvg';
import ColorSinglePicker from '../../components/ColorSinglePicker';
import { gDate } from '../../utils/formatTime';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import ActionModal from 'src/components/ActionModal';
import useAuth from 'src/hooks/useAuth';
import { getBaseProjectRights } from 'src/utils/project';
import { baseColors } from 'src/constants/color';
import ProjectMoreOption from './ProjectMoreOption';
import ProjectWorkspaceMoreOption from './ProjectWorkspaceMoreOption';

export const LightTooltip = styled(({ className, ...props }) => (
  <MTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    zIndex: theme.zIndex.drawer + 5,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    maxWidth: 'none',
    fontSize: 11,
    padding: 0,
    margin: 0,
    borderRadius: 2
  }
}));

export const COLOR_OPTIONS = [
  '#2D2C2C',
  '#817F7E',
  '#BFBDBD',
  '#c7c4c4',
  '#091A7A',
  '#1939B7',
  '#3366FF',
  '#84A9FF',
  '#037EB5',
  '#07D7FC',
  '#69FEFD',
  '#CDFEF6',
  '#05D8CA',
  '#07fcd5',
  '#69FECA',
  '#CDFEE4',
  '#548156',
  '#26A11F',
  '#5FE03E',
  '#B0F58B',
  '#B27928',
  '#F8BF51',
  '#FCE196',
  '#FEF7DC',
  '#7A0844',
  '#B7154C',
  '#ff2b4b',
  '#FF847F'
];

const colorFormat = (color, theme) => {
  if (color === 'primary') return theme.palette.primary.main;
  if (color === 'info') return '#0083DB';
  if (color === 'success') return '#1c7e0a';
  if (color === 'warning') return '#FBBE07';
  if (color === 'error') return '#FF8963';
  return '#c7c4c4';
};

export default function ProjectCardSimple({ project, handleOpenSelected, role, user, handleCloseOpenBox, onRemove, space }) {
  const { user: currentUser } = useAuth();
  const rights = getBaseProjectRights(currentUser);

  const [more, openMore, closeMore] = useToggleV2();
  const moreRef = useRef();

  //#region STATE
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const setColorRef = useRef(null);
  const anchorMRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [cColor, setColor] = useState(project?.color || '#c7c4c4');
  const [favorite, setFavorite] = useState(project?.favorite ? project?.favorite[currentUser.id] : false);

  const [pending, setPending] = useState(false);

  useEffect(() => setColor(project?.color), [project?.color]);

  const createEmail = useMemo(() => {
    return project?.createBy?.userEmail || project?.createBy?.email;
  }, [project]);

  const { open: hover, handleClose: closeHover, handleOpen: openHover } = useToggle();
  //eslint-disable-next-line
  const { open, handleClose } = useToggle();
  //eslint-disable-next-line
  const { open: openColors, handleClose: closeColors, handleOpen: handleOpenColors } = useToggle();
  const { open: openD, handleClose: closeDelete, handleOpen: openDelete } = useToggle();
  const [deleteOk, setDeleteOK] = useState(false);
  // eslint-disable-next-line
  const isDisabled = useMemo(() => project?.closed || project?.suspended, [project?.closed, project?.suspended]);
  //#endregion

  //#region FUNCTIONS
  const handleChangeColor = (event) => {
    setColor(event.target.value);
    dispatch(updateProjectColor(project.id, event.target.value));
  };

  const handleSelectProject = () => {
    dispatch(selectProject(project.id));
    dispatch(projectActuSelected(project));
    dispatch(updateProjectLatestOpen(project.id));
    navigate(`${PATH_DASHBOARD.general.tasks}/${project?.id}`, {
      state: {
        projectName: project.name,
        projectState: project?.state
      }
    });
  };

  const handleDelete = () => {
    const callback = () => {
      setPending(false);
      closeColors();
      closeDelete();
      enqueueSnackbar('Projet supprimé avec succès', { variant: 'warning' });
      navigate(PATH_DASHBOARD.general.accueil);
    };
    setPending(true);
    dispatch(deleteProject(project.id, callback));

  };

  const handleClickDeleteBtn = () => {
    openDelete();
    handleCloseOpenBox();
  };

  const addFavorite = () => {
    dispatch(updateProjectFavorite(project.id, !favorite));
    setFavorite((fav) => !fav);
  };

  //#endregion

  return (
    <>
      <Box
        onMouseEnter={openHover}
        onMouseLeave={closeHover}
        sx={{
          position: 'relative',
          transition: (t) =>
            t.transitions.create(['background-color', 'transform'], {
              duration: t.transitions.duration.standard
            }),
          '&:hover': {
            bgcolor: (t) => t.palette.action.hover,
            transform: 'scale(1.05)'
          },
          width: 150,
          cursor: 'pointer',
          height: 210,
          borderRadius: 3,
          pt: 1,
          display: 'flex',
          justifyContent: 'center'
        }}
        ref={anchorRef}
      >
        {(hover || open) && (
          <Stack
            ref={anchorMRef}
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
              alignItems: 'center',
              ...(open && { bgcolor: (t) => t.palette.action.selected })
            }}
          >
            <IconButton ref={moreRef} size="small" onClick={openMore}>
              <Icon icon={moreFill} width={20} height={20} color="#000" />
            </IconButton>
            <ProjectWorkspaceMoreOption
              open={more}
              onClose={closeMore}
              anchorEl={moreRef.current}
              project={project}
              userId={user.id}
              space={space}
            />
          </Stack>
        )}

        <Tooltip
          align={{ points: ['bl', 'br'] }}
          overlayInnerStyle={{ color: 'black' }}
          title={project?.name}
          destroyTooltipOnHide
          mouseEnterDelay={0.3}
          color="white"
        >
          <Stack width={1} alignItems="center">
            <Box>
              <Box onClick={handleSelectProject} sx={{ p: 2.5 }}>
                <FolderSvg color={cColor} />
              </Box>
              <Stack
                onClick={handleSelectProject}
                sx={{
                  position: 'absolute',
                  top: 65,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  alignItems: 'center'
                }}
              >
                <Icon icon={barchatFill} width={35} height={35} color="#fff" />
              </Stack>

              {hover && (
                <Stack
                  sx={{
                    position: 'absolute',
                    top: 101,
                    right: 0,
                    left: 0,
                    alignItems: 'center'
                  }}
                >
                  <LightTooltip
                    title={
                      <>
                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          sx={{
                            position: 'relative',
                            borderRadius: 2,
                            bgcolor: colorFormat(
                              createAvatar(project?.createBy?.displayName || project?.createBy?.name).color,
                              theme
                            ),
                            height: 300,
                            width: 250
                          }}
                        >
                          <Stack width={1} height={1} direction="column" justifyContent="flex-end" alignItems="center">
                            <Avatar
                              alt={project?.createBy?.displayName || project?.createBy?.name}
                              src={project?.createBy?.avatar}
                              sx={{
                                transform: 'translateY(50%)'
                              }}
                            />
                          </Stack>

                          <Stack
                            width={1}
                            height={250}
                            sx={{
                              bgcolor: (t) => t.palette.background.default
                            }}
                          >
                            <Stack width={1} height={1} spacing={1} sx={{ p: 3 }}>
                              <Typography
                                variant="h6"
                                sx={{ color: theme.palette.mode === 'light' ? '#41414d' : '#828294' }}
                              >
                                {' '}
                                {project?.createBy?.displayName || project?.createBy?.name}{' '}
                              </Typography>
                              <Stack direction="row" width={1} height={1} spacing={1}>
                                <Box
                                  component={Icon}
                                  icon={emailFill}
                                  color="#c7c4c4"
                                  sx={{
                                    width: 20,
                                    height: 20,
                                    flexShrink: 0,
                                    mr: 1
                                  }}
                                />
                                <Typography variant="body2" color="text.secondary">
                                  {' '}
                                  {project?.createBy?.email || project?.createBy?.userEmail}{' '}
                                </Typography>
                              </Stack>
                              <Stack direction="row" width={1} height={1} spacing={1}>
                                <Box
                                  component={Icon}
                                  icon={clockFill}
                                  color="#c7c4c4"
                                  sx={{
                                    width: 20,
                                    height: 20,
                                    flexShrink: 0,
                                    mr: 1
                                  }}
                                />
                                <Typography variant="body2" color="text.secondary">
                                  {' '}
                                  {format(gDate(project?.createdAt), 'dd MMM yyyy', { locale: LocalFr })}{' '}
                                </Typography>
                              </Stack>
                            </Stack>
                            <ProgressView value={project?.progress || 0} />
                            <Stack
                              width={1}
                              height={70}
                              sx={{
                                borderRadius: '0 0 10px 10px',
                                p: 2,
                                bgcolor: (t) => t.palette.action.hover
                              }}
                            >
                              <Button
                                fullWidth
                                onClick={handleSelectProject}
                                sx={{ color: '#c7c4c4' }}
                                variant="outlined"
                                color="inherit"
                              >
                                Ouvrir le projet
                              </Button>
                            </Stack>
                          </Stack>
                        </Stack>
                      </>
                    }
                  >
                    <Box onClick={handleSelectProject}>
                      <MAvatar
                        aria-label="recipe"
                        src={project?.createBy?.avatar}
                        alt={project?.createBy?.displayName || project?.createBy?.name}
                        sx={{ width: 27, height: 27, fontSize: 12 }}
                        color={
                          project?.createBy?.avatar
                            ? 'default'
                            : createAvatar(project?.createBy?.displayName || project?.createBy?.name).color
                        }
                      >
                        {createAvatar(project?.createBy?.displayName || project?.createBy?.name).name}
                      </MAvatar>
                    </Box>
                  </LightTooltip>
                </Stack>
              )}
            </Box>
            <Box onClick={handleSelectProject} sx={{ mt: -2 }}>
              <Typography component={'p'} align="center" variant="subtitle2" fontSize={16} className="maxLineText">
                {project?.name.length < 47 ? project?.name : `${project?.name.substring(0, 47)}...`}
              </Typography>
            </Box>

            {!favorite ? (
              (hover || open) && (
                <Stack
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0
                  }}
                >
                  <IconButton onClick={addFavorite}>
                    <Icon icon={favoriteOutlined} color={baseColors.WARINIG} />
                  </IconButton>
                </Stack>
              )
            ) : (
              <Stack
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0
                }}
              >
                <IconButton onClick={addFavorite}>
                  <Icon icon={favoriteFill} color={baseColors.WARINIG} />
                </IconButton>
              </Stack>
            )}
          </Stack>
        </Tooltip>
      </Box>

      <MenuPopover open={openColors} width={260} onClose={closeColors} anchorEl={setColorRef.current}>
        <ColorSinglePicker onChange={handleChangeColor} colors={COLOR_OPTIONS} />
      </MenuPopover>

      {openD && (
        <ActionModal
          title="Supprimer ce projet ?"
          desc="Cette action ne peut pas être annulée"
          color="error"
          open={openD}
          onClose={closeDelete}
          moreAction={
            <Stack direction="row" spacing={1} alignItems="center" sx={{ cursor: 'pointer' }}>
              <Checkbox
                size="small"
                color="error"
                checked={deleteOk}
                onChange={(e) => setDeleteOK(e.currentTarget.checked)}
              />
              <Typography fontSize={14} fontWeight={120} onClick={() => setDeleteOK(!deleteOk)}>
                Je comprends qu'une fois supprimé, ce projet ne peut pas être récupéré.
              </Typography>
            </Stack>
          }
          action={
            <LoadingButton
              loading={pending}
              color="error"
              variant="contained"
              disabled={!deleteOk}
              onClick={handleDelete}
            >
              Supprimer
            </LoadingButton>
          }
        />
      )}
    </>
  );
}

export const NewCardSimpleCreate = ({ handleOpen, title = 'Nouveau projet' }) => {
  return (
    <Box
      onClick={handleOpen}
      sx={{
        transition: (t) =>
          t.transitions.create(['background-color', 'transform'], {
            duration: t.transitions.duration.standard
          }),
        '&:hover': {
          bgcolor: (t) => t.palette.action.hover,
          transform: 'scale(1.05)'
        },
        cursor: 'pointer',
        width: 150,
        height: 210,
        borderRadius: 3,
        display: 'flex',
        pt: 2,
        justifyContent: 'center'
      }}
    >
      <Stack spacing={1} alignItems="center">
        <Box
          sx={{
            bgcolor: (t) => (t.palette.mode === 'light' ? 'white' : t.palette.divider),
            border: (t) => `1px dashed #b7bdc3`,
            borderRadius: 2,
            borderWidth: 2,
            width: 110,
            height: 110,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Icon icon={plusFill} width={40} height={40} color="#b7bdc3" />
        </Box>

        <Typography variant="subtitle2" color="inherit">
          {title}
        </Typography>
      </Stack>
    </Box>
  );
};
