import { createSlice, nanoid } from '@reduxjs/toolkit';
import { filter, omit, min, max, uniq, isObject, uniqBy, values } from 'lodash';
// utils
import firestore from 'src/utils/firestore';
import { changeOnObject } from './../../utils/changeOnObject';
import { auth } from 'src/contexts/FirebaseContext';
import { serverTime } from './../../utils/serverTime';
import {
  ProjectCreateNotification,
  ProjectUpdateNotification,
  taskCommentAddNotification,
  taskCreateNotification,
  taskDeleteNotification,
  taskCreationNotification,
  taskMentionNotification,
  taskMoveNotification,
  // taskDeletionNotification,
  taskUpdateNotification
} from './notifications';
// import { newOnArrayWithEmail } from 'src/utils/newOnArray';
import { distinct } from './../../utils/distinct';
import { gDate } from './../../utils/formatTime';
import { TASK_STATE_VALIDATION, TASK_STATE_PROGRESS, TASK_VISIBILITY, NOTIFICATION_TYPES } from 'src/constants';
import { DEPS_TYPES } from 'src/constants/deps';
import axios from 'axios';
import { getProjectById } from 'src/utils/project';
import { getCurrentUserAccess, getDocChanges, transformUserToAccess } from 'src/helpers/user';
import axiosRequest from 'src/utils/axiosRequest';
import { gProjectId } from 'src/helpers/task';
import { MAIL_CONFIG, NOTIF_CONFIG } from 'src/config';
import { V4_TASK_HISTORY_ACTION } from 'src/constants/task';
import { PROJECT_STATE } from 'src/constants/project';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  convLoading: false,
  isCreatingColumn: false,
  isCardCreating: false,
  projectConversation: {},
  error: false,
  etiquettes: [],
  project: [],
  dashProject: [],
  selectedProject: null,
  currentProject: null,
  sProject: null,
  comments: {},
  models: [],
  currentModel: {
    isUpdate: false,
    title: '',
    desc: '',
    image: 'bg-1',
    cards: {},
    columns: {},
    columnOrder: []
  },
  sortBy: null,
  filterProject: {
    createBy: null,
    projectName: '',
    percent: null,
    dueDate: [null, null],
    assigne: [],
    status: null,
    progress: null
  },
  filtersTask: {
    admin: [],
    assigne: [],
    taskName: [],
    dueDate: [null, null],
    percent: null
  },
  filterLoading: false,
  isApplyFilter: false,
  projectTasksResult: [],
  board: {
    cards: {},
    columns: {},
    columnOrder: []
  },
  allTasks: [],
  taskLoading: false,
  listProjectTask: {}
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START LOADING
    startTaskLoading(state) {
      state.taskLoading = true;
    },
    startFilterLoading(state) {
      state.isLoading = true;
    },
    setIsApplyFilter(state) {
      state.isApplyFilter = true;
    },
    setCloseApplyFilter(state) {
      state.isApplyFilter = false;
    },

    startConvLoading(state) {
      state.convLoading = true;
    },

    startCreationColumn(state) {
      state.isCreatingColumn = true;
    },

    filterProject(state, action) {
      state.filterProject.assigne = action.payload.assigne;
      state.filterProject.createBy = action.payload.createBy;
      state.filterProject.projectName = action.payload.projectName;
      state.filterProject.percent = action.payload.percent;
      state.filterProject.dueDate = action.payload.dueDate;
      state.filterProject.status = action.payload.status;
      state.filterProject.progress = action.payload.progress;
    },

    filterTask(state, action) {
      state.filtersTask.admin = action.payload.admin;
      state.filtersTask.assigne = action.payload.assigne;
      state.filtersTask.taskName = action.payload.taskName;
      state.filtersTask.dueDate = action.payload.dueDate;
      state.filtersTask.percent = action.payload.percent;
    },

    applyFilterProject(state, action) {
      state.filterLoading = false;
      state.projectTasksResult = action.payload;
    },

    getAllTasksSuccess(state, action) {
      state.isLoading = false;
      state.allTasks = action.payload;
    },

    getProjectTasks(state, action) {
      state.taskLoading = false;
      const { projectId, tasks } = action.payload;
      state.listProjectTask[projectId] = tasks;
    },

    setTitle(state, action) {
      state.currentModel.title = action.payload;
    },

    setDesc(state, action) {
      state.currentModel.desc = action.payload;
    },

    setBackImg(state, action) {
      state.currentModel.image = action.payload;
    },

    resetCurrentModel(state) {
      state.currentModel = {
        isUpdate: false,
        title: '',
        desc: '',
        image: 'bg-1',
        cards: {},
        columns: {},
        columnOrder: []
      };
    },
    addModel(state, action) {
      const newColumn = action.payload;
      state.isLoading = false;
      state.currentModel.columns = {
        ...state.currentModel.columns,
        [newColumn.id]: newColumn
      };
      state.currentModel.columnOrder.push(newColumn.id);
    },

    addModelTask(state, action) {
      const { card, columnId } = action.payload;
      state.isLoading = false;
      state.currentModel.cards[card.id] = card;
      state.currentModel.columns[columnId].cardIds.push(card.id);
    },

    upModelTask(state, action) {
      const { card } = action.payload;
      state.isLoading = false;
      state.currentModel.cards[card.id] = card;
    },

    deleteModelTask(state, action) {
      const { cardId, columnId } = action.payload;
      state.isLoading = false;
      state.currentModel.columns[columnId].cardIds = state.currentModel.columns[columnId].cardIds.filter(
        (id) => id !== cardId
      );
      state.currentModel.cards = omit(state.currentModel.cards, [cardId]);
    },

    uploadModelState(state) {
      state.isLoading = false;
      state.currentModel = {
        isUpdate: false,
        title: '',
        image: 'bg-1',
        columns: {},
        columnOrder: []
      };
    },

    getModelSuccess(state, action) {
      state.isLoading = false;
      state.models = action.payload;
    },

    updateModel(state, action) {
      const column = action.payload;
      state.isLoading = false;
      state.currentModel.columns[column.id] = column;
    },

    removeModelCol: (state, action) => {
      state.currentModel.columnOrder = state.currentModel.columnOrder?.filter((id) => action.payload !== id);
    },

    startCardCreating(state) {
      state.isCardCreating = true;
    },

    closeCardCreating(state) {
      state.isCardCreating = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      //console.log(state.error);
      //console.log('message', state.error?.message);
      //console.log('code', state.error?.code);
    },

    getEtiquette(state, action) {
      state.isLoading = false;
      state.etiquettes = action.payload;
    },

    projectSelected(state, action) {
      state.isLoading = false;
      state.selectedProject = action.payload;
    },

    projectActuSelected(state, action) {
      state.isLoading = false;
      state.sProject = action.payload;
    },
    //  DASH PROEJCT
    projectDashProject(state, action) {
      state.isLoading = false;
      state.dashProject = action.payload;
    },

    getCurrentModel(state, action) {
      state.isLoading = false;
      state.currentModel = action.payload;
    },

    getCurrentProject(state, action) {
      state.isLoading = false;
      state.currentProject = action.payload;
      state.selectedProject = action.payload.id;
    },

    // GET PROJECT
    getProjectSuccess(state, action) {
      state.isLoading = false;
      state.project = action.payload;
    },

    addProjectSuccess(state, action) {
      state.isLoading = false;
      // console.log(action?.payload);
      // state.project.unshift(action.payload);
    },

    updateProjectSuccess(state, action) {
      state.isLoading = false;
      const { project } = action.payload;
      var newProject = state.project.filter((pr) => pr.id !== project.id);
      newProject.unshift(project);
      state.project = newProject;
    },

    updateProjectColorSuccess(state, action) {
      state.isLoading = false;
      const { projectId, color } = action.payload;
      state.project = state.project.map((pr) => {
        if (pr.id !== projectId) {
          return { ...pr, color: color };
        }
        return pr;
      });
    },
    updateProjectFavoriteSuccess(state, action) {
      state.isLoading = false;
      const { projectId, favorite } = action.payload;
      state.project = state.project.map((pr) => {
        if (pr.id === projectId) {
          let f = isObject(pr.favorite) ? pr.favorite : {};
          f[auth.currentUser.uid] = favorite;
          return { ...pr, favorite: f };
        }
        return pr;
      });
    },

    //GET CURRENT PROJECT CONVERSATIONS
    projectConversations(state, action) {
      state.convLoading = false;
      const { projectId, add } = action.payload;
      state.projectConversation[projectId] = state.projectConversation[projectId]?.length
        ? [...state.projectConversation[projectId], ...add]
        : [...add];
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      state.board = action.payload;
      let comments = {};
      Object.entries(state.board.cards).map(([key, value]) => {
        return (comments[`${key}`] = { comment: value.comments, cardTitle: value.name, taskId: value?.id });
      });

      state.comments = comments;
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      state.board.columns = {
        ...state.board.columns,
        [newColumn.id]: newColumn
      };
      state.board.columnOrder.push(newColumn.id);
      state.isLoading = false;
      state.isCreatingColumn = false;
    },

    persistCard(state, action) {
      const { columns } = action.payload;
      state.board.columns = columns;
    },

    persistColumn(state, action) {
      state.board.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId } = action.payload;

      state.board.cards[card.id] = card;
      if (state.board.columns[columnId] && !state.board.columns[columnId]?.cardIds?.includes(card.id)) {
        state.board.columns[columnId].cardIds.push(card.id);
        return;
      }
      state.board.columns[columnId] = { id: columnId, cardIds: [], color: '' };
      state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;

      state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter((id) => id !== cardId);
      state.board.cards = omit(state.board.cards, [cardId]);
      state.isLoading = false;
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload;

      state.isLoading = false;

      state.board.columns[column.id] = { ...state.board.columns[column.id], ...column };
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload;

      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);

      state.isLoading = false;
    },

    // DELETE COLUMN
    deleteProjectSuccess(state, action) {
      const { porjectId } = action.payload;

      state.project = state.project.filter((c) => c !== porjectId);

      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;
export const {
  filterProject,
  setIsApplyFilter,
  setCloseApplyFilter,
  getProjectTasks,
  getCurrentProject,
  resetCurrentModel
} = slice.actions;

//#region -------------------------FILTER---------------------------------------------
export function ApplyProjectFilter(projects) {
  return async (dispatch) => {
    dispatch(slice.actions.startFilterLoading());
    try {
      const tasks = [];

      await Promise.all(
        projects.map(async (_project) => {
          const taskDocs = await firestore.collection('tasks').where('projectKey', '==', _project.id).get();
          taskDocs.forEach((doc) => {
            tasks.push({
              projectState: _project.state,
              projectName: _project.name,
              id: doc.id,
              ...doc.data()
            });
          });
        })
      );

      dispatch(slice.actions.applyFilterProject(tasks));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//#endregion

//#region -------------------------ALL BOARD---------------------------------------------

export function getBoardWithSub({ projectId, isSub, onResult, onError }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let subTaskDocs = [],
        taskDocs = [],
        colDocs = [],
        colOrderDocs = null,
        colOrderDocsId = null;

      let _board = {
        columnOrder: colOrderDocs,
        columnOrderId: colOrderDocsId,
        columns: colDocs,
        cards: [...taskDocs, ...subTaskDocs],
        subLoading: true,
        taskLoading: true,
        colLoading: true,
        colOrderLoading: true
      };

      if (isSub) {
        firestore
          .collection('tasks')
          .where('idProject', '==', projectId)
          .orderBy('updatedAt', 'desc')
          .get()
          .then((snap) => {
            if (!snap.empty) {
              snap.docs.forEach((_doc) => subTaskDocs.push({ ..._doc.data(), id: _doc.id }));
            }

            if (onResult) {
              _board = {
                ..._board,
                subLoading: false,
                columnOrder: colOrderDocs,
                columns: colDocs,
                cards: [...taskDocs, ...subTaskDocs]
              };

              onResult(_board);
            }
          });
      }

      firestore
        .collection('tasks')
        .where('projectKey', '==', projectId)
        .orderBy('updatedAt', 'desc')
        .get()
        .then((snap) => {
          if (!snap.empty) {
            snap.docs.forEach((_doc) => taskDocs.push({ ..._doc.data(), id: _doc.id }));
          }

          if (onResult) {
            _board = {
              ..._board,
              taskLoading: false,
              columnOrder: colOrderDocs,
              columns: colDocs,
              cards: [...taskDocs, ...subTaskDocs]
            };

            onResult(_board);
          }
        });

      firestore
        .collection('taskColumns')
        .where('projectKey', '==', projectId)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            snap.docs.forEach((_doc) => colDocs.push({ ..._doc.data(), id: _doc.id }));
          }

          if (onResult) {
            _board = {
              ..._board,
              colLoading: false,
              columnOrder: colOrderDocs,
              columns: colDocs,
              cards: [...taskDocs, ...subTaskDocs]
            };

            onResult(_board);
          }
        });

      await firestore
        .collection('columnOrder')
        .where('projectKey', '==', projectId)
        .get()
        .then((snap) => {
          const data = snap.docs.at(0);
          colOrderDocs = data.data()?.columnOrder || [];

          if (onResult) {
            //console.log({ orderId: data.id });
            _board = {
              ..._board,
              colOrderLoading: false,
              columnOrder: colOrderDocs,
              columnOrderId: data.id,
              columns: colDocs,
              cards: [...taskDocs, ...subTaskDocs]
            };

            onResult(_board);
          }
        });
    } catch (error) {
      console.error(error);
      if (onError) onError();
      dispatch(slice.actions.hasError(error));
    }
  };
}

//#endregion

//#region -------------------------ALL TASKS---------------------------------------------

export const getTaskById = ({ taskId, resolve, reject }) => {
  return async (dispatch, getStore) => {
    try {
      const first = values(getStore()?.kanban?.board?.cards || {}) || [];
      const second = getStore().firestore.ordered[`${taskId}_get_details_for_task`] || [];
      const list = [...first, ...second];
      const find = list?.find((el) => el?.id === taskId);

      if (find) return resolve && resolve(find);

      const snap = await firestore.collection('tasks').doc(taskId).get();

      if (snap.exists) return resolve && resolve({ ...snap.data(), id: snap.id });

      reject && reject('Not found');
    } catch (error) {
      reject && reject();
      console.error(error);
      console.trace();
    }
  };
};

export function getAllTasks(tasks) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getAllTasksSuccess(tasks));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//#endregion

//#region -------------------------MODEL---------------------------------------------

export function getModel(model) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getModelSuccess(model));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setBackgroundImage(img) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setBackImg(img));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setModelTitle(title) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setTitle(title));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function setModelDesc(title) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setDesc(title));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function selectModelToUpdate(model, callback = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getCurrentModel(model));
      if (callback) return callback();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addNewModelColumn(newCol) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.addModel(newCol));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addNewModelTask(newTask) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.addModelTask(newTask));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function UpdateModelTask(upTask) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.upModelTask({ card: upTask }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function DeleteModelTask(cardId, columnId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteModelTask({ cardId, columnId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function UploadNewModel(model, callback = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await firestore.collection('projectModels').add({ ...model, createAt: new Date(), updateAt: new Date() });

      dispatch(slice.actions.uploadModelState());
      if (callback) return callback();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function UploadUpdateModel(model, callback = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await firestore
        .collection('projectModels')
        .doc(model.id)
        .set({ ...model, updateAt: new Date() });
      dispatch(slice.actions.uploadModelState());
      if (callback) return callback();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteModel(modelId, callback = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await firestore.collection('projectModels').doc(modelId).delete();

      if (callback) return callback();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateModel(model, callback = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { id, ...rest } = model;

      await firestore.collection('projectModels').doc(id).update(rest);

      callback && callback();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//#endregion
// -------------------PROJET---------------------------------------------------

export function getDashProject(projects) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.projectDashProject(projects));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//#region -------------------------WORKSPACE----------------------------------
export function createWorkspace({ workspace, callback, onError }) {
  return async (dispatch) => {
    try {
      await firestore.collection('projectWorkspace').add(workspace);
      if (callback) {
        callback();
      }
    } catch (error) {
      if (onError) {
        onError();
      }
      console.error(error);
    }
  };
}

export function updateWorkspace({ id, workspace, callback, onError }) {
  return async (dispatch) => {
    try {
      await firestore.collection('projectWorkspace').doc(id).set(workspace, { merge: true });

      if (callback) {
        callback();
      }
    } catch (error) {
      if (onError) {
        onError();
      }
      console.error(error);
    }
  };
}

export function addProjectToWorkspace({ spaceId, projectIds, callback, onError }) {
  return async (dispatch) => {
    try {
      firestore.collection('projectWorkspace').doc(spaceId).set({ projectIds }, { merge: true });

      if (callback) {
        callback();
      }
    } catch (error) {
      if (onError) {
        onError();
      }
      console.error(error);
    }
  };
}

export function deleteWorkspace({ spaceId, onResolve, onReject }) {
  return (dispatch) => {
    try {
      firestore.collection('projectWorkspace').doc(spaceId).set({ delete: true }, { merge: true });

      onResolve && onResolve();
    } catch (error) {
      onReject && onReject();
      console.error(error);
    }
  };
}

//#endregion

//#region ----------------------REMOVE ACCESS ON PORJECT ID --------------------------------------------------------------------------REMOVE ACCESS ON PORJECT ID --------------------------------------

export function ProjectEvolutionPercent(currentCard) {
  return async (dispatch, getState) => {
    const cards = getState().kanban.board.cards;

    const thisProjectTasks = Object.entries(cards).map(([_, card]) => {
      if (card.id === currentCard?.id) {
        return currentCard;
      }
      return card;
    });

    let totalProgress = 0;
    let list = [];

    thisProjectTasks.forEach((one) => {
      totalProgress += one?.progress || 0;
      list = [...list, gDate(one?.due[0]), gDate(one?.due[1])];
    });
    const finalProgress = totalProgress / thisProjectTasks.length || 0;
    const interval = {
      start: min(list) || null,
      end: max(list) || null
    };

    let notDates = true;
    Object.entries(cards).forEach(([_, card]) => {
      if (card.due[1] && card.due[0]) {
        notDates = false;
        return;
      }
    });

    const dataInterval = notDates ? { start: null, end: null } : interval;

    await firestore
      .collection('project')
      .doc(currentCard?.projectKey || thisProjectTasks[0]?.projectKey)
      .set({ progress: finalProgress, dataInterval, updatedAt: serverTime() }, { merge: true });
  };
}

export function removeAccessId(accessIds, projectId, taskId, callback = null) {
  return async (dispatch) => {
    try {
      if (accessIds.length) {
        var projectRef = firestore.collection('project').doc(projectId);
        await firestore.runTransaction((tranc) => {
          return tranc.get(projectRef).then((sfDoc) => {
            if (sfDoc.exists) {
              const canAccessId = sfDoc.data()?.canAccessId;
              const canAccess = sfDoc.data()?.canAccess;
              let newVal = [...canAccessId];
              let newValAss = [...canAccess];

              accessIds.forEach((ac) => {
                newVal = newVal.filter((one) => one !== ac);
              });

              accessIds.forEach((ac) => {
                newValAss = newVal.filter((one) => one.id !== ac);
              });
              tranc.update(projectRef, { canAccessId: [...newVal], canAccess: [...newValAss] });
            }
          });
        });

        var taskRef = firestore.collection('tasks').doc(taskId);
        await firestore.runTransaction((tranc) => {
          return tranc.get(taskRef).then((sfDoc) => {
            if (sfDoc.exists) {
              const canAccessId = sfDoc.data()?.canAccessId;
              let newVal = [...canAccessId];
              accessIds.forEach((ac) => {
                newVal = newVal.filter((one) => one !== ac);
              });
              tranc.update(taskRef, { canAccessId: [...newVal] });
            }
          });
        });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function selectProject(project) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.projectSelected(project));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//#endregion

//#region -------------------PROJET------------------------------------------------------------PROJECT--------------------------------------------------------------------------PROJECT------

export function projectActuSelected(project) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.projectActuSelected(project));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createProject(project, callback = null, onError = null) {
  //TODO set rights to true
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { model, id, ...rest } = project;
      const { displayName, email, photoURL, uid } = auth.currentUser;

      let canAccessId = rest.canAccess.map((pers) => pers?.id);
      let managerIds = rest?.managers?.map((_one) => _one.id) || [];

      canAccessId = [...canAccessId, uid || ''];
      canAccessId = uniqBy([...canAccessId, ...managerIds]);

      const projectKey = id || nanoid();

      //console.log({ project , canAccessId, managerIds});

      await firestore
        .collection('project')
        .doc(projectKey)
        .set(
          {
            ...rest,
            canAccessId,
            canAccess: [...rest?.canAccess],
            managers: [...rest?.managers],
            createBy: {
              id: uid,
              userEmail: email,
              displayName: displayName,
              avatar: photoURL
            },
            state: PROJECT_STATE.OPEN,
            createdAt: serverTime(),
            updatedAt: serverTime(),
            lastOpen: serverTime()
          },
          { merge: true }
        );

      let colOrders = [];

      if (model) {
        const batch = firestore.batch();

        model.columnOrder.forEach((colId) => {
          const { id: oldId, ...col } = model.columns[colId];

          const colRef = firestore.collection('taskColumns').doc();

          colOrders.push(colRef.id);
          batch.set(colRef, { ...col, cardIds: [], projectKey });
        });

        await batch.commit();
      }

      await firestore.collection('columnOrder').doc(projectKey).set({ columnOrder: colOrders, projectKey });

      dispatch(slice.actions.addProjectSuccess({ ...rest, id: projectKey }));
      dispatch(ProjectCreateNotification({ project: { ...project, id: projectKey, canAccessId, managerIds } }));

      if (callback) return callback(projectKey);
    } catch (error) {
      console.error(error);
      if (onError) onError();
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateProject(project, oldProject, callback = null) {
  return async (dispatch) => {
    // console.log({ project, oldProject });
    dispatch(slice.actions.startLoading());
    try {
      const { id, canAccess, managers, ...rest } = project;
      let access = [...canAccess];
      const isOn = project?.canAccess?.find((one) => one.id === project?.createBy?.id);
      let canAccessId = access.map((pers) => pers.id);

      if (!isOn) {
        const { createBy } = project;
        canAccessId = [...canAccessId, createBy?.id];

        access = [
          ...canAccess,
          {
            name: createBy?.displayName || createBy?.name,
            displayName: createBy?.displayName || createBy?.name,
            email: createBy?.userEmail || createBy?.email,
            avatar: createBy?.avatar,
            id: createBy?.id
          }
        ];
      }

      let managerIds = managers?.map((_one) => _one.id) || [];
      canAccessId = uniqBy([...canAccessId, ...managerIds]);

      await firestore
        .collection('project')
        .doc(id)
        .set(
          {
            ...rest,
            canAccessId,
            canAccess: uniqBy(access, 'id'),
            managers: uniqBy(managers, 'id'),
            updatedAt: serverTime()
          },
          { merge: true }
        );

      dispatch(slice.actions.updateProjectSuccess({ project }));
      dispatch(
        ProjectUpdateNotification({
          project: {
            ...project,
            canAccessId,
            canAccess: uniqBy(access, 'id'),
            managers: uniqBy(managers, 'id'),
            managerIds
          },
          oldProject
        })
      );

      if (callback) return callback();
    } catch (error) {
      ``;
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProjectColor(projectId, color, callback = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await firestore.collection('project').doc(projectId).set(
        {
          color: color,
          updatedAt: serverTime()
        },
        { merge: true }
      );

      dispatch(slice.actions.updateProjectColorSuccess({ projectId, color }));

      if (callback) return callback();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProjectFavorite(projectId, favorite, callback = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const updateRef = firestore.collection('project').doc(projectId);

      await firestore.runTransaction(async (tranc) => {
        const sfDoc = await tranc.get(updateRef);

        if (sfDoc.exists) {
          let favs = isObject(sfDoc.data()?.favorite) ? sfDoc.data()?.favorite : {};
          favs[auth.currentUser.uid] = favorite;
          tranc.update(updateRef, { favorite: favs, updatedAt: serverTime() });
        }
      });

      dispatch(slice.actions.updateProjectFavoriteSuccess({ projectId, favorite }));

      if (callback) return callback();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProjectLatestOpen(projectId, callback = null) {
  return async (dispatch) => {
    try {
      await firestore.collection('project').doc(projectId).set(
        {
          lastOpen: serverTime()
        },
        { merge: true }
      );

      if (callback) return callback();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function duplicateProject({ projectId, duplicate, user, callback, stop }) {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${NOTIF_CONFIG.fb_notif}/duplicate-project`, {
        projectId,
        duplicate,
        user
      });

      stop();
      if (res.status === 200) {
        callback && callback();
      }
    } catch (error) {
      stop();
      console.error(error);
    }
  };
}

const callback = () => {};

export function inviteToProjectByMail(users = [], project, onResolve = callback, onReject = callback) {
  return async () => {
    try {
      const from = MAIL_CONFIG.from;
      const subject = MAIL_CONFIG.from;
      const domaine = window.location.host;

      const person = transformUserToAccess(getCurrentUserAccess());

      const emit = async (user) => {
        const invite = {
          id: nanoid(),
          email: user?.email,
          createdAt: serverTime(),
          createdBy: person,
          used: false,
          projectId: project?.id
        };

        const title = `Bienvenue sur ${subject} Management.`;
        const description = ` ${person?.name} vous invite à rejoindre le projet  ${project?.name}.`;
        const accessLink = `https://${domaine}/auth/invite/${invite?.id}`;

        const data = {
          description: description,
          name: user?.name || user?.displayName,
          salutation: 'BONJOUR',
          link: accessLink,
          subject: subject,
          header: 'Invitation'
        };

        await firestore.collection('invites').doc(invite?.id).set(invite, { merge: true });

        await axiosRequest.post('/mail/template', {
          to: user?.email,
          from,
          templateId: MAIL_CONFIG.template.members,
          data
        });
      };

      for (const el of users) {
        await emit(el);
      }

      onResolve();
    } catch (error) {
      console.error(error);
      onReject(error);
    }
  };
}

export const rejectInvite = async (invite) => {
  try {
    await firestore.collection('invites').doc(invite?.id).set({ used: true }, { merge: true });
  } catch (e) {
    //console.log(e);
  }
};

export const ProjectAssignedUpdate = (projectId, users = [],userId, onResolve = callback, onReject = callback) => {
  return async (dispatch) => {
    try {
      const snap = firestore.collection('project').doc(projectId);

      await firestore.runTransaction(async () => {
        return snap.get().then(async (doc) => {
          const project = doc.data();
          const canAccess = project?.canAccess || [];
          const newCanAccess = [...canAccess, ...users];
          const canAccessId = uniq([...newCanAccess.map((item) => item.id), userId]);
          await snap.update({ canAccess: uniqBy(newCanAccess, 'id'), canAccessId });
          return Promise.resolve();
        });
      });

      onResolve();
    } catch (e) {
      //console.log(e);
      onReject();
    }
  };
};

export const registerNewProjectAssiged = (invite, users = [], onResolve = callback, onReject = callback) => {
  return async (dispatch) => {
    try {
      const snap = await firestore.collection('project').doc(invite?.projectId).get();

      if (snap.exists) {
        const project = { ...snap.data(), id: snap.id };
        const canAccessId = [...(project?.canAccess || []), ...(project?.managers || []), project?.createBy]?.map(
          (el) => el?.id
        );

        const newProject = {
          ...project,
          canAccess: uniqBy([...(project?.canAccess || []), ...users], 'id'),
          canAccessId: uniqBy([...canAccessId, ...users?.map((el) => el?.id)], 'id')
        };

        dispatch(updateProject(newProject, project));
        if (invite?.id) {
          await firestore.collection('invites').doc(invite?.id).set({ used: true }, { merge: true });
        }
      }

      onResolve();
    } catch (e) {
      //console.log(e);
      onReject();
    }
  };
};

// ----------------------------------------------------------------------

export function deleteProject(porjectId, callback = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await firestore.collection('project').doc(porjectId).delete();
      dispatch(slice.actions.deleteProjectSuccess(porjectId));

      if (callback) return callback();

      let task_toDelete = await firestore.collection('tasks').where('projectKey', '==', porjectId).get();
      let column_toDelete = await firestore.collection('columnOrder').where('projectKey', '==', porjectId).get();
      let columnOder_toDelete = await firestore.collection('taskColumns').where('projectKey', '==', porjectId).get();

      let deleteBatch = firestore.batch();

      if (!task_toDelete.empty) {
        task_toDelete.docs.forEach((doc) => {
          deleteBatch.delete(doc.ref);
        });
      }

      if (!column_toDelete.empty) {
        column_toDelete.docs.forEach((doc) => {
          deleteBatch.delete(doc.ref);
        });
      }

      if (!columnOder_toDelete.empty) {
        columnOder_toDelete.docs.forEach((doc) => {
          deleteBatch.delete(doc.ref);
        });
      }

      deleteBatch.commit();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllProjects(porjects) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProjectSuccess(porjects));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBoard(board) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getBoardSuccess(board));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProjectEtiquettes(etiquettes) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getEtiquette(etiquettes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//#endregion

//#region ---------------------------COLUMNS--------------------------------------------COLUMNS-----------------------------------------------------COLUMNS-------------------------------------------

export function createColumn(newColumn, callback = null, onFirst = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startCreationColumn());
    try {
      const id = newColumn?.id || nanoid();

      let newCol = {
        ...newColumn,
        cardIds: []
      };

      const { currentUser } = auth;

      dispatch(slice.actions.createColumnSuccess({ ...newCol, id }));

      await firestore.runTransaction(async (tranc) => {
        const colRef = firestore.collection('taskColumns').doc(id);
        const colNumRef = firestore.collection('columnOrder').doc(newCol.projectKey);
        const sfDoc = await tranc.get(colNumRef);

        if (!sfDoc.exists) {
          const colNumDcSecond = await firestore
            .collection('columnOrder')
            .where('projectKey', '==', newCol.projectKey)
            .get();
          if (!colNumDcSecond.empty) {
            const findTheId = colNumDcSecond.docs.at(0).id;
            const colNumRefSecond = firestore.collection('columnOrder').doc(findTheId);

            const sfDocSecond = await tranc.get(colNumRefSecond);
            if (sfDocSecond.exists) {
              let colNum = sfDocSecond.data().columnOrder;
              let newColOrder = onFirst ? [id, ...colNum] : [...colNum, id];

              tranc.update(colNumRefSecond, { columnOrder: uniq(newColOrder) });
            }
          }
        }
        if (sfDoc.exists) {
          let colNum = sfDoc.data().columnOrder;
          let newColOrder = onFirst ? [id, ...colNum] : [...colNum, id];

          tranc.update(colNumRef, { columnOrder: uniq(newColOrder) });
        }
        tranc.set(
          colRef,
          {
            ...newCol,
            by: {
              id: currentUser.uid,
              name: currentUser.displayName,
              email: currentUser.email
            }
          },
          { merge: true }
        );
      });

      if (callback) return callback({ ...newCol, id });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId, updateColumn, callback = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    //console.log({ columnId, updateColumn });
    try {
      const { currentUser } = auth;
      await firestore
        .collection('taskColumns')
        .doc(columnId)
        .set(
          {
            ...updateColumn,
            updateBy: {
              id: currentUser.uid,
              name: currentUser.displayName,
              email: currentUser.email
            }
          },
          { merge: true }
        );
      dispatch(slice.actions.updateColumnSuccess({ id: columnId, ...updateColumn }));

      if (callback) return callback();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId, projectKey, callback = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteColumnSuccess({ columnId }));

      const colNumRef = firestore.collection('columnOrder').doc(projectKey);

      await firestore.runTransaction((tranc) => {
        return tranc.get(colNumRef).then((sfDoc) => {
          if (sfDoc.exists) {
            let colNum = sfDoc.data().columnOrder;
            let newCol = filter(colNum, (id) => id !== columnId);
            tranc.update(colNumRef, { columnOrder: [...newCol] });
          }
        });
      });

      if (callback) {
        callback();
      }

      const next = async () => {
        const ref = firestore.collection('taskColumns').doc(columnId);

        const colSnap = await ref.get();
        const cardIds = colSnap.data()?.cardIds || [];

        for (const id of cardIds) {
          const cardSnap = await firestore.collection('tasks').doc(id).get();

          if (cardSnap.exists) {
            const card = { ...cardSnap.data(), id: cardSnap.id };
            dispatch(deleteTask({ card, cardId: card?.id, parentId: card?.parentId }));
          }
        }

        await ref.delete();
      };

      setTimeout(next, 10000);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder, orderId) {
  return async (dispatch) => {
    dispatch(slice.actions.persistColumn(newColumnOrder));

    const colNumRef = firestore.collection('columnOrder').doc(orderId);

    await firestore.runTransaction(async (tranc) => {
      const sfDoc = await tranc.get(colNumRef);
      if (sfDoc.exists) {
        tranc.update(colNumRef, { columnOrder: newColumnOrder });
      }
    });
  };
}

//#endregion

//#region ----------------------------CARD-------------------------------------------CARDS-----------------------------------------------CARDS------------------------------------------

const notifyDepsForCarUpdate = async (card) => {
  const tSave = {
    id: card?.id,
    name: card?.name,
    due: card?.due,
    assignee: card?.assignee,
    state: card?.state || null
  };

  const notify = async (list, target) => {
    for (const task of list) {
      await firestore.runTransaction(async (transaction) => {
        const ref = firestore.collection('tasks').doc(task?.id);
        const doc = await transaction.get(ref);
        if (doc.exists) {
          const dependences = doc.data()?.dependences || {};
          const data = dependences[target] || [];
          const prev = [...data]?.filter((el) => el?.id !== tSave?.id);
          const newDeps = uniqBy([...prev, tSave], 'id');

          transaction.update(ref, {
            dependences: {
              ...dependences,
              [target]: newDeps
            }
          });
        }
      });
    }
  };

  const changes = card?.dependences || {};

  const pendingTasks = changes[DEPS_TYPES.PENDING] || [];
  await notify(pendingTasks, DEPS_TYPES.LOCK);

  const lockedTasks = changes[DEPS_TYPES.LOCK] || [];
  await notify(lockedTasks, DEPS_TYPES.PENDING);

  const linkedTasks = changes[DEPS_TYPES.TASK] || [];
  await notify(linkedTasks, DEPS_TYPES.TASK);
};

export function closeTaskDeps(card, callback = null) {
  return async (dispatch) => {
    try {
      const changes = card?.dependences || {};
      const pendingTasks = changes[DEPS_TYPES.PENDING] || [];

      for (const task of pendingTasks) {
        const snap = await firestore.collection('tasks').doc(task?.id).get();

        if (snap.exists) {
          const data = snap.data();

          const tSave = {
            id: task?.id,
            name: data?.name,
            due: data?.due,
            assignee: data?.assignee,
            canAccessId: data?.canAccessId || [],
            responsable: data?.responsable || [],
            createdBy: data?.createdBy,
            state: TASK_STATE_VALIDATION.DONE,
            dependences: data?.dependences || {}
          };

          dispatch(updateCard(tSave));
        }
      }

      const tSave = {
        id: card?.id,
        name: card?.name,
        due: card?.due,
        assignee: card?.assignee,
        canAccessId: card?.canAccessId || [],
        responsable: card?.responsable || [],
        createdBy: card?.createdBy,
        state: TASK_STATE_VALIDATION.INPROGRESS,
        dependences: card?.dependences || {}
      };
      dispatch(updateCard(tSave));

      callback && callback();
    } catch (e) {
      //console.log(e);
    }
  };
}

export function updateCard(card, callback = null, prevCard = null) {
  return async (dispatch, getState) => {
    try {
      const { id, mentions = [], titleMentions = [], managers = [], ...rest } = card;

      const cardName = rest?.name?.replace(/<[^>]+>/g, '');

      const editCard = { ...rest, name: cardName };

      dispatch(ProjectEvolutionPercent(card));

      const {
        kanban: {
          board: { cards: cardList },
          currentProject,
          sProject
        }
      } = getState();

      const oldCard = cardList[id];
      const canAccessIdAssign = card?.assignee?.map((one) => one.id) || [];
      let canAccessId = [...canAccessIdAssign, card.createdBy?.id];
      canAccessId = canAccessId.filter(distinct);

      const theOld = prevCard || oldCard;
      const change = changeOnObject(theOld, card);

      let completed = false;

      let metadata = {};
      await notifyDepsForCarUpdate(card);

      if (card?.state === TASK_STATE_VALIDATION.INPROGRESS) {
        metadata = {
          ...metadata,
          startDate: serverTime(),
          startBy: {
            id: auth.currentUser?.uid,
            name: auth.currentUser?.displayName,
            email: auth.currentUser?.email,
            avatar: auth.currentUser?.photoURL
          }
        };
      }

      if (card?.state === TASK_STATE_VALIDATION.ACCEPTED) {
        completed = true;
        metadata = {
          ...metadata,
          acceptDate: serverTime(),
          acceptBy: {
            id: auth.currentUser?.uid,
            name: auth.currentUser?.displayName,
            email: auth.currentUser?.email,
            avatar: auth.currentUser?.photoURL
          }
        };
      }

      if (card?.state === TASK_STATE_VALIDATION.DONE) {
        metadata = {
          ...metadata,
          done: {
            doneDate: serverTime(),
            doneBy: {
              id: auth.currentUser?.uid
            }
          }
        };
      }

      if (card?.state === TASK_STATE_VALIDATION.REJECTED) {
        metadata = {
          ...metadata,
          doneDate: null,
          rejectDate: serverTime(),
          rejectBy: {
            id: auth.currentUser?.uid,
            name: auth.currentUser?.displayName,
            email: auth.currentUser?.email,
            avatar: auth.currentUser?.photoURL
          }
        };
      }

      let assigneByIds = editCard?.assigneByIds || [];

      if (change.length && change.find((one) => one?.assignee || one?.responsable)) {
        if (editCard?.responsable !== null && editCard?.responsable?.id !== theOld?.responsable?.id) {
          assigneByIds = uniq([auth.currentUser.uid, ...assigneByIds]);
        }

        if (change.filter((_one) => _one?.assignee)) {
          const exist = change.filter((_one) => _one?.assignee);

          if (exist[0]?.assignee?.length) {
            let newAss = exist[0]?.assignee;
            newAss = newAss.filter((_on) => !theOld?.assignee?.find((_n) => _n?.id === _on.id));
            if (newAss?.length) {
              assigneByIds = uniq([auth.currentUser.uid, ...assigneByIds]);
            }
          }
        }
      }

      const assigneIds = editCard?.assignee ? editCard?.assignee?.map((_one) => _one.id) : [];

      const updateCard = {
        ...editCard,
        name: cardName,
        canAccessId,
        completed,
        deadLine: card.due[1],
        updatedAt: serverTime(),
        metadata,
        assigneIds,
        assigneByIds,
        progress: TASK_STATE_PROGRESS[card?.state] || 0,
        _titleMentions: titleMentions,
        _mentions: mentions,
        managers: managers
      };

      const _projectKey = currentProject?.id || sProject?.id || updateCard?.projectKey || updateCard?.idProject;

      dispatch(
        taskUpdateNotification({
          card: { ...card, name: cardName, projectKey: _projectKey, managers },
          change,
          projectName: currentProject?.name || sProject?.name || '',
          theOld
        })
      );

      if (mentions?.length !== 0) {
        const { added } = getDocChanges(card?._mentions || [], mentions);

        dispatch(
          taskMentionNotification({
            canReceived: added,
            projectKey: _projectKey,
            projectName: currentProject?.name,
            taskId: card?.id,
            taskName: cardName,
            target: 'dans la description'
          })
        );
      }

      if (titleMentions?.length !== 0) {
        const { added } = getDocChanges(card?._titleMentions || [], titleMentions);

        dispatch(
          taskMentionNotification({
            canReceived: added,
            projectKey: _projectKey,
            projectName: currentProject?.name || sProject?.name || '',
            taskId: card?.id,
            taskName: cardName,
            target: 'dans le titre'
          })
        );
      }

      const { subTasksCount, ...cardWithoutSubCount } = updateCard;

      await firestore.collection('tasks').doc(id).set(cardWithoutSubCount, { merge: true });

      if (callback) {
        callback();
      }

      if (updateCard && prevCard && Number(updateCard?.budget) !== Number(prevCard?.budget)) {
        const count = (updateCard?.budget || 0) - (prevCard?.budget || 0);

        const _buffer = { ...prevCard, ...updateCard };
        const id = gProjectId(_buffer);

        dispatch(updatedProjectUsedBudget(count, id));
      }

      //SAVE CHANGE OF THE TASK
      if (change.length) {
        await firestore
          .collection('tasks')
          .doc(id)
          .collection('actions')
          .add({
            change,
            by: {
              id: auth.currentUser.uid,
              email: auth.currentUser.email,
              displayName: auth.currentUser.displayName
            },
            createAt: serverTime()
          });

        const rappelChange = change.find((_ch) => _ch?.rappels || false);

        if (rappelChange) {
          let newRappel = rappelChange?.rappels || [];

          if (newRappel.length) {
            const rappelBatch = firestore.batch();
            newRappel.forEach((_rap) => {
              const _rappRef = firestore.collection('tasks').doc(id).collection('rappels').doc(_rap.id);
              rappelBatch.set(
                _rappRef,
                {
                  ..._rap,
                  next: _rap?.date,
                  taskId: card?.id,
                  taskName: cardName,
                  projectId: card?.projectKey,
                  projectName: currentProject?.name || sProject?.name
                },
                { merge: true }
              );
            });

            await rappelBatch.commit();
          }
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const updatedProjectUsedBudget = (amount, projectId) => {
  return async () => {
    await firestore.runTransaction(async (transaction) => {
      const ref = firestore.collection('project').doc(projectId);
      const doc = await transaction.get(ref);
      if (!doc.exists) return;
      const budget = doc.data()?.budget;
      if (!budget) return;
      const used = (budget?.used || 0) + amount;

      transaction.set(ref, { budget: { ...budget, used } }, { merge: true });
    });
  };
};

export const deleteCard = (card, callback) => {
  return async (dispatch) => {
    try {
      await firestore.collection('tasks').doc(card?.id).delete();

      dispatch(ProjectEvolutionPercent(null));

      if (card != null) {
        const tSave = {
          id: card?.id,
          name: card?.name,
          due: card?.due,
          assignee: card?.assignee,
          state: card?.state || null
        };
        const changes = card?.dependences || {};
        const pendingTasks = { removed: changes[DEPS_TYPES.PENDING] || [], added: [] };
        await exeDepsChange(pendingTasks, DEPS_TYPES.LOCK, tSave);

        const lockedTasks = { removed: changes[DEPS_TYPES.LOCK] || [], added: [] };
        await exeDepsChange(lockedTasks, DEPS_TYPES.PENDING, tSave);

        const linkedTasks = { removed: changes[DEPS_TYPES.TASK] || [], added: [] };
        await exeDepsChange(linkedTasks, DEPS_TYPES.TASK, tSave);
      }

      dispatch(updatedProjectUsedBudget((card?.budget || 0) * -1, gProjectId(card)));

      callback && callback();

      if (card?.parentId)
        await firestore.runTransaction(async (transaction) => {
          const ref = firestore.collection('tasks').doc(card?.parentId);
          const snap = await transaction.get(ref);
          if (snap.exists) {
            const cardIds = (snap.data()?.cardIds || [])?.filter((id) => id !== card?.id);
            transaction.set(ref, { cardIds }, { merge: true });
          }
        });
    } catch (e) {
      console.error(e);
    }
  };
};

const exeDepsChange = async (changeSnap, typeTarget, tSave) => {
  if (changeSnap?.added?.length !== 0) {
    for (const task of changeSnap?.added) {
      await firestore.runTransaction(async (transaction) => {
        const ref = firestore.collection('tasks').doc(task?.id);
        const doc = await transaction.get(ref);
        if (doc.exists) {
          const dependences = doc.data()?.dependences || {};
          const data = dependences[typeTarget] || [];
          const newDeps = uniqBy([...data, tSave], 'id');

          transaction.update(ref, {
            dependences: {
              ...dependences,
              [typeTarget]: newDeps
            }
          });
        }
      });
    }
  }

  if (changeSnap?.removed?.length !== 0) {
    for (const task of changeSnap?.removed) {
      await firestore.runTransaction((transaction) => {
        const ref = firestore.collection('tasks').doc(task?.id);
        return transaction.get(ref).then((doc) => {
          if (doc.exists) {
            const dependences = doc.data()?.dependences || {};
            const data = dependences[typeTarget] || [];
            const newDeps = uniqBy(
              [...data]?.filter((el) => el?.id !== tSave?.id),
              'id'
            );

            transaction.update(ref, {
              dependences: {
                ...dependences,
                [typeTarget]: newDeps
              }
            });
          }
        });
      });
    }
  }
};

export const addDepsToTask = (task, deps, toSave, changes, callback) => {
  return async (dispatch) => {
    try {
      const result = changeOnObject(task?.dependences || {}, toSave);
      //console.log('change', result);

      await firestore.collection('tasks').doc(task?.id).update({ dependences: toSave, updatedAt: serverTime() });
      // const batch = firestore.batch();

      const tSave = {
        id: task?.id,
        name: task?.name,
        due: task?.due,
        state: task?.state || null,
        assignee: task?.assignee
      };

      const pendingTasks = changes[DEPS_TYPES.PENDING] || {};
      await exeDepsChange(pendingTasks, DEPS_TYPES.LOCK, tSave);

      const lockedTasks = changes[DEPS_TYPES.LOCK] || {};
      await exeDepsChange(lockedTasks, DEPS_TYPES.PENDING, tSave);

      const linkedTasks = changes[DEPS_TYPES.TASK] || {};
      await exeDepsChange(linkedTasks, DEPS_TYPES.TASK, tSave);

      if (callback) return callback();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
};

// ----------------------------------------------------------------------

export function persistCard(columns, start, finish) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.persistCard(columns));

      const batch = firestore.batch();
      const startRef = firestore.collection('taskColumns').doc(start.id);
      batch.update(startRef, { cardIds: uniq(start.cardIds) });

      if (start.id !== finish.id) {
        const finshRef = firestore.collection('taskColumns').doc(finish.id);
        batch.update(finshRef, { cardIds: uniq(finish.cardIds) });
      }

      await batch.commit();

      if (start.id !== finish.id) {
        dispatch(
          taskMoveNotification({
            card: start?.card,
            project: start?.project,
            oldOperationName: start?.name,
            newOperationName: finish?.name
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addTaskToOperation(taskId, colId, callback = null) {
  return async () => {
    try {
      const taskColRef = firestore.collection('taskColumns').doc(colId);
      await firestore.runTransaction(async (tranc) => {
        return tranc.get(taskColRef).then((sfDoc) => {
          if (sfDoc.exists) {
            let cardIds = sfDoc.data().cardIds;
            tranc.update(taskColRef, { cardIds: uniq([...cardIds, taskId]) });
          }
        });
      });
      if (callback) callback();
    } catch (e) {
      //console.log(e);
    }
  };
}

// ----------------------------------------------------------------------

export function addTask(task, callback = null, resetForm = null, parentId) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startCardCreating());
    try {
      const { card, columnId } = task;
      const cardId = card?.id || nanoid(24);

      const { uid, email, displayName } = auth.currentUser;

      const {
        kanban: { currentProject }
      } = getState();

      let observerIds = card.observers.map((pers) => pers.id);
      let canAccessId = card.assignee.map((pers) => pers.id);

      canAccessId.push(card.createdBy?.id || uid);
      canAccessId.concat(observerIds);

      const assigneIds = card?.assignee ? card?.assignee?.map((_one) => _one.id) : [];

      let assigneByIds = card?.assigneByIds || [];

      if (card.assignee?.length || card?.responsable !== null) {
        assigneByIds = uniq([auth.currentUser.uid, ...assigneByIds]);
      }

      const project = await getProjectById(card?.projectKey || card?.idProject, getState());
      const isPrivate = project?.isPrivate || currentProject?.isPrivate || false;
      const visibility = isPrivate ? TASK_VISIBILITY.PRIVATE : TASK_VISIBILITY.PUBLIC;

      const history = {
        actionType: V4_TASK_HISTORY_ACTION.CREATED,
        taskId: cardId,
        values: {},
        createdBy: { id: uid, email, name: displayName },
        createdAt: serverTime()
      };

      let newCard = {
        state: TASK_STATE_VALIDATION.PENDING,
        ...card,
        isDeleted: false,
        canAccessId,
        assigneIds,
        assigneByIds,
        deadLine: card.due[1],
        createdBy: { id: uid, email, name: displayName },
        createdAt: serverTime(),
        updatedAt: serverTime(),
        subTasksCount: 0,
        metadata: {
          history: [history]
        },
        projectState: currentProject?.state || 'open',
        ...(parentId
          ? { idProject: currentProject?.id || project?.id }
          : { projectKey: currentProject?.id || project?.id }),
        visibility,
        name: card?.name?.replace(/<[^>]+>/g, '')
      };

      resetForm && resetForm();

      dispatch(slice.actions.addTask({ card: { ...newCard, id: cardId }, columnId }));

      //console.log(cardId, newCard);

      const docRef = firestore.collection('tasks').doc(cardId);

      await docRef.set(newCard);

      newCard = { ...newCard, id: docRef.id };

      if (parentId) {
        const parentRef = firestore.collection('tasks').doc(parentId);

        await firestore.runTransaction(async (transaction) => {
          const doc = await transaction.get(parentRef);
          if (doc.exists) {
            const data = doc.data();
            transaction.update(parentRef, {
              cardIds: uniq([...(data.cardIds || []), docRef.id]),
              subTasksCount: (data?.subTasksCount || 0) + 1
            });
          }
        });
      }

      if (columnId) {
        const taskColRef = firestore.collection('taskColumns').doc(columnId);
        await firestore.runTransaction(async (tranc) => {
          return tranc
            .get(taskColRef)
            .then((sfDoc) => {
              if (sfDoc.exists) {
                let cardIds = sfDoc.data().cardIds;
                if (cardIds && cardIds.includes(docRef.id)) {
                  return;
                }
                if (cardIds) {
                  cardIds = uniq([...cardIds, docRef.id]);
                }

                tranc.update(taskColRef, { cardIds });
              }
            })
            .catch((err) => console.log({ err }));
        });
      }

      dispatch(slice.actions.closeCardCreating());

      let listIds = {};
      observerIds.forEach((id) => (listIds[id] = true));
      canAccessId.forEach((id) => (listIds[id] = true));

      //console.log({ listIds });

      //console.log({ card });
      dispatch(
        taskCreateNotification({
          card: {
            ...newCard,
            managers: currentProject?.managers || [],
            projectKey: currentProject?.id || newCard?.projectKey || newCard?.idProject
          },
          projectName: currentProject?.name || project?.name || ''
        })
      );
      // dispatch(
      //   taskCreationNotification({
      //     card: {
      //       ...newCard,
      //       managers: currentProject?.managers || [],
      //       projectKey: currentProject?.id || newCard?.projectKey || newCard?.idProject
      //     }
      //   })
      // );

      if (columnId) {
        dispatch(ProjectEvolutionPercent({ ...card, id: docRef.id }));
      }

      if (callback) return callback(docRef.id);

      if (newCard?.rappels?.length) {
        const rappelBatch = firestore.batch();

        newCard?.rappels.forEach((_rap) => {
          const _rappRef = docRef.collection('rappels').doc(_rap.id);
          rappelBatch.set(_rappRef, {
            ..._rap,
            next: _rap?.date,
            taskId: card?.id,
            taskName: card?.name,
            projectId: currentProject?.id,
            projectName: currentProject?.name
          });
        });

        await rappelBatch.commit();
      }
    } catch (error) {
      console.error(error);
      // dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId = null, parentId = null, card = null }, callback = null) {
  return async (dispatch, getState) => {
    try {
      const kanban = getState().kanban;
      const {
        currentProject: project,
        board: { cards }
      } = kanban;
      const currentCard = cards[cardId];
      //console.log({ name:currentCard?.name});
      const taskRef = firestore.collection('tasks').doc(cardId);
      taskRef.delete();
      //console.log('delete task', cardId, columnId, parentId, card);

      if (callback) {
        callback();
      }

      let taskColRef;
      dispatch(
        taskDeleteNotification({
          card: {
            ...currentCard,
            managers: project?.managers || [],
            projectKey: project?.id || card?.projectKey || card?.idProject,
            name: currentCard?.name
          },
          projectName: card?.projectName || '',
          project
        })
      );

      if (columnId) {
        taskColRef = firestore.collection('taskColumns').doc(columnId);
      } else {
        const cols = await firestore.collection('taskColumns').where('cardIds', 'array-contains', cardId).get();
        const exist = cols.docs.at(0);
        if (exist) {
          taskColRef = exist.ref;
        }
      }

      if (taskColRef) {
        await firestore.runTransaction(async (tranc) => {
          const sfDoc = await tranc.get(taskColRef);
          if (sfDoc.exists) {
            let cardIds = sfDoc.data().cardIds;
            cardIds = filter(cardIds, (card) => card !== cardId);
            tranc.update(taskColRef, { cardIds: [...cardIds] });
          }
        });
      }

      if (card != null) {
        const tSave = {
          id: card?.id,
          name: card?.name,
          due: card?.due,
          assignee: card?.assignee
        };
        const changes = card?.dependences || {};
        const pendingTasks = { removed: changes[DEPS_TYPES.PENDING] || [], added: [] };
        await exeDepsChange(pendingTasks, DEPS_TYPES.LOCK, tSave);

        const lockedTasks = { removed: changes[DEPS_TYPES.LOCK] || [], added: [] };
        await exeDepsChange(lockedTasks, DEPS_TYPES.PENDING, tSave);

        const linkedTasks = { removed: changes[DEPS_TYPES.TASK] || [], added: [] };
        await exeDepsChange(linkedTasks, DEPS_TYPES.TASK, tSave);
      }

      if (parentId) {
        const parentRef = firestore.collection('tasks').doc(parentId);
        await firestore.runTransaction(async (transaction) => {
          const doc = await transaction.get(parentRef);
          if (doc.exists) {
            transaction.update(parentRef, {
              subTasksCount: (doc.data()?.subTasksCount || 0) - 1
            });
          }
        });
      }

      if (card) dispatch(updatedProjectUsedBudget((card?.budget || 0) * -1, gProjectId(card)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAccessId(accessIds, projectId, taskId, callback = null) {
  return async (dispatch, getState) => {
    const users = getState().user.users;
    try {
      if (accessIds.length) {
        var projectRef = firestore.collection('project').doc(projectId);
        await firestore.runTransaction(async (tranc) => {
          const sfDoc = await tranc.get(projectRef);
          if (sfDoc.exists) {
            const canAccessId = sfDoc.data()?.canAccessId;
            const canAccess = sfDoc.data()?.canAccess || [];
            let newVal = [...canAccessId, ...accessIds];
            newVal = newVal.filter(distinct);
            let canAccessNew = [];

            accessIds.forEach((_one) => {
              const _u = users.find((_u_1) => _u_1.id === _one);

              if (_u) {
                canAccessNew.push({
                  name: _u?.displayName || _u?.name || `${_u?.lastName} ${_u?.firstName}`,
                  email: _u?.email || '',
                  avatar: _u?.photoUrl || _u?.photoURL || _u?.avatar || '',
                  id: _one
                });
              }
            });

            tranc.update(projectRef, {
              canAccessId: [...newVal],
              canAccess: [...canAccess, ...canAccessNew]
            });
          }
        });

        var taskRef = firestore.collection('tasks').doc(taskId);
        await firestore.runTransaction((tranc) => {
          return tranc.get(taskRef).then((sfDoc) => {
            if (sfDoc.exists) {
              const canAccessId = sfDoc.data()?.canAccessId;
              let newVal = [...canAccessId, ...accessIds];
              newVal = newVal.filter(distinct);
              tranc.update(taskRef, { canAccessId: [...newVal] });
            }
          });
        });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAccessProjectId(accessIds, projectId, callback = null) {
  return async (dispatch) => {
    try {
      if (accessIds.length) {
        var projectRef = firestore.collection('project').doc(projectId);
        await firestore.runTransaction((tranc) => {
          return tranc.get(projectRef).then((sfDoc) => {
            if (sfDoc.exists) {
              const canAccessId = sfDoc.data()?.canAccessId;
              let newVal = [...canAccessId, ...accessIds];
              newVal = newVal.filter(distinct);
              tranc.update(projectRef, { canAccessId: [...newVal] });
            }
          });
        });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//#endregion

//#region --------------------------------------------PROJECT CONVERSATION--------------------------------------PROJECT CONVERSATION-----------------------------------PROJECT CONVERSATION-----------
export function getCurrentTaskProjectConversation(projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.startCardCreating());
    try {
      firestore
        .collection('project')
        .doc(projectId)
        .collection('messages')
        .orderBy('createdAt', 'asc')
        .limitToLast(250)
        .onSnapshot((snap) => {
          const add = [];
          snap.docChanges().forEach((docChang) => {
            const { type, doc } = docChang;
            if (type === 'added') {
              add.push({
                id: doc.id,
                ...doc.data()
              });
            }
          });

          dispatch(slice.actions.projectConversations({ add, projectId }));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//--------------------------------------------COMMENTS------------------------------------------------COMMENTS-------------------------------------------------COMMENTS-----------
export function addCommentToTask({ taskId, generateId, commentBbject, canAccess, taskName, callback = null }) {
  return async (dispatch, getState) => {
    try {
      const { mentions = [] } = commentBbject;

      firestore
        .collection('tasks')
        .doc(taskId)
        .collection('comments')
        .doc(generateId)
        .set(
          {
            ...commentBbject,
            createdAt: serverTime()
          },
          { merge: true }
        );
      callback && callback();

      const {
        kanban: { currentProject }
      } = getState();

      dispatch(
        taskCommentAddNotification({
          canReceived: canAccess,
          projectKey: currentProject?.id,
          projectName: currentProject?.name,
          taskId,
          taskName
        })
      );

      if (mentions?.length !== 0)
        dispatch(
          taskMentionNotification({
            canReceived: mentions,
            projectKey: currentProject?.id,
            projectName: currentProject?.name,
            taskId,
            taskName,
            target: 'dans le commentaire'
          })
        );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function EditCommentFromTask({ taskId, commentId, commentBbject, callback = null }) {
  return async (dispatch) => {
    try {
      firestore
        .collection('tasks')
        .doc(taskId)
        .collection('comments')
        .doc(commentId)
        .set({ ...commentBbject }, { merge: true });

      callback && callback();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteCommentFromTask({ taskId, commentId, callback = null }) {
  return async (dispatch) => {
    try {
      firestore.collection('tasks').doc(taskId).collection('comments').doc(commentId).delete();
      callback && callback();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCommentReadState({ taskId, comments, callback = null }) {
  return async (dispatch) => {
    try {
      if (comments?.length) {
        const batch = firestore.batch();

        comments.forEach((com) => {
          const commentRef = firestore.collection('tasks').doc(taskId).collection('comments').doc(com.id);
          batch.set(commentRef, com, { merge: true });
        });

        batch.commit();
        callback && callback();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//#endregion

//#region --------------------------------------------SUBTASK------------------------------------------------SUBTASK-----------------------------------------------SUBTASK-----------
export function addSubTask({ taskId, subTask, callback = null, rest = null }) {
  return async (dispatch) => {
    try {
      const taskRef = firestore.collection('tasks').doc(taskId);

      await taskRef.collection('subTask').add(subTask);
      if (rest) {
        rest();
      }

      await firestore.runTransaction(async (transaction) => {
        const doc = await transaction.get(taskRef);
        if (doc.exists) {
          transaction.update(taskRef, {
            subTasksCount: (doc.data()?.subTasksCount || 0) + 1
          });
        }
      });
      callback && callback();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSubTask({ taskId, subTask, callback = null }) {
  return async (dispatch) => {
    try {
      const { id, ...rest } = subTask;
      await firestore
        .collection('tasks')
        .doc(taskId)
        .collection('subTask')
        .doc(subTask.id)
        .set({ ...rest }, { merge: true });
      callback && callback();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSubTask({ taskId, subTaskId, callback = null }) {
  return async (dispatch) => {
    try {
      const taskRef = firestore.collection('tasks').doc(taskId);

      await taskRef.collection('subTask').doc(subTaskId).delete();

      await firestore.runTransaction((transaction) => {
        return transaction.get(taskRef).then((doc) => {
          if (doc.exists) {
            transaction.update(taskRef, {
              subTasksCount: (doc.data()?.subTasksCount || 0) - 1
            });
          }
        });
      });
      callback && callback();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//#endregion

export const deleteModelCol = (colId, callback = null) => {
  return async (dispatch, getState) => {
    try {
      const { currentModel } = getState()?.kanban;

      dispatch(slice.actions.removeModelCol(colId));

      await firestore.runTransaction((transaction) => {
        const ref = firestore.collection('projectModels').doc(currentModel?.id);
        return transaction.get(ref).then((doc) => {
          if (doc.exists) {
            const { columnOrder = [] } = doc.data();

            transaction.update(ref, {
              columnOrder: columnOrder?.filter((id) => id !== colId)
            });
          }
        });
      });

      callback && callback();
    } catch (e) {
      //console.log(e);
    }
  };
};

export const updateModelCol = (colId, column, callback = null) => {
  return async (dispatch, getState) => {
    try {
      const { currentModel } = getState()?.kanban;

      dispatch(slice.actions.updateModel({ id: colId, ...column }));

      await firestore.runTransaction((transaction) => {
        const ref = firestore.collection('projectModels').doc(currentModel?.id);
        return transaction.get(ref).then((doc) => {
          if (doc.exists) {
            const { columns = [] } = doc.data();

            transaction.update(ref, {
              columns: {
                ...columns,
                [colId]: column
              }
            });
          }
        });
      });

      callback && callback();
    } catch (e) {
      //console.log(e);
    }
  };
};

export const taskGroupCreate = (group, columnId, projectKey) => {
  return async (dispatch) => {
    try {
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
};
