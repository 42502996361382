import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { keys, orderBy, uniqBy, values } from 'lodash';
import { format } from 'date-fns';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { fr as LocalFr, id } from 'date-fns/locale';
import BasicTabs from 'src/components/BasicTabs';
import closeFill from '@iconify/icons-eva/close-fill';
import infoOutlined from '@iconify/icons-eva/info-outline';
import lockOutlined from '@iconify/icons-eva/lock-outline';
import editOutlined from '@iconify/icons-eva/edit-outline';
import peopleOutlined from '@iconify/icons-eva/people-outline';
import stateOutlined from '@iconify/icons-eva/play-circle-outline';
import personOutlined from '@iconify/icons-eva/person-add-outline';
import pricetagsOutlined from '@iconify/icons-eva/pricetags-outline';
import briefcaseOutlined from '@iconify/icons-eva/briefcase-outline';
import taskAltOutlined from '@iconify/icons-eva/list-outline';

import {
  Box,
  Button,
  Card,
  Grid,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Autocomplete,
  MenuItem,
  Tooltip,
  Typography,
  TextField
} from '@mui/material';
import { useDispatch, useSelector } from 'src/redux/store';
import useToggle, { useToggleV2 } from './../../hooks/useToggle';
import { gDate } from 'src/utils/formatTime';
import CloseIcon from '@mui/icons-material/Close';
import { nameFilter } from 'src/utils/nameFilter';
import createAvatar from './../../utils/createAvatar';
import { updateProject, createProject } from 'src/redux/slices/kanban';
import MenuPopover from './../../components/MenuPopover';
import { MAvatar } from '../../components/@material-extend';
import ColorSinglePicker from './../../components/ColorSinglePicker';
import { COLOR_OPTIONS, LightTooltip } from 'src/section/project/ProjectCardSimple';
import {
  CustomAutocompletInput,
  CustomInput,
  customTextFieldTheme,
  MuiCustomInput
} from './../../components/CustomInput';

import SingleBackgroundImagePicker from 'src/components/SingleBackgroundImagePicker';
import { searchList } from 'src/utils/array';
import { dateConverter } from 'src/helpers/dueDateConverter';
import { DisplayTime, useDatePicker } from '../tasks/kanban/KanbanTaskAdd';
import DateTineRangeSelector from 'src/components/DateTineRangeSelector';
import { TASK_VISIBILITY } from 'src/constants';
import Iconify from 'src/components/Iconify';
import { useTheme } from '@mui/material/styles';
import CurrencyPicker from 'src/components/CurrencyPicker';
import Center from 'src/components/Center';
import DeletePopup from 'src/components/DeletePopup';
import { getNumberFieldProps, parseNumber } from 'src/utils/formatNumber';
import useAuth from 'src/hooks/useAuth';
import HtmlTooltip from 'src/components/HtmlTooltip';
import CIconButton from 'src/components/CIconButton';
import Scrollbar from 'src/components/Scrollbar';
import budgetImg from 'src/assets/budget.png';


const PROJECT_STATE = {
  IN_PROGRESS: 'En cours',
  SUSPENDED: 'Suspendus',
  CLOSED: 'Clôturés'
};

const stateConverter = (state) => {
  if (state === 'open') return PROJECT_STATE.IN_PROGRESS;
  if (state === 'suspended') return PROJECT_STATE.SUSPENDED;
  if (state === 'closed') return PROJECT_STATE.CLOSED;
  return state;
};

const stateReverseConverter = (state) => {
  if (state === PROJECT_STATE.IN_PROGRESS) return 'open';
  if (state === PROJECT_STATE.SUSPENDED) return 'suspended';
  if (state === PROJECT_STATE.CLOSED) return 'closed';
  return state;
};

export default function ProjectCardOPtion({
  project,
  onClose,
  isNew = false,
  currentUser,
  onSave,
  addToSpace = false,
  openCreated = false
}) {
  const [detail, setDetail] = useState(project);

  useEffect(() => setDetail(project), [project]);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleDetailChange = (name, value) => {
    setDetail({ ...detail, [name]: value });
  };

  const updateTheProject = () => {
    //console.log({ detail, project });
    let canAccessId = detail.canAccess.map((pers) => pers.id);
    const isOn = project?.canAccess?.find((one) => one.id === project?.createBy?.id);
    let managerIds = detail?.managers?.map((_one) => _one.id) || [];

    canAccessId = uniqBy([...canAccessId, ...managerIds]);

    if (!isOn) {
      const { createBy } = project;
      canAccessId = [...canAccessId, createBy?.id];

      handleDetailChange('canAccess', [
        ...detail?.canAccess,
        {
          name: createBy?.displayName || createBy?.name,
          displayName: createBy?.displayName || createBy?.name,
          email: createBy?.userEmail || createBy?.email,
          avatar: createBy?.avatar,
          id: createBy?.id
        }
      ]);
    }

    const callback = () => {
      enqueueSnackbar('Projet mise à jour', { variant: 'info' });
      if (onSave) onSave(detail);
    };
    if (JSON.stringify(project) !== JSON.stringify(detail)) {
      dispatch(updateProject({ ...detail, id: project?.id, canAccessId }, project, callback));
    }
    onClose();
  };

  // console.log(detail);

  const newProject = () => {
    let canAccessId = detail.canAccess.map((pers) => pers?.id);
    let managerIds = detail?.managers?.map((_one) => _one.id) || [];

    canAccessId = [...canAccessId, currentUser?.id || ''];

    canAccessId = uniqBy([...canAccessId, ...managerIds]);

    handleDetailChange('canAccess', [
      ...detail?.canAccess,
      {
        name: currentUser?.displayName || currentUser?.name,
        displayName: currentUser?.displayName || currentUser?.name,
        email: currentUser?.userEmail || currentUser?.email,
        avatar: currentUser?.avatar,
        id: currentUser?.id
      }
    ]);

    const callback = (id) => {
      enqueueSnackbar('Projet ajouté', { variant: 'success' });
      if (onSave) onSave(detail);
      if (addToSpace) addToSpace(id);
      if (openCreated) openCreated({ ...detail, id });
    };
    if (JSON.stringify(project) !== JSON.stringify(detail) && detail.name.trim() !== '') {
      dispatch(
        createProject(
          {
            ...detail,
            createBy: {
              id: currentUser?.id || '',
              userEmail: currentUser?.email || '',
              displayName: currentUser?.displayName || '',
              avatar: currentUser?.photoUrl || currentUser?.photoURL || ''
            },
            canAccessId
          },
          callback
        )
      );
    }
    onClose();
  };

  const handleClose = () => {
    if (!isNew) {
      updateTheProject();
      return;
    }
    onClose();
  };
  //project?.canAccess[0]?.{
  // id "5f9b2b1b-4b7c-4b9a-9c0a-0a3a1a0a0a0a"
  // rights: {create: true, delete: true, edit: true, read: true}
  // }
  const userCanEdit = useMemo(() => {
    return project?.canAccess?.find((one) => one.id === currentUser?.id)?.rights?.edit;
  }, [currentUser?.id, project?.canAccess]);

  //console.log({userCanEdit});
  return (
    <BasicTabs
      headers={[
        { name: 'Editer', icon: <Icon icon={editOutlined} width={25} height={25} /> },
        { name: 'Collaborateurs', icon: <Icon icon={peopleOutlined} width={25} height={25} /> }
      ]}
      subHeader={(_) => (
        <Stack pt={1} direction="row" justifyContent="flex-end" spacing={3} alignItems="center">
          <Stack>
            {isNew && (
              <Tooltip title="Enregistrer">
                <span>
                  <Button disabled={detail.name.trim() === ''} size="small" variant="contained" onClick={newProject}>
                    {' '}
                    Enregister{' '}
                  </Button>
                </span>
              </Tooltip>
            )}
            {!isNew && (
              <Tooltip title="Enregistrer">
                <span>
                  <Button disabled={detail.name.trim() === ''} size="small" variant="contained" onClick={handleClose}>
                    Enregister
                  </Button>
                </span>
              </Tooltip>
            )}
          </Stack>

          <Stack>
            <Tooltip title="Fermer">
              <IconButton size="small" color="error" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      )}
      panels={[
        { element: <EditWindow project={detail} handleDetailChange={handleDetailChange} isNew={isNew} /> },
        {
          element: <UserAccessView isNew={isNew} project={detail} handleDetailChange={handleDetailChange} />
        }
      ]}
    />
  );
}

const EditLine = ({ icon, field, value, label, onChange }) => {
  return (
    <Stack direction="row" width={1} spacing={1} alignItems="center">
      <Icon icon={icon} width={25} height={25} />
      <CustomInput
        value={value}
        placeholder={label}
        size="small"
        onChange={(e) => onChange(field, e.currentTarget.value)}
        sx={{ width: { xs: 350, md: 400 } }}
      />
    </Stack>
  );
};

const TaskVisibility = ({ value = null, onChange }) => {
  const [val, setVal] = useState(value || TASK_VISIBILITY.PUBLIC);

  const options = useMemo(() => {
    return [
      {
        label: TASK_VISIBILITY.PUBLIC,
        value: TASK_VISIBILITY.PUBLIC,
        desc: 'Les tâches créées seront visibles par tous'
      },
      {
        label: TASK_VISIBILITY.PRIVATE,
        value: TASK_VISIBILITY.PRIVATE,
        desc: 'Seules ceux qui sont invités sur les tâches peuvent y accéder'
      }
    ];
  }, []);

  const handleChange = (option) => {
    const value = option?.value || null;
    setVal(value);
    onChange('taskVisibility', value);
  };

  return (
    <Stack direction="row" width={1} spacing={1} alignItems="center">
      <Iconify icon={'bi:shield-lock-fill'} width={25} height={25} />
      <Autocomplete
        value={val}
        options={options}
        size="small"
        onChange={(evant, newValue) => handleChange(newValue)}
        isOptionEqualToValue={(option, value) => option?.value === value}
        renderOption={(props, option) => {
          return (
            <MenuItem {...props}>
              <ListItemText
                primary={option?.label}
                secondary={option?.desc}
                sx={{ pr: 3 }}
                secondaryTypographyProps={{
                  whiteSpace: 'normal'
                }}
              />
            </MenuItem>
          );
        }}
        renderInput={(params) => (
          <CustomAutocompletInput
            {...params}
            sx={{
              width: { xs: 350, md: 400 },
              '& .MuiInput-underline': {
                borderColor: 'transparent'
              }
            }}
            placeholder="Visibilité des tâches"
          />
        )}
      />
    </Stack>
  );
};

const Budget = ({ value = {}, onChange }) => {
  const ref = useRef();
  const theme = useTheme();
  const [open, onOpen, onClose] = useToggleV2();
  const [openD, onOpenD, onCloseD] = useToggleV2();
  const [hasBudget, confirmAddBudget, rem] = useToggleV2(value?.estimated > 0);

  const [budget, setBudget] = useState({
    currency: {
      symbol: 'CFA',
      name: 'CFA Franc BCEAO'
    },
    estimated: 0,
    ...value
  });

  const handleCurrencyChange = (currency) => {
    const { _helper, ...rest } = currency;
    handleChange('currency', rest);
  };

  const handleChange = (field, value) => {
    setBudget((prevState) => {
      const budget = { ...prevState, [field]: value };

      onChange && onChange('budget', budget);

      return budget;
    });
  };

  const handleRemoveBudget = () => {
    onCloseD();
    handleChange('estimated', 0);
    rem();
    setBudget('budget', null);
  };

  return (
    <>
      <Stack direction="row" width={'94%'} spacing={1} alignItems="center">
        <Stack component={'img'} src={budgetImg} width={25} height={25} />

        {!hasBudget && (
          <Stack direction={'row'} spacing={1} pl={1}>
            <Stack justifyContent={'center'}>
              <Typography>Ajouter un budget au projet?</Typography>
            </Stack>

            <Button onClick={confirmAddBudget}>Oui</Button>
          </Stack>
        )}

        {hasBudget && (
          <Stack direction={'row'} width={1} spacing={1}>
            <Stack width={1}>
              <TextField
                fullWidth
                label={''}
                // type={'number'}
                size={'small'}
                variant="standard"
                placeholder={'Budget estimé'}
                // value={budget?.estimated}
                // onChange={event => handleChange('estimated', Number(event.target.value))}
                {...getNumberFieldProps({
                  value: budget?.estimated,
                  onChange: (val) => handleChange('estimated', parseNumber(val, true))
                })}
                sx={customTextFieldTheme(theme)}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <Stack direction={'row'} sx={{ cursor: 'pointer' }} onClick={onOpen} ref={ref}>
                      <Typography>{budget?.currency?.symbol}</Typography>
                      <Iconify icon={'gridicons:dropdown'} />
                    </Stack>
                  )
                }}
              />
            </Stack>

            <Stack>
              <Center>
                <IconButton onClick={onOpenD}>
                  <CloseIcon />
                </IconButton>
              </Center>
            </Stack>
          </Stack>
        )}
      </Stack>

      {open && (
        <CurrencyPicker
          anchorEl={ref.current}
          open={open}
          onClose={onClose}
          onPick={handleCurrencyChange}
          selected={budget?.currency}
        />
      )}

      <DeletePopup
        open={openD}
        onClose={onCloseD}
        anchorRef={ref}
        onDelete={handleRemoveBudget}
        text={''}
        acceptText={'Oui'}
        pending={false}
        message={'Êtes vous certains de retirer le budget de ce projet ?'}
      />
    </>
  );
};

const EditMultiLine = ({
  icon,
  field,
  options = [],
  value,
  label,
  onChange,
  multiple = false,
  isFree = true,
  onClick
}) => {
  const [val, setVal] = useState(value);

  const handlechange = (newVal) => {
    setVal(newVal);
    if (field === 'state') {
      return onChange(field, stateReverseConverter(newVal));
    }
    onChange(field, newVal);
  };

  return (
    <Stack direction="row" width={1} spacing={1} alignItems="center">
      <Icon icon={icon} width={25} height={25} />
      <Autocomplete
        value={val}
        options={options}
        size="small"
        multiple={multiple}
        onChange={(evant, newValue) => handlechange(newValue)}
        {...(isFree && { freeSolo: true })}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option + index}
              color="info"
              size="small"
              variant="filled"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderOption={(props, option) => {
          return (
            <Box sx={{ position: 'relative' }} key={option + props.id}>
              <MenuItem {...props}>{option}</MenuItem>

              {onClick && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 4,
                    right: 5
                  }}
                >
                  <Button size={'small'} sx={{ p: 0 }} onClick={() => onClick && onClick(option)}>
                    voir
                  </Button>
                </Box>
              )}
            </Box>
          );
        }}
        renderInput={(params) => (
          <CustomAutocompletInput
            {...params}
            sx={{
              width: { xs: 350, md: 400 },
              '& .MuiInput-underline': {
                borderColor: 'transparent'
              }
            }}
            placeholder={label}
          />
        )}
      />
    </Stack>
  );
};

const ColorsLine = ({ color, onChange }) => {
  const [cColor, setColor] = useState(color || '#c7c4c4');
  const { open, handleClose, handleOpen } = useToggle();
  const setColorRef = useRef(null);

  useEffect(() => setColor(color || '#c7c4c4'), [color]);

  const handleChangeColor = (event) => {
    setColor(event.target.value);
    onChange('color', event.target.value);
  };

  const checkImage = useMemo(() => (cColor.includes('#') ? '' : cColor), [cColor]);
  const isImage = useMemo(() => (cColor.includes('#') ? false : true), [cColor]);

  return (
    <>
      <Stack direction="row" spacing={1} sx={{ cursor: 'pointer' }} onClick={handleOpen} ref={setColorRef}>
        <Box width={25} />
        <Stack
          width={1}
          alignItems="center"
          sx={{
            ...(isImage
              ? {
                backgroundImage: `url(${cColor})`,
                backgroundSize: 'cover'
              }
              : { bgcolor: cColor }),
            color: 'white',
            p: 1
          }}
        >
          <Typography variant="body2" color="inherit">
            Couleur du projet
          </Typography>
        </Stack>
      </Stack>
      <MenuPopover
        open={open}
        width={260}
        onClose={handleClose}
        anchorEl={setColorRef.current}
        zIndex={(t) => t.zIndex.modal + 50}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <ColorSinglePicker selected={cColor} onChange={handleChangeColor} colors={COLOR_OPTIONS} />
        <Divider />

        <Box sx={{ py: 0.75, px: 1.5 }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body2">Image de fond</Typography>
            </Grid>
            <Grid item xs={12}>
              <SingleBackgroundImagePicker selected={checkImage} onChange={(event) => handleChangeColor(event)} />
            </Grid>
          </Grid>
        </Box>
      </MenuPopover>
    </>
  );
};

const EditWindow = ({ project, handleDetailChange, isNew = false }) => {
  const anchorEl = useRef();

  const { startTime, endTime, isSameDays, isSameMonths, onChangeDueDate, openPicker, onOpenPicker, onClosePicker } =
    useDatePicker({
      date: dateConverter([project?.dataInterval?.start || null, project?.dataInterval?.end || null])
    });

  const onClose = (val) => {
    onClosePicker();
    handleDetailChange('dataInterval', { start: val?.at(0), end: val?.at(1) });
  };

  return (
    <Stack spacing={2} width={1} sx={{ mt: 3, py: 2, px: 1 }}>
      <ColorsLine color={project?.color} onChange={handleDetailChange} />
      <EditLine
        onChange={handleDetailChange}
        icon={briefcaseOutlined}
        field="name"
        label="Titre"
        value={project?.name}
      />
      <EditMultiLine
        onChange={handleDetailChange}
        icon={pricetagsOutlined}
        field="tags"
        label="Tags"
        multiple={true}
        value={project?.tags || []}
      />
      <EditLine
        onChange={handleDetailChange}
        icon={infoOutlined}
        field="description"
        label="Description"
        value={project?.description || ''}
      />
      <EditMultiLine
        onChange={handleDetailChange}
        icon={stateOutlined}
        field="state"
        label="Etat"
        value={stateConverter(project?.state)}
        isFree={false}
        options={[PROJECT_STATE.IN_PROGRESS, PROJECT_STATE.SUSPENDED, PROJECT_STATE.CLOSED]}
      />
      <Stack ref={anchorEl} direction="row" spacing={2} alignItems="center">
        <Icon icon="flat-color-icons:calendar" width={30} height={30} />
        {startTime && endTime ? (
          <DisplayTime
            startTime={startTime}
            endTime={endTime}
            isSameDays={isSameDays}
            isSameMonths={isSameMonths}
            onOpenPicker={onOpenPicker}
          />
        ) : (
          <Stack onClick={onOpenPicker}>
            <CustomInput
              readOnly
              placeholder="Date de début et de fin"
              size="small"
              sx={{ width: { xs: 350, md: 400 } }}
            />
          </Stack>
        )}
        {openPicker && (
          <DateTineRangeSelector
            open={openPicker}
            anchorEl={anchorEl.current}
            onClose={onClose}
            onChange={onChangeDueDate}
            deb={startTime}
            end={endTime}
          />
        )}
      </Stack>

      <TaskVisibility value={project?.taskVisibility} onChange={handleDetailChange} />

      <Budget value={project?.budget} onChange={handleDetailChange} />

      <Box />
      <Typography align="center" fontSize={13} color="ActiveCaption">
        Ajouté par{' '}
        <span style={{ color: '#ADC8FF', textDecoration: 'underline' }}>
          {project?.createBy?.name || project?.createBy?.displayName}
        </span>{' '}
        le {format(gDate(project?.createdAt) || new Date(), 'dd MMMM yyyy', { locale: LocalFr })}
      </Typography>
    </Stack>
  );
};

const collabRights = {
  read: false,
  create: false,
  edit: false,
  delete: false
};

const taskRights = {
  read: true,
  create: true,
  edit: true,
  delete: true
};
const managersRights = {
  read: true,
  create: true,
  edit: true,
  delete: true,
  validate: true
};

export const UserList = ({
  title,
  sendAllProperties = false,
  collaborateurs = [],
  current = [],
  onChange,
  canSelect,
  canEdit = false,
  canRemove,
  right = 'normal',
  isManager = false,
  single = false
}) => {
  const [checked, setChecked] = React.useState([...current]);
  const [hover, setHover] = React.useState(null);

  useEffect(() => setChecked([...current]), [current]);


  const handleToggle = (value) => () => {
    const currentIndex = checked.find((one) => one.id === value.id);
    const newChecked = [...checked];

    if (single) {
      if (!currentIndex) {
        setChecked([value]);
        return onChange([value]);
      }
      setChecked([]);
      return onChange([]);
    }

    if (!currentIndex) {
      let person = !sendAllProperties
        ? {
          name: value.displayName || value?.name || `${value?.lastName} ${value?.firstName}`,
          displayName: value.displayName || value?.name || `${value?.lastName} ${value?.firstName}`,
          email: value.email,
          id: value.id,
          avatar: value?.photoUrl || value?.photoURL || value?.avatar || null,
          ...(right === 'participants' && { rights: collabRights, taskRights: taskRights }),
          ...(right === 'managers' && { rights: managersRights })
        }
        : value;

      newChecked.push(person);
      setChecked(newChecked);
      return onChange(newChecked);
    } else {
      const rest = newChecked.filter((one) => one.id !== value.id);
      setChecked(rest);
      return onChange(rest);
    }
  };

  const handleIncludesRights = (userId, rights) => {
    onChange &&
      onChange(
        checked?.map((el) => {
          if (el?.id === userId) {
            return { ...el, rights: { ...el?.rights, ...rights } };
          } else {
            return el;
          }
        })
      );
  };

  const handleIncludesTaskRights = (userId, taskRights) => {
    onChange &&
      onChange(
        checked?.map((el) => {
          if (el?.id === userId) {
            return { ...el, taskRights: { ...el?.taskRights, ...taskRights } };
          } else {
            return el;
          }
        })
      );
  };



  const onCloseHover = () => setHover(null);

  const removeUser = (id) => {
    const newChecked = [...checked];
    const rest = newChecked.filter((one) => one.id !== id);
    setChecked(rest);
    onChange(rest);
  };

  return (
    <Stack width={1}>
      <List
        className="scroll-smooth-small"
        sx={{ bgcolor: 'background.paper', overflow: 'hidden', maxHeight: 300, pt: 1 }}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {uniqBy(current, 'id').length} Sélectionné(s)
          </ListSubheader>
        }
      >
        <Scrollbar>
          {uniqBy(collaborateurs, 'email').map((col, idx) => {
            const labelId = `checkbox-list-label-${col.id}`;
            return (
              <React.Fragment key={col.id + idx}>
                <ListItemButton
                  sx={{ position: 'relative' }}
                  onMouseEnter={() => setHover(col.id)}
                  onMouseLeave={onCloseHover}
                  role={undefined}
                  onClick={canSelect ? handleToggle(col) : undefined}
                  dense
                  key={col.id}
                >
                  {canSelect && (
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.find((one) => one.id === col.id) ? true : false}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                  )}
                  <ListItemAvatar>
                    <MAvatar
                      alt={col?.name || col?.displayName}
                      src={col?.photoUrl || col?.photoURL || col?.avatar}
                      sx={{ width: 27, height: 27, fontSize: 12 }}
                      color={col?.avatar ? 'default' : createAvatar(col?.displayName || col?.name).color}
                    >
                      {createAvatar(col?.displayName || col?.name).name}
                    </MAvatar>
                  </ListItemAvatar>
                  <ListItemText primary={col?.displayName || col?.name} />

                  <Stack direction="row">
                    {canEdit && (
                      <Stack direction="row" spacing={0}>
                        <RightsForm
                          onChange={(rights) => handleIncludesRights(col?.id, rights)}
                          onChangeTask={(taskRights) => handleIncludesTaskRights(col?.id, taskRights)}
                          rights={col?.rights}
                          taskRights={col?.taskRights}
                          hide={right === 'normal'}
                          isManager={right === 'managers'}
                          title="Les droits d'accès du projet"
                          taskTitle="Les droits d'accès aux tâches"
                          icon={lockOutlined}
                        />
                      </Stack>
                    )}
                    {canRemove && hover === col.id && (
                      <IconButton
                        sx={{ position: 'absolute', right: 5 }}
                        size="small"
                        onClick={() => removeUser(col.id)}
                      >
                        <Icon icon={closeFill} width={20} height={20} />
                      </IconButton>
                    )}
                  </Stack>
                </ListItemButton>
                <Divider light />
              </React.Fragment>
            );
          })}
        </Scrollbar>
      </List>
    </Stack>
  );
};

const baseOptions = {
  create: 'Création',
  edit: 'Ecriture',
  delete: 'Suppression'
};
const managerOption = {
  ...baseOptions,
  validate: 'Validation'
};

const descriptions = {
  create: 'Donne le droit de créer des projets',
  edit: 'Donne le droit de modifier les projets',
  delete: 'Donne le droit de supprimer les projets',
  validate: 'Donne le droit de valider les projets'
};

const taskDescriptions = {
  create: 'Donne le droit de créer des tâches',
  edit: 'Donne le droit de modifier les tâches',
  delete: 'Donne le droit de supprimer les tâches',
};

const RightsForm = ({ onChange, onChangeTask, rights, taskRights, hide = false, isManager, title, taskTitle, icon }) => {
  const anchor = React.useRef();
  const { open, handleOpen, handleClose } = useToggle();

  const hasRights = useMemo(() => {
    return rights?.edit || rights?.create || rights?.delete || taskRights?.edit || taskRights?.create || taskRights?.delete;
  }, [rights, taskRights]);


  const options = useCallback((rights) => {
    const selected = isManager ? managerOption : baseOptions;
    return keys(selected)?.map((key) => {
      return {
        key,
        label: selected[key],
        checked: (rights && rights[key]) || false,
        description: isManager ? descriptions[key] : taskDescriptions[key]
      };
    })
  }, [isManager]);


  const triggerClosing = () => {
    handleClose();
  };

  const handleToggle = (change) => {
    onChange && onChange({ ...rights, ...change });
  };

  const handleToggleTask = (change) => {
    onChangeTask && onChangeTask({ ...taskRights, ...change });
  }

  return (
    <Stack hidden={hide}>
      <Tooltip title={title} arrow>
        <IconButton
          ref={anchor}
          sx={{ mr: 2 }}
          color={hasRights ? 'success' : 'default'}
          size="small"
          onClick={handleOpen}
        >
          <Icon icon={icon} width={20} height={20} />
        </IconButton>
      </Tooltip>

      <MenuPopover open={open} onClose={triggerClosing} sx={{ p: 2 }} anchorEl={anchor.current}>
        <Stack sx={{ backgroundColor: 'background.default', pb: 1 }}>
          <Typography sx={{ ml: 0, fontSize: 14 }}>{title}</Typography>
          <List>
            {options(rights)?.map((el) => (
              <Tooltip title={el.description} arrow placement="right">
                <ListItemButton sx={{ p: 0 }} key={el?.label} onClick={() => handleToggle({ [el?.key]: !el?.checked })}>
                  <ListItemIcon>
                    <Checkbox edge="start" checked={el?.checked} tabIndex={-1} disableRipple />
                  </ListItemIcon>
                  <ListItemText primary={el?.label === 'Ecriture' ? 'Modification' : el?.label} />
                </ListItemButton>
              </Tooltip>
            ))}
          </List>
        </Stack>
        {!isManager &&
          <>
            <Divider sx={{ my: 1 }} />
            <Stack>
              <Typography sx={{ ml: 0, fontSize: 14 }}>{taskTitle}</Typography>
              <List>
                {options(taskRights)?.map((el) => (
                  <Tooltip title={el.description} arrow placement="right">
                    <ListItemButton sx={{ p: 0 }} key={el?.label} onClick={() => handleToggleTask({ [el?.key]: !el?.checked })}>
                      <ListItemIcon>
                        <Checkbox edge="start" checked={el?.checked} tabIndex={-1} disableRipple />
                      </ListItemIcon>
                      <ListItemText primary={el?.label === 'Ecriture' ? 'Modification' : el?.label} />
                    </ListItemButton>
                  </Tooltip>
                ))}
              </List>
            </Stack>
          </>
        }
      </MenuPopover>
    </Stack>
  );
};

/**
 *
 * @param {{
 *  current: Array
 *  onClose: Function
 *  onChange: function(string, Array)
 *  field: string
 *  preTitle: string
 *  title: string
 *  height: number
 *  right: string
 *  single: boolean
 *  actionTitle: string
 *  customList: Array,
 * sendAllProperties: Boolean
 * }} props
 * @returns
 */
export const SelectCollaborates = (props) => {
  const {
    currents,
    onClose,
    onChange,
    field,
    preTitle = 'Ajouter des',
    title = 'Intervenants',
    sendAllProperties = false,
    height = 370,
    right = 'normal',
    single = false,
    customList = [],
    actionTitle = 'Ajouter'
  } = props;

  const [selected, setSelected] = useState(currents);
  const [popoverSearch, setPopoverSearch] = useState('');
  const { users, loading } = useSelector((state) => state.user);

  const persons = useMemo(
    () =>
      (customList.length > 0 ? customList : users)?.map((el) => ({
        ...el,
        displayName: el?.displayName || el?.name
      })),
    [users, customList]
  );
  const popoverFilterFunction = (users) => {
    const sort_user = orderBy(users, 'displayName');
    return nameFilter(sort_user, 'displayName', popoverSearch);
  };

  const handleChange = (newVal) => {
    setSelected(newVal);
  };

  const handleClose = () => {
    let list = [...selected]?.map((el) => ({
      ...el,
      ...(right === 'participants' && { rights: collabRights, taskRights: taskRights }),
      ...(right === 'managers' && { rights: managersRights })
    }));

    onChange(field, list);
    onClose(list);
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Card>
        <CardContent sx={{ p: 1 }}>
          <Stack width={1} minWidth={300} spacing={2} height={{ xs: 1, md: height }}>
            <MuiCustomInput
              size="small"
              fullWidth
              placeholder="Filtrer "
              value={popoverSearch}
              onChange={(e) => setPopoverSearch(e.currentTarget.value)}
            />

            <Stack width={1} justifyContent="center">
              {loading && <CircularProgress />}
              {!loading && (
                <UserList
                  collaborateurs={persons ? popoverFilterFunction(persons) : []}
                  current={selected}
                  canSelect={true}
                  sendAllProperties={sendAllProperties}
                  onChange={handleChange}
                  single={single}
                />
              )}
            </Stack>
          </Stack>

          <Stack direction="row" width={1} justifyContent="flex-end" spacing={2}>
            <Button onClick={handleCancel} color="inherit">
              Annuler
            </Button>
            <Button onClick={handleClose} color="inherit" variant="contained">
              {actionTitle}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </ClickAwayListener>
  );
};

export const UserAccessView = ({ project, isNew, handleDetailChange, userCanEdit }) => {
  const { user } = useAuth();
  const { open, handleClose, handleOpen } = useToggle();
  const { open: show, handleClose: handleCloseGest, handleOpen: handleOpenGest } = useToggle();
  const selectColRef = useRef(null);
  const [search, openSearch, closeSearch] = useToggleV2();

  const [keyword, setKeyword] = useState('');

  const canEdit = useMemo(() => {
    if (isNew) return true;

    const isCreator = project?.createBy?.id === user?.id;
    const isManager = Boolean(project?.managers?.find((el) => el?.id === user?.id));

    return isCreator || isManager;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  const users = useMemo(() => {
    const data = (project?.canAccess || [])?.map((e) => ({ ...e, _helper: `${e?.name || e?.displayName}` }));
    return data?.filter((el) => el?.id !== project?.createBy?.id);
  }, [project?.canAccess, project?.createBy?.id]);

  const filtered = useMemo(() => {
    return searchList(users, keyword);
  }, [keyword, users]);

  const handleKeywordChange = (event) => {
    const value = event.target.value;
    const list = searchList(users, value);

    setKeyword(value);

    if (canEdit && value?.length >= 1 && list?.length === 0) {
      handleOpen();
      setKeyword('');
    }
  };

  const onChangeAction = (newVal) => {
    handleDetailChange('canAccess', newVal);
  };

  const onChangeSelecteGest = (newVal) => {
    handleDetailChange('managers', newVal);
  };

  return (
    <Stack
      className="scroll-smooth-small"
      spacing={2}
      height="70vh"
      width={1}
      sx={{ mt: 1, py: 2, px: 1, overflow: 'hidden' }}
    >
      <Scrollbar>
        <Stack width={1} direction="row" justifyContent="space-between" alignItems="center">
          <LightTooltip
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={show}
            placement="left-start"
            onClose={handleCloseGest}
            title={
              <SelectCollaborates
                title="Gestionnaires"
                field="managers"
                currents={project?.managers || []}
                onClose={handleCloseGest}
                onChange={handleDetailChange}
                right={'managers'}
              />
            }
            arrow
          >
            <Tooltip title="Ajouter des gestionnaires" arrow>
              <Button
                disabled={!canEdit || !userCanEdit}
                color="warning"
                size="small"
                startIcon={<Icon icon={lockOutlined} width={20} height={20} />}
                onClick={handleOpenGest}
              >
                GESTIONNAIRES
              </Button>
            </Tooltip>
          </LightTooltip>
        </Stack>

        <UserList
          title="Gestionnaires"
          canEdit={canEdit && userCanEdit}
          canRemove={canEdit && userCanEdit}
          current={project?.managers || []}
          isManager={true}
          collaborateurs={project?.managers || []}
          onChange={onChangeSelecteGest}
          right={'managers'}
        />

        <Stack width={1} direction="row" justifyContent="space-between" alignItems="center">
          <Stack spacing={1} direction="row" alignItems="center">
            <LightTooltip
              disableFocusListener
              disableHoverListener
              disableTouchListener
              open={open}
              placement="left-start"
              onClose={handleClose}
              title={
                <SelectCollaborates
                  field="canAccess"
                  currents={project?.canAccess || []}
                  onClose={handleClose}
                  onChange={handleDetailChange}
                  right={'participants'}
                />
              }
              arrow
            >
              <Tooltip title="Ajouter des intervenants" arrow>
                <Button
                  disabled={!canEdit || !userCanEdit}
                  color="info"
                  size="small"
                  startIcon={<Icon icon={personOutlined} width={20} height={20} />}
                  onClick={handleOpen}
                >
                  INTERVENANTS
                </Button>
              </Tooltip>
            </LightTooltip>
            <CIconButton title="Filtrer les intervenants" noBorder onClick={openSearch}>
              <Iconify icon="ic:round-search" />
            </CIconButton>
          </Stack>

        </Stack>

        {search && (
          <ClickAwayListener onClickAway={closeSearch}>
            <CustomInput
              sx={{ width: { xs: 0.9, md: 1 } }}
              placeholder="Filtre "
              value={keyword}
              onChange={handleKeywordChange}
            />
          </ClickAwayListener>
        )}

        <UserList
          canEdit={canEdit && userCanEdit}
          canRemove={canEdit && userCanEdit}
          current={filtered}
          collaborateurs={filtered}
          onChange={onChangeAction}
          right={'participants'}
        />
      </Scrollbar>
    </Stack>
  );
};
