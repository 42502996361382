import { Icon } from '@iconify/react';
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Popover,
  Checkbox
} from '@mui/material';
import { deleteColumn, updateColumn } from 'src/redux/slices/kanban';
import { useDispatch } from 'react-redux';
import { baseColors } from 'src/constants/color';
import { CustomMenuPopover } from 'src/components/MenuPopover';
import { MenuLine } from 'src/components/MenuLine';
import React, { useRef, useState } from 'react';
import CIconButton from 'src/components/CIconButton';
import { MuiCustomInput } from 'src/components/CustomInput';
import useToggle, { useToggleV2 } from 'src/hooks/useToggle';
import ActionModal from 'src/components/ActionModal';
import { useV4ProjectContext } from '..';
import V4ProjectContentListTask, { V4TaskLineNew } from './V4ProjectContentListTask';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Iconify from 'src/components/Iconify';
import LoadingView from 'src/components/LoadingView';

export default function V4ProjectContentListSection({ projectId }) {
  //TODO : notify when a task is Moved
  const { board, loading } = useV4ProjectContext();
  const { columnOrder, columns } = board;
  const [hover, setHover] = useState();

  //console.log('board', board?.cards);

  return (
    <Stack width={1}>
      {loading ? (
        <Stack height="80vh" width={1} justifyContent="center" alignItems="center">
          <LoadingView />
        </Stack>
      ) : (
        !!columnOrder?.length &&
        columnOrder.map(
          (_colId, index) =>
            columns[_colId] && (
              <OneSection
                key={_colId + index}
                operation={columns[_colId]}
                hover={hover}
                setHover={setHover}
                index={index}
              />
            )
        )
      )}
    </Stack>
  );
}

const More = ({ open, onClose, anchorEl, onEdit, onDelete }) => {
  return (
    <CustomMenuPopover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      disabledArrow
    >
      <MenuLine onClick={onEdit} icon="eva:edit-outline" title="Modifer le titre de l'opération" disabled={!onEdit} />
      <Divider />
      <MenuLine
        onClick={onDelete}
        color={baseColors.REDD}
        icon="eva:trash-outline"
        title="Supprimer l'opération"
        disabled={!onDelete}
      />
    </CustomMenuPopover>
  );
};

export const OneSection = ({ operation, index, hover, setHover }) => {
  const { open, handleSwitch } = useToggle(true);
  const [create, onCreate, endCreate] = useToggleV2();
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(operation?.name || ''); // Ajoutez un état pour le nom édité
  const anchorEl = useRef(null); // Élément cible pour le positionnement
  const [deleteOK, openDelete, closeDelete] = useToggleV2();
  const [canDelete, _, __, handleSwitchDelete] = useToggleV2();
  const [showMoreMenu, onShowMore, onCloseShowMore] = useToggleV2();
  const { taskRights: rights } = useV4ProjectContext();

  const canEdit = rights?.edit;
  const _canDelete = rights?.delete;
  const canCreate = rights?.create;

  const dispatch = useDispatch();

  const handleEditClick = () => {
    setIsEditing(true);
    anchorEl.current?.focus();
    onCloseShowMore();
  };

  const handleDeleteClick = () => {
    openDelete();
    onCloseShowMore();
  };

  const handleConfirmDelete = () => {
    // Placez ici la logique pour supprimer l'opération
    dispatch(deleteColumn(operation.id));
    closeDelete();
  };

  const handleCancelDelete = () => {
    closeDelete();
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
    }
    onCloseShowMore();
  };

  const handleEditNameChange = (event) => {
    if (!canEdit) return; // Ajout de cette ligne
    setEditedName(event.target.value);
    dispatch(updateColumn(operation.id, { ...operation, name: event.target.value }));
  };
  return (
    <>
      <Draggable draggableId={operation?.id} index={index} isDragDisabled={!canEdit}>
        {(provided, snap) => {
          return (
            <Stack
              {...provided.draggableProps}
              ref={provided.innerRef}
              direction="row"
              width={1}
              onMouseEnter={() => setHover(operation?.id)}
              onMouseLeave={() => setHover(null)}
            >
              <Box pt={0.1} width={27} {...provided.dragHandleProps}>
                {(hover === operation?.id || snap.isDragging) && (
                  <IconButton size="small">
                    <Icon icon="radix-icons:drag-handle-dots-2" height={15} width={15} />
                  </IconButton>
                )}
              </Box>

              <Stack width={1} mb={2} spacing={1}>
                <Stack direction="row" width={1} spacing={1} alignItems="center">
                  <CIconButton size="small" sx={{ p: 0.2 }} noBorder onClick={handleSwitch}>
                    <Icon icon="ic:baseline-arrow-drop-down" rotate={open ? 0 : 3} />
                  </CIconButton>
                  {isEditing ? (
                    canEdit ? ( // Ajout de cette condition
                      <>
                        <MuiCustomInput
                          size="small"
                          label="Nom de l'opération"
                          value={editedName}
                          autoFocus
                          onChange={handleEditNameChange}
                          onBlur={() => setIsEditing(false)}
                          onKeyUp={handleKeyUp}
                          sx={{
                            border: 'none',
                            typography: 'overline',
                            fontSize: 14,
                            fontWeight: 'fontWeightBold',
                            py: 0.3
                          }}
                        />
                      </>
                    ) : null // Ne rien rendre si canEdit est false
                  ) : (
                    <>
                      <Typography
                        variant="caption"
                        color="grey.600"
                        onClick={canEdit ? handleEditClick : undefined} // Ajout de cette condition
                        sx={{ cursor: 'pointer' }}
                      >
                        {operation?.name}
                      </Typography>
                      {hover === operation?.id && (
                        <Box ref={anchorEl}>
                          <CIconButton
                            size="small"
                            sx={{ p: 0.2, ml: 1 }}
                            noBorder
                            title="Plus d'options"
                            onClick={onShowMore}
                            aria-controls={showMoreMenu ? 'more-menu' : undefined}
                            aria-haspopup="true"
                          >
                            <Icon icon="eva:more-horizontal-outline" />
                          </CIconButton>
                        </Box>
                      )}

                      {showMoreMenu && (
                        <More
                          task={operation}
                          open={Boolean(showMoreMenu)}
                          onClose={onCloseShowMore}
                          anchorEl={anchorEl.current}
                          onEdit={canEdit ? handleEditClick : undefined} // Ajout de cette condition
                          onDelete={_canDelete ? handleDeleteClick : undefined} // Ajout de cette condition
                          onOpen={() => {}}
                          onDone={() => {}}
                          onAddPrioritie={() => {}}
                        />
                      )}
                    </>
                  )}
                </Stack>
                {open && !snap.isDragging && (
                  <>
                    <Droppable droppableId={operation?.id} type="task" isDropDisabled={!canEdit}>
                      {(prov, snapshot) => (
                        <Stack
                          width={1}
                          ref={prov.innerRef}
                          {...prov.droppableProps}
                          sx={{
                            transition: 'background-color 0.3s ease',
                            bgcolor: snapshot.isDraggingOver && 'rgba(233, 255, 90, 0.2)'
                          }}
                        >
                          <V4ProjectContentListTask column={operation} />
                          {prov.placeholder}
                        </Stack>
                      )}
                    </Droppable>
                  </>
                )}
                {open && !create && <CreateNewTask operation={operation} onCreate={onCreate} disabled={!canCreate} />}
                {open && create && <V4TaskLineNew operation={operation} endCreate={endCreate} />}
                {deleteOK && (
                  <ActionModal
                    open={deleteOK}
                    onClose={closeDelete}
                    title="Supprimer cette opération"
                    moreAction={
                      <Stack
                        alignItems="center"
                        direction="row"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleSwitchDelete}
                      >
                        <Checkbox checked={canDelete} />
                        <Typography>Vous êtes certains de vouloir supprimer cette opération ?</Typography>
                      </Stack>
                    }
                    action={
                      <Button variant="contained" onClick={handleConfirmDelete} disabled={!canDelete}>
                        Supprimer
                      </Button>
                    }
                  />
                )}
              </Stack>
            </Stack>
          );
        }}
      </Draggable>
    </>
  );
};

const CreateNewTask = ({ onCreate, disabled }) => {
  return (
    <Stack width={1} pl={3} onClick={!disabled ? onCreate : null} sx={{ cursor: !disabled ? 'pointer' : 'default' }}>
      <Stack direction="row" spacing={1} py={1} component={MenuItem}>
        <Iconify icon="eva:plus-fill" color="grey.600" />
        <Typography variant="caption" color="grey.600">
          Ajouter une tâche...
        </Typography>
      </Stack>
    </Stack>
  );
};
