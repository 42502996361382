import React, { useEffect, useMemo, useRef, useState } from 'react';
import { format } from 'date-fns';
import { Icon } from '@iconify/react';
import frLocale from 'date-fns/locale/fr';
import { nanoid } from '@reduxjs/toolkit';
import {
  Box,
  Chip,
  Stack,
  Paper,
  Button,
  Divider,
  MenuItem,
  InputBase,
  IconButton,
  Typography,
  FormControl,
  NativeSelect,
  Autocomplete,
  ClickAwayListener,
  Tooltip,
  ListItemText,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Avatar, Checkbox, DatePicker } from 'antd';
import ShowModal from '../../../components/ShowModal';
import MenuPopover from '../../../components/MenuPopover';
import { LabelStyle } from './KanbanTaskDetails';
import useToggle, { useToggleV2 } from '../../../hooks/useToggle';
import KanbanTaskManager from './KanbanTaskManager';
import { gDate } from '../../../utils/formatTime';
import plusFill from '@iconify/icons-eva/plus-fill';
import QuillEditor from '../../../components/editor/quill/index';
import { useDatePicker } from './ModelKanbanTaskAdd';
import bellFill from '@iconify/icons-eva/bell-outline';
import calendarOutlined from '@iconify/icons-eva/calendar-outline';
import editFill from '@iconify/icons-eva/edit-outline';
import deleteFill from '@iconify/icons-eva/trash-fill';
import closeFill from '@iconify/icons-eva/close-outline';
import { useTheme, alpha } from '@mui/material/styles';
import { BootstrapInput } from 'src/components/CustomSelect';
import { SelectCollaborates } from 'src/section/project/ProjectCardOPtion';
import {
  CustomAutocompletInput,
  MuiCustomInput,
  grey,
  blue,
  customTextFieldTheme,
  EditorMentionEnter
} from '../../../components/CustomInput';
import { TASK_STATE_VALIDATION, RAPPEL_EVENT_TIMES, TASK_VISIBILITY } from '../../../constants';
import { dateConverter } from '../../../helpers/dueDateConverter';
import { isEmpty, isEqual, uniqBy } from 'lodash';
import { MAvatar } from 'src/components/@material-extend';
import createAvatar from 'src/utils/createAvatar';
import { baseColors } from 'src/constants/color';
import { defaultTask, DisplayTime } from './KanbanTaskAdd';
import { isTaskDone, isTaskAccepted } from 'src/helpers/projectStateChecker';
import { auth } from 'src/contexts/FirebaseContext';
import useSubTask from 'src/hooks/useSubTask';
import { serverTime } from 'src/utils/serverTime';
import KanbanTaskDetailsComment from './KanbanTaskDetailsComment';
import LoadingView from 'src/components/LoadingView';
import ActionModal from 'src/components/ActionModal';
import { handleKeyDown } from 'src/helpers/lockEnterShiftKeyDown';
import { useProjectContext } from 'src/contexts/ProjectContext';
import { priorityColor } from 'src/helpers/priorityColor';
import PriorityFlags from '../PriorityFlags';
import EllipsisText from 'src/components/EllipsisText';
import Scrollbar from 'src/components/Scrollbar';
import { RejectComment } from './KanbanTaskCardValidation';
import DependencyTaskCanStartTrigger, { canStartTask } from '../dependency/DependencyTaskCanStartTrigger';

import { DEPS_TYPES } from 'src/constants/deps';
import { TaskDueDateGuardPrompt, useTaskDueDateGuard } from '../due_date/TaskDueDateGuard';
import UserPicker from 'src/components/UserPicker';
import budgetImg from 'src/assets/budget.png';
import useProject from 'src/hooks/useProject';
import useAuth from 'src/hooks/useAuth';
import { BudgetGuard } from 'src/section/tasks/budget/BudgetGuard';
import { getNumberFieldProps, parseNumber } from 'src/utils/formatNumber';
import { showEditorTaskTitleWithMention } from '../editor/helpers';
import { useV4ProjectContext } from 'src/section/project/v4/content/project_content';
import OkyCalendar from 'src/components/calendar/OkyCalendar';

export default function KanbanTaskDetailsInfo({
  users,
  details,
  classes,
  userId,
  project,
  userLoading,
  handleManganer,
  handleDetailChange,
  rigths = { read: true, create: true, edit: false, delete: true }
}) {
  const theme = useTheme();
  const { open, handleClose, handleOpen } = useToggle();
  const [isReject, onReject, endReject] = useToggleV2();

  const { validateNewDue, ...params } = useTaskDueDateGuard(details, ({ due }) => {
    handleDetailChange('due', due);
  });

  const handleDueChange = (_, due) => {
    const val = [gDate(due[0] || null), gDate(due[1] || null)];

    if (validateNewDue(val)) {
      handleDetailChange('due', due);
    }
  };

  const canValidate = React.useMemo(() => {
    if (details?.managers?.length) {
      return details?.managers.find((one) => one?.id === userId) ? true : false;
    }
    return details?.createdBy?.id === userId;
  }, [details, userId]);

  const canStart = React.useMemo(() => canStartTask(details), [details]);
  const enableStateSelect = React.useMemo(() => {
    if (details?.state === TASK_STATE_VALIDATION.PENDING && !canStart) {
      return false;
    }
    return true;
  }, [canStart, details?.state]);

  const [visible, display, hide] = useToggleV2();
  const anchor = useRef();

  const handleStartTask = () => {
    if (!canStartTask(details)) {
      //console.log('canStartTask', details?.name, canStartTask(details));
      display();
      return;
    }
    handleValidateTask(TASK_STATE_VALIDATION.INPROGRESS);
  };

  const handleValidateTask = (_state) => {
    if (_state === TASK_STATE_VALIDATION.DONE) {
      handleOpen();
      return;
    }
    if (_state === TASK_STATE_VALIDATION.REJECTED) {
      onReject();
      return;
    }
    handleDetailChange('state', _state);
  };

  const rejectTask = () => {
    handleDetailChange('state', TASK_STATE_VALIDATION.REJECTED);
  };

  const closeManage = (newValues, autoValidate) => {
    handleClose();
    if (newValues?.length) {
      handleManganer(newValues, TASK_STATE_VALIDATION.DONE, false);
      return;
    }
    if (autoValidate) {
      handleManganer([], TASK_STATE_VALIDATION.DONE, false);
    }
  };

  const handleCommentChange = (comment) => {
    handleDetailChange('comments', [...details.comments, { ...comment }]);
  };

  const handleTitle = ({ newValue, mentions }) => {
    handleDetailChange(['name', 'editor_mentions'], [newValue, mentions]);
  };

  return (
    <Stack spacing={1}>
      <TaskDueDateGuardPrompt {...params} />

      <DependencyTaskCanStartTrigger card={details} open={visible} onClose={hide} anchor={anchor} />

      <Stack width={1} maxWidth={1} minHeight={80}>
        <Stack flexWrap="wrap" width={1} direction="row" spacing={2} rowGap={2} pt={2} pb={1} px={3}>
          <ResponsableView
            disabled={!rigths?.edit}
            users={users}
            userLoading={userLoading}
            details={details}
            handleAssigne={handleDetailChange}
            classes={classes}
          />
          <Stack height={40}>
            <Divider light orientation="vertical" />
          </Stack>
          <IntervenantView
            disabled={!rigths?.edit}
            users={users}
            userLoading={userLoading}
            details={details}
            handleAssigne={handleDetailChange}
            classes={classes}
          />
          <Stack height={40}>
            <Divider light orientation="vertical" />
          </Stack>
          <PriorityView details={details} handleDetailChange={handleDetailChange} />
          <Stack height={40}>
            <Divider light orientation="vertical" />
          </Stack>
          <Stack direction="row">
            <DateView details={details} handleDetailChange={handleDueChange} title="DATE DE DÉBUT" first />
            <Stack height={40}>
              <Icon icon="ep:arrow-right" height={40} color={baseColors.GREYLIGHTER} />
            </Stack>
            <DateView details={details} handleDetailChange={handleDueChange} title="DATE D'ÉCHÉANCE" />
          </Stack>
          <Stack height={40}>
            <Divider light orientation="vertical" />
          </Stack>

          <VisibilityView details={details} handleDetailChange={handleDetailChange} />

          <Stack height={40}>
            <Divider light orientation="vertical" />
          </Stack>

          <BudgetView details={details} handleDetailChange={handleDetailChange} />
        </Stack>
        <Divider light sx={{ mt: 0.7 }} />
      </Stack>

      <Stack height={`calc(100vmin - 200px)`}>
        <Scrollbar>
          <Stack height={1} spacing={3} p={3}>
            <Stack width={1}>
              <Stack width={1} direction="row" justifyContent="space-between">
                <LabelStyle>TITRE</LabelStyle>
                {details.createdAt && (
                  <LabelStyle sx={{ width: 'max-content' }}>
                    ajouté par{' '}
                    <span
                      style={{
                        color: '#3366FF',
                        textDecoration: 'underline'
                      }}
                    >
                      {' '}
                      {details.createdBy?.name || details.createdBy?.displayName}
                    </span>{' '}
                    le {format(gDate(details.createdAt) || gDate(), 'dd MMM yyyy HH:mm', { locale: frLocale })}
                  </LabelStyle>
                )}
              </Stack>

              <EditorMentionEnter useBackground value={details?.name} onChange={handleTitle} />
            </Stack>

            <Stack>
              <DescriptionView
                details={details}
                handleDetailChange={handleDetailChange}
                theme={theme}
                rigths={rigths}
              />
            </Stack>

            <Stack spacing={1} width={1}>
              <LabelStyle>STATUS</LabelStyle>

              <Stack width={1} spacing={1} alignItems="center" direction="row">
                <FormControl fullWidth variant="standard">
                  <NativeSelect
                    disabled={!enableStateSelect}
                    value={details?.state || TASK_STATE_VALIDATION.PENDING}
                    onChange={(e) => handleValidateTask(e.target.value)}
                    id="demo-customized-select-native"
                    style={{
                      fontSize: 17
                    }}
                    input={<BootstrapInput sx={{ fontSize: 17 }} fullWidth />}
                  >
                    {Object.entries(TASK_STATE_VALIDATION).map(([_, option], idx) => {
                      const canValidateOp =
                        option === TASK_STATE_VALIDATION.ACCEPTED || option === TASK_STATE_VALIDATION.REJECTED
                          ? canValidate
                          : true;
                      return (
                        <option style={{ fontSize: 17 }} disabled={!canValidateOp} value={option} key={option + idx}>
                          {option}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>

                {(!details?.state || details.state === TASK_STATE_VALIDATION.PENDING) && (
                  <Button color="primary" variant="contained" ref={anchor} onClick={handleStartTask} sx={{ px: 2 }}>
                    Démarrer
                  </Button>
                )}
                {details?.state === TASK_STATE_VALIDATION.INPROGRESS && (
                  <Button
                    disabled={!rigths?.edit}
                    onClick={() => handleValidateTask(TASK_STATE_VALIDATION.DONE)}
                    color="info"
                    sx={{
                      bgcolor: (theme) => (theme.palette.mode === 'dark' ? grey[900] : grey[50]),
                      px: 2
                    }}
                  >
                    Terminer
                  </Button>
                )}
                {details?.state === TASK_STATE_VALIDATION.DONE && (
                  <Stack direction="row" spacing={3}>
                    <Button
                      disabled={!canValidate || !rigths?.edit}
                      onClick={() => handleValidateTask(TASK_STATE_VALIDATION.REJECTED)}
                      color="error"
                      sx={{ bgcolor: (t) => alpha(t.palette.error.main, t.palette.action.hoverOpacity) }}
                    >
                      Rejeter
                    </Button>
                    <Button
                      disabled={!canValidate || !rigths?.edit}
                      onClick={() => handleValidateTask(TASK_STATE_VALIDATION.ACCEPTED)}
                      color="success"
                      sx={{ bgcolor: (t) => alpha(t.palette.success.main, t.palette.action.hoverOpacity) }}
                    >
                      Valider
                    </Button>
                  </Stack>
                )}
                {details?.state === TASK_STATE_VALIDATION.ACCEPTED && (
                  <Button
                    disabled
                    color="inherit"
                    onClick={() => handleValidateTask(TASK_STATE_VALIDATION.ACCEPTED)}
                    sx={{
                      bgcolor: (theme) => (theme.palette.mode === 'dark' ? grey[900] : grey[50]),
                      px: 2
                    }}
                  >
                    validée
                  </Button>
                )}
                {details?.state === TASK_STATE_VALIDATION.REJECTED && (
                  <Button
                    onClick={() => handleValidateTask(TASK_STATE_VALIDATION.INPROGRESS)}
                    color="error"
                    sx={{
                      bgcolor: (theme) => (theme.palette.mode === 'dark' ? grey[900] : grey[50]),
                      px: 2
                    }}
                  >
                    Recommencer
                  </Button>
                )}
                {isReject && (
                  <RejectComment
                    open={isReject}
                    onClose={endReject}
                    reject={rejectTask}
                    taskId={details.id}
                    intervenants={details.assignee}
                    managers={uniqBy([...(project?.managers || []), details.responsable], 'id')}
                    taskName={details?.name}
                  />
                )}
              </Stack>
            </Stack>

            <Stack>
              <TagView details={details} handleDetailChange={handleDetailChange} theme={theme} rigths={rigths} />
            </Stack>

            <Stack spacing={2}>
              <Stack direction="row" justifyContent="space-between">
                <LabelStyle>SOUS-TÁCHES</LabelStyle>
              </Stack>
              <Stack pl={4}>
                <SubTaskView
                  taskId={details?.id}
                  theme={theme}
                  project={project}
                  taskName={details?.name}
                  parent={details}
                />
              </Stack>
              <SubTaskNewLine theme={theme} details={details} />
            </Stack>

            <Stack width={1}>
              <RappelView disabled={!rigths?.edit} details={details} handleDetailChange={handleDetailChange} />
            </Stack>

            <Stack width={1} display={{ xs: 'block', md: 'none' }}>
              <KanbanTaskDetailsComment
                idQ="info"
                details={details}
                users={users}
                userId={userId}
                name={auth.currentUser?.displayName || ''}
                handleCommentChange={handleCommentChange}
              />
            </Stack>

            <Stack width={1}>
              {' '}
              <Typography height={200} color="transparent">
                bottom
              </Typography>{' '}
            </Stack>
          </Stack>
        </Scrollbar>
      </Stack>

      {open && (
        <KanbanTaskManager
          intervenants={[]}
          managers={details?.responsable ? [details?.responsable] : project?.managers || []}
          open={open}
          onClose={closeManage}
          users={users}
        />
      )}
    </Stack>
  );
}

export const PriorityView = ({ details, handleDetailChange }) => {
  const [openP, onOpenP, onCloseP] = useToggleV2();
  const priorityRef = useRef();
  const [priority, setPriority] = useState(details?.priority);

  const handleClose = (val) => {
    onCloseP();
    handleDetailChange('priority', val);
    setPriority(val);
  };

  return (
    <Stack>
      <LabelStyle width="max-content" fontSize={11}>
        PRIORITÉ
      </LabelStyle>
      {!isEmpty(priority) ? (
        <Stack width="max-content" justifyContent="center">
          <Tooltip title={priority} arrow>
            <IconButton ref={priorityRef} onClick={onOpenP} sx={{ border: `1px solid ${priorityColor(priority)}` }}>
              <Icon icon="eva:flag-fill" color={priorityColor(priority)} width={15} height={15} />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <Stack width="max-content" justifyContent="center">
          <Tooltip title="Ajouter une priorité" arrow>
            <IconButton ref={priorityRef} onClick={onOpenP} sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}>
              <Icon icon="eva:flag-outline" width={20} height={20} />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      {openP && (
        <PriorityFlags
          value={priority}
          open={openP}
          onClose={handleClose}
          onChange={setPriority}
          anchorRef={priorityRef}
        />
      )}
    </Stack>
  );
};

export const BudgetView = ({ details, handleDetailChange }) => {
  const ref = useRef();
  const { user } = useAuth();
  const { projects } = useProject({ id: user?.id, status: ['open'] });

  const [open, onOpen, onClose] = useToggleV2();
  const [value, setValue] = useState(details?.budget || 0);

  const currentProject = useMemo(
    () => projects?.find((el) => el?.id === details?.projectKey || el?.id === details?.idProject),
    [details?.idProject, details?.projectKey, projects]
  );
  const hasBudget = useMemo(() => {
    return Number(currentProject?.budget?.estimated || 0) > 0;
  }, [currentProject?.budget?.estimated]);

  const handleChange = (val) => {
    onClose();
    handleDetailChange('budget', val);
    setValue(val);
  };

  return (
    <>
      {hasBudget && (
        <Stack>
          <LabelStyle width="max-content" fontSize={11}>
            DÉPENSE
          </LabelStyle>
          <Stack width="max-content" justifyContent="center" onClick={onOpen} sx={{ cursor: 'pointer' }}>
            <Tooltip title={'Dépense pour cette tâche'} arrow>
              {value > 0 ? (
                <Typography mt={1}>
                  {value} {currentProject?.budget?.currency?.symbol}
                </Typography>
              ) : (
                <IconButton sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}>
                  <Stack component={'img'} src={budgetImg} width={20} height={20} />
                </IconButton>
              )}
            </Tooltip>
            <div ref={ref} />
          </Stack>

          <BudgetModal
            open={open}
            onClose={onClose}
            anchor={ref}
            onChange={handleChange}
            amount={value}
            symbol={currentProject?.budget?.currency?.symbol}
            projectBudget={currentProject?.budget}
          />
        </Stack>
      )}
    </>
  );
};

export const BudgetModal = ({
  amount = 0,
  symbol,
  onChange,
  open,
  onClose,
  anchor,
  projectBudget = {},
  secondPos = false
}) => {
  const theme = useTheme();
  const [value, setValue] = useState(amount || 0);
  const [visible, show, hide] = useToggleV2();

  const { used = 0, estimated = 0 } = projectBudget;

  const handleSave = () => {
    const change = parseNumber(value) - amount;
    if (used + change > estimated) {
      show();
      return;
    }
    onChange(value);
    onClose();
  };

  const handleForceSave = () => {
    onChange(value);
    onClose();
    hide();
  };

  return (
    <>
      <MenuPopover
        open={open}
        onClose={onClose}
        anchorEl={anchor.current}
        width={400}
        // arrow={'top-left'}
        arrow={'top-center'}
        horizontal={'center'}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Stack p={2} spacing={1}>
          <Typography>Dépense pour cette tâche</Typography>
          <TextField
            fullWidth
            label={''}
            size={'small'}
            variant="standard"
            // value={value}
            // onChange={event => setValue(Number(event.target.value))}

            {...getNumberFieldProps({ value, onChange: setValue })}
            sx={customTextFieldTheme(theme)}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <Stack direction={'row'} sx={{ cursor: 'pointer' }}>
                  <Typography>{symbol}</Typography>
                </Stack>
              )
            }}
          />
          <Stack direction={'row'} justifyContent={'end'} spacing={1} mt={1.5}>
            <Button size={'small'} color={'inherit'} onClick={onClose}>
              Annuler
            </Button>
            <Button size={'small'} variant={'contained'} color={'primary'} onClick={handleSave}>
              Valider
            </Button>
          </Stack>
        </Stack>
      </MenuPopover>
      <BudgetGuard
        open={visible}
        onClose={hide}
        amount={value}
        change={value - amount}
        budget={projectBudget}
        onAccept={handleForceSave}
      />
    </>
  );
};

export const VisibilityView = ({ details, handleDetailChange }) => {
  const [open, onOpen, onClose] = useToggleV2();
  const [value, setValue] = useState(details?.visibility);

  const { icon, color, tip } = useMemo(() => {
    if (value === TASK_VISIBILITY.PRIVATE)
      return {
        icon: 'bi:shield-lock-fill',
        color: 'orange',
        tip: {
          label: TASK_VISIBILITY.PRIVATE,
          desc: 'Accès restreint aux collaborateurs assignés à la tâche'
        }
      };
    return {
      icon: 'bi:shield-lock',
      color: null,
      tip: {
        label: TASK_VISIBILITY.PUBLIC,
        desc: 'Cette tâche est visible par toutes personnes invités au projet'
      }
    };
  }, [value]);

  const handleChange = (val) => {
    onClose();
    handleDetailChange('visibility', val);
    setValue(val);
  };

  const handlePrivate = () => {
    handleChange(TASK_VISIBILITY.PRIVATE);
  };

  const handleTap = () => {
    if (value === TASK_VISIBILITY.PRIVATE) {
      handleChange(TASK_VISIBILITY.PUBLIC);
    } else {
      onOpen();
    }
  };

  return (
    <Stack>
      <LabelStyle width="max-content" fontSize={11}>
        VISIBILITÉ
      </LabelStyle>
      <Stack width="max-content" justifyContent="center">
        <Tooltip
          title={
            <ListItemText
              primary={tip?.label}
              secondary={tip?.desc}
              secondaryTypographyProps={{
                whiteSpace: 'normal',
                color: 'white'
              }}
            />
          }
          arrow
        >
          <IconButton onClick={handleTap} sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}>
            <Icon icon={icon} color={color} width={20} height={20} />
          </IconButton>
        </Tooltip>
      </Stack>

      <VisibilityPrivateModal open={open} onClose={onClose} onAccept={handlePrivate} />
    </Stack>
  );
};

export const VisibilityPrivateModal = ({ open, onClose, onAccept }) => {
  const [privateTask, setPrivateTask] = useState(false);

  const handleAccept = () => {
    onAccept();
    onClose();
  };

  return (
    <>
      <ActionModal
        title="Rendre cette tâche privée"
        color="error"
        open={open}
        onClose={onClose}
        moreAction={
          <Stack direction="row" spacing={1} alignItems="center">
            <Checkbox
              size="small"
              color="error"
              checked={privateTask}
              onChange={(e) => setPrivateTask(e.target.checked)}
            />
            <Typography fontSize={13} fontWeight={120}>
              Je comprends qu'une fois fait , seules ceux qui sont invités sur cette tâche peuvent y accéder
            </Typography>
          </Stack>
        }
        action={
          <Button color="error" variant="contained" disabled={!privateTask} onClick={handleAccept}>
            Appliquer
          </Button>
        }
      />
    </>
  );
};

const TagView = ({ details, handleDetailChange, theme, rigths }) => {
  const [canAdd, setCanAdd] = useState(!isEmpty(details?.tags));

  return (
    <Stack width={1} spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <LabelStyle>TAGS</LabelStyle>
        {!canAdd ? (
          <IconButton size="small" color="primary" onClick={() => setCanAdd(true)}>
            <Icon icon={plusFill} />
          </IconButton>
        ) : null}
      </Stack>

      {canAdd ? (
        <Stack direction="row" width={1} spacing={1} alignItems="center">
          <Autocomplete
            disabled={!rigths?.edit}
            value={details?.tags || []}
            options={[]}
            multiple
            fullWidth
            onChange={(evant, newValue) => handleDetailChange('tags', newValue)}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip size="small" color="primary" variant="filled" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <CustomAutocompletInput
                {...params}
                size="small"
                fullWidth
                sx={{
                  '& .MuiInput-underline': {
                    borderColor: 'transparent'
                  }
                }}
                placeholder="Tags..."
              />
            )}
          />
        </Stack>
      ) : (
        <Divider />
      )}
    </Stack>
  );
};

export const SubTaskNewLine = ({ theme, details, exit }) => {
  const [openP, onOpenP, onCloseP] = useToggleV2();
  const priorityRef = useRef();
  const [title, setTitle] = useState('');
  const [mentions, setMentions] = useState(details?.editor_mentions || []);
  const [selected, setSelected] = useState(false);
  const [priority, setPriority] = useState('');
  const [assigne, setAssigne] = useState([]);

  const anchorRef = useRef(null);
  const { open, handleOpen, handleClose } = useToggle();

  const { addSubTask } = useProjectContext();

  //console.log({ details });

  const {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate,
    openPicker,
    onOpenPicker,
    onClosePicker
  } = useDatePicker({
    date: dateConverter([null, null])
  });

  const handleOpenPicker = () => {
    onOpenPicker();
    setSelected(true);
  };

  const handleDueDeteChange = (value) => {
    onChangeDueDate(value);
  };

  const handeChange = (_, value) => {
    const val = value.map((newValue) => {
      return {
        id: newValue.id,
        name: newValue?.displayName || newValue?.name || null,
        email: newValue.email,
        avatar: newValue?.photoURL || newValue?.photoUrl || newValue?.avatar || null
      };
    });
    setAssigne(val);
  };

  const onClose = () => {
    setSelected(false);
    exit && exit();
    if (!isEmpty(title)) {
      addSubTask(
        {
          ...defaultTask,
          name: title,
          editor_mentions: mentions,
          assignee: assigne,
          priority,
          due: dueDate
        },
        details,
        () => {
          setTitle('');
          onChangeDueDate([null, null]);
          setAssigne([]);
          setSelected(true);
        }
      );
    }
  };

  const onKeyUp = (event) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      onClose();
    }
  };

  const handleChangeTitle = ({ newValue, mentions }) => {
    setTitle(newValue);
    setMentions(mentions);
  };

  //TODO: ERROR ON DATE RESTRICTION.
  const disadledDue = (day) => {
    const due = details?.due || [];
    const date = gDate(day);
    //console.log({ date, due });
    if (!due[0] || !due[1]) {
      return false;
    }
    const dueMin = due[0]?.toDate();
    dueMin.setDate(dueMin.getDate() - 1);
    const dueMax = due[1]?.toDate();
    return date < dueMin || date > dueMax;
  };

  return (
    <Stack spacing={1}>
      <ClickAwayListener onClickAway={onClose}>
        <Box>
          {!selected ? (
            <Paper elevation={5} sx={{ width: '100%', borderRadius: 1 }} onClick={() => setSelected(true)}>
              <Stack direction="row" spacing={2} p={1}>
                <Icon icon="tabler:subtask" color={baseColors.GREY} />
                <Typography color="grey.400" fontSize={13}>
                  Nouvelle sous-tâche
                </Typography>
              </Stack>
            </Paper>
          ) : (
            <Stack
              direction="row"
              px={1}
              pt={0.5}
              pb={0.2}
              spacing={2}
              alignItems="center"
              sx={{ border: (t) => `1px solid ${t.palette.primary.main}`, borderRadius: 1 }}
              width={1}
            >
              <Icon icon="tabler:subtask" color={baseColors.GREY} height={35} width={35} />
              <Stack width={1}>
                <EditorMentionEnter
                  placeholder="Nouvelle sous-tâche"
                  fullWidth
                  autoFocus
                  onKeyUp={onKeyUp}
                  onKeyDown={handleKeyDown}
                  value={title}
                  onChange={handleChangeTitle}
                  sx={{ fontSize: 13, color: 'grey.600' }}
                />
              </Stack>
              <Stack spacing={1} direction="row">
                {endTime ? (
                  <Stack justifyContent="center" sx={{ width: 'max-content' }}>
                    <DisplayTime
                      noYear
                      endTime={endTime}
                      startTime={startTime}
                      isSameDays={isSameDays}
                      isSameMonths={isSameMonths}
                      onOpenPicker={handleOpenPicker}
                      sx={{ fontSize: 10, color: 'grey.500' }}
                    />
                  </Stack>
                ) : (
                  <IconButton
                    size="small"
                    onClick={handleOpenPicker}
                    sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
                  >
                    <Icon icon={calendarOutlined} />
                  </IconButton>
                )}

                <Stack
                  justifyContent="center"
                  onClick={handleOpen}
                  ref={anchorRef}
                  sx={{ width: 'max-content', cursor: 'pointer' }}
                >
                  {assigne.length ? (
                    <Avatar.Group size="small" maxPopoverPlacement="top" maxCount={1}>
                      {assigne.map((part) => (
                        <Avatar
                          key={part?.id}
                          size="small"
                          srcSet={part?.avatar}
                          style={{ backgroundColor: createAvatar(part?.name).color2 }}
                        >
                          {createAvatar(part?.name).name}
                        </Avatar>
                      ))}
                    </Avatar.Group>
                  ) : (
                    <IconButton
                      onClick={handleOpen}
                      size="small"
                      sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
                    >
                      <Icon icon="eva:person-add-outline" />
                    </IconButton>
                  )}
                </Stack>

                <Stack>
                  {!isEmpty(priority) ? (
                    <Stack>
                      <Tooltip title={priority}>
                        <IconButton ref={priorityRef} onClick={onOpenP} size="small">
                          <Icon
                            icon="eva:flag-fill"
                            color={priorityColor(priority)}
                            sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  ) : (
                    <Stack>
                      <Tooltip title="Pas de priorité">
                        <IconButton ref={priorityRef} onClick={onOpenP} size="small">
                          <Icon icon="eva:flag-outline" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  )}
                </Stack>

                <Stack justifyContent="center">
                  <Stack
                    py={0.3}
                    px={1}
                    color="white"
                    bgcolor={(t) => t.palette.primary.main}
                    fontSize={10}
                    fontWeight="bold"
                    sx={{ cursor: 'pointer' }}
                    onClick={onClose}
                  >
                    ENREGISTRER
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}

          {openPicker && (
            <OkyCalendar
              dates={[startTime, endTime]}
              open={openPicker}
              onClose={onClosePicker}
              onChange={handleDueDeteChange}
              anchorEl={anchorRef.current}
              dataInterval={dueDate}
              disabled={disadledDue}
            />
          )}

          {open && (
            <MenuPopover
              zIndex={(theme) => theme?.zIndex?.modal + 5}
              width={400}
              sx={{
                height: { xs: 1, md: 550 },
                bgcolor: 'transparent',
                border: 'none',
                outline: 'none',
                boxShadow: 'none'
              }}
              open={open}
              onClose={handleClose}
              anchorEl={anchorRef.current}
              arrow="right-center"
            >
              <SelectCollaborates
                title="intervenants"
                field="assignee"
                currents={assigne}
                onClose={handleClose}
                onChange={handeChange}
              />
            </MenuPopover>
          )}
          {openP && (
            <PriorityFlags
              value={priority}
              open={openP}
              onClose={onCloseP}
              onChange={setPriority}
              anchorRef={priorityRef}
            />
          )}
        </Box>
      </ClickAwayListener>
    </Stack>
  );
};

export const SubTaskOneLine = ({
  subTask,
  theme,
  taskId,
  onClick,
  disabled = false,
  rights = null,
  isFocus = false
}) => {
  const priorityRef = useRef();
  const priorityRef2 = useRef();
  const anchorRef = useRef(null);
  const [openP, onOpenP, onCloseP] = useToggleV2();
  const [openP2, onOpenP2, onCloseP2] = useToggleV2();
  const [openP3, onOpenP3, onCloseP3] = useToggleV2();
  const [checked, setChecked] = useState(isTaskAccepted(subTask) || isTaskDone(subTask) ? true : false);
  const [deleting, setDeleting] = useState(false);
  const [deleteOk, setDeleteOK] = useState(false);
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState(subTask?.name);
  const [mentions, setMentions] = useState(subTask?.editor_mentions || []);
  const [priority, setPriority] = useState(subTask?.priority);
  const [assigne, setAssigne] = useState(subTask?.assignee);

  const taskRights = rights;

  //console.log('taskRights', taskRights);

  // taskRights: { create: true, edit: true, delete: false }

  const [open, handleOpen, handleClose] = useToggleV2();
  const [hover, onHover, endHover] = useToggleV2();
  const [openD, handleOpenD, handleCloseD] = useToggleV2();

  const { deleteTask, updateTask } = useProjectContext();

  const handleOnClick = () => {
    //onClick(subTask);
  };

  const onEdit = () => {
    setTitle(subTask?.name);
    setEdit(true);
  };

  const handeChange = (_, value) => {
    const val = value.map((newValue) => {
      return {
        id: newValue.id,
        name: newValue?.displayName || newValue?.name || null,
        email: newValue.email,
        avatar: newValue?.photoURL || newValue?.photoUrl || newValue?.avatar || null
      };
    });
    setAssigne(val);
  };

  const onCloseEditing = () => {
    if (openP3) {
      return false;
    } else {
      setEdit(false);
    }

    if (
      !isEmpty(title) &&
      (!isEqual(subTask?.priority, priority) ||
        !isEqual(subTask?.assignee, assigne) ||
        !isEqual(subTask?.name, title) ||
        !isEqual(subTask?.due, dueDate))
    ) {
      const user = auth.currentUser;
      updateTask(
        {
          ...subTask,
          name: title,
          editor_mentions: mentions,
          priority,
          assignee: assigne,
          updatedAt: serverTime(),
          lastUpdatedBy: {
            id: user?.id || null,
            name: user?.displayName || '',
            email: user?.email || '',
            avatar: user?.photoURL || null
          }
        },
        subTask,
        false
      );
    }
  };

  const {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate,
    openPicker,
    onOpenPicker,
    onClosePicker
  } = useDatePicker({
    date: dateConverter(subTask?.due || [null, null])
  });

  const handleDueDeteChange = (value) => {
    onChangeDueDate(value);
  };

  const handleOpenPicker = () => {
    onOpenPicker();
    onOpenP3();
  };

  const handleDelete = () => {
    setDeleting(true);
    deleteTask(subTask?.id, taskId);
    setDeleting(false);
  };

  const onCheckChange = (e) => {
    setChecked(e.target.checked);
    const user = auth.currentUser;
    const check = e.target.checked;
    updateTask(
      {
        ...subTask,
        completed: check,
        state: check ? TASK_STATE_VALIDATION.DONE : TASK_STATE_VALIDATION.INPROGRESS,
        editor_mentions: mentions,
        updatedAt: serverTime(),
        lastUpdatedBy: {
          id: user?.id || null,
          name: user?.displayName || '',
          email: user?.email || '',
          avatar: user?.photoURL || null
        }
      },
      subTask,
      false
    );
  };

  const onKeyUp = (event) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      if (event.target.value) onCloseEditing();
    }
  };

  const closePriority2 = (_priority) => {
    onCloseP2();
    if (!isEmpty(title) && !isEqual(subTask?.priority, _priority)) {
      const user = auth.currentUser;
      updateTask(
        {
          ...subTask,
          name: title,
          priority: _priority,
          assignee: assigne,
          updatedAt: serverTime(),
          lastUpdatedBy: {
            id: user?.id || null,
            name: user?.displayName || '',
            email: user?.email || '',
            avatar: user?.photoURL || null
          }
        },
        subTask,
        false
      );
    }
  };

  const handleChangeTitle = ({ newValue, mentions }) => {
    setTitle(newValue);
    setMentions(mentions);
  };

  const [blink, setBlink] = useState(false);

  useEffect(() => {
    if (!isFocus) return;
    const interval = setInterval(() => {
      setBlink((prevBlink) => !prevBlink);
    }, 300);

    const timeout = setTimeout(() => {
      clearInterval(interval);
    }, 5000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [isFocus]);

  return (
    <Stack
      component={Paper}
      elevation={5}
      p={1}
      width={1}
      direction="row"
      alignItems="center"
      sx={{
        cursor: 'pointer',
        border: isFocus ? `2px solid ${blink ? 'red' : 'transparent'}` : 'none',
        transition: 'border-color 0.5s'
      }}
      spacing={1}
      mb={0.5}
      onMouseEnter={onHover}
      onMouseLeave={endHover}
    >
      <Stack direction="row" alignItems="center" spacing={1} width={1}>
        <Checkbox checked={checked} onChange={onCheckChange} disabled={!taskRights?.edit} />
        <Stack>
          {!isEmpty(priority) ? (
            <Stack>
              <Tooltip title={priority}>
                <IconButton ref={priorityRef2} onClick={onOpenP2} size="small" disabled={!taskRights?.edit}>
                  <Icon
                    icon="eva:flag-fill"
                    color={priorityColor(priority)}
                    sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          ) : (
            <Stack>
              <Tooltip title="Pas de priorité">
                <IconButton ref={priorityRef2} onClick={onOpenP2} size="small" disabled={!taskRights?.edit}>
                  <Icon icon="eva:flag-outline" />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
          {openP2 && (
            <PriorityFlags
              open={openP2}
              onClose={closePriority2}
              onChange={setPriority}
              anchorRef={priorityRef2}
              value={priority}
            />
          )}
        </Stack>

        <Avatar.Group maxPopoverPlacement="top" size="small" maxCount={3}>
          {assigne?.map((part) => (
            <Avatar
              key={part?.id}
              size="small"
              srcSet={part?.avatar}
              style={{ backgroundColor: createAvatar(part?.name).color2 }}
            >
              {createAvatar(part?.name).name}
            </Avatar>
          ))}
        </Avatar.Group>

        {!edit && (
          <Stack maxWidth={assigne?.length > 2 ? 0.67 : 0.8} onClick={handleOnClick}>
            <EllipsisText
              material
              text={showEditorTaskTitleWithMention(subTask?.name, mentions)}
              style={{ fontSize: 14 }}
              sx={{ textDecoration: isTaskDone(subTask) ? 'line-through' : '' }}
            />
          </Stack>
        )}

        {!edit && hover && (
          <Stack direction="row" spacing={1}>
            <Stack>
              <IconButton
                onClick={onEdit}
                color="primary"
                size="small"
                sx={{ border: (t) => `1px dashed ${t.palette.divider}`, p: 0.2 }}
                disabled={!taskRights?.edit}
              >
                <Icon icon={editFill} height={15} width={15} />
              </IconButton>
            </Stack>
            <Stack>
              <IconButton
                color="error"
                onClick={handleOpenD}
                size="small"
                sx={{ border: (t) => `1px dashed ${t.palette.divider}`, p: 0.4 }}
                disabled={!taskRights?.edit}
              >
                <Icon icon={deleteFill} height={12} width={12} />
              </IconButton>
            </Stack>
          </Stack>
        )}

        {!edit && <Stack width={1} color="transparent" onClick={handleOnClick}></Stack>}

        {edit && (
          <ClickAwayListener onClickAway={onCloseEditing}>
            <Stack
              direction="row"
              px={1}
              py={1}
              spacing={2}
              alignItems="center"
              sx={{ border: (t) => `1px solid ${t.palette.primary.main}`, borderRadius: 1 }}
              width={1}
            >
              <Stack width={1}>
                <EditorMentionEnter
                  placeholder="Nouvelle sous-tâche"
                  onKeyDown={handleKeyDown}
                  onKeyUp={onKeyUp}
                  value={title}
                  onChange={handleChangeTitle}
                />
              </Stack>
              <Stack>
                {startTime || endTime ? (
                  <Stack justifyContent="center" sx={{ minWidth: 'max-content' }}>
                    <DisplayTime
                      noYear
                      endTime={endTime}
                      startTime={startTime}
                      isSameDays={isSameDays}
                      isSameMonths={isSameMonths}
                      onOpenPicker={handleOpenPicker}
                      //disabled={true}
                      sx={{ fontSize: 10, color: 'grey.500' }}
                    />
                  </Stack>
                ) : (
                  <IconButton
                    onClick={handleOpenPicker}
                    size="small"
                    sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
                    color="grey.500"
                    aria-label="date picker"
                    variant="text"
                  >
                    <Icon icon="eva:calendar-outline" />
                  </IconButton>
                )}
              </Stack>
              <Stack
                justifyContent="center"
                onClick={handleOpen}
                ref={anchorRef}
                sx={{ width: 'max-content', cursor: 'pointer' }}
              >
                {assigne.length ? (
                  <Avatar.Group size="small" maxPopoverPlacement="top" maxCount={1}>
                    {assigne.map((part) => (
                      <Avatar
                        key={part?.id}
                        size="small"
                        srcSet={part?.avatar}
                        style={{ backgroundColor: createAvatar(part?.name).color2 }}
                      >
                        {createAvatar(part?.name).name}
                      </Avatar>
                    ))}
                  </Avatar.Group>
                ) : (
                  <IconButton
                    onClick={handleOpen}
                    size="small"
                    sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
                  >
                    <Icon icon="eva:person-add-outline" />
                  </IconButton>
                )}
              </Stack>

              <Stack>
                {!isEmpty(priority) ? (
                  <Stack>
                    <Tooltip title={priority}>
                      <IconButton ref={priorityRef} onClick={onOpenP} size="small">
                        <Icon
                          icon="eva:flag-fill"
                          color={priorityColor(priority)}
                          sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ) : (
                  <Stack>
                    <Tooltip title="Pas de priorité">
                      <IconButton ref={priorityRef} onClick={onOpenP} size="small">
                        <Icon icon="eva:flag-outline" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}
              </Stack>
              <Stack alignItems="center">
                <Stack
                  py={0.3}
                  px={1}
                  color="white"
                  bgcolor={(t) => t.palette.primary.main}
                  fontSize={10}
                  fontWeight="bold"
                  sx={{ cursor: 'pointer' }}
                  onClick={onCloseEditing}
                >
                  ENREGISTRER
                </Stack>
              </Stack>
              {open && (
                <MenuPopover
                  zIndex={(theme) => theme?.zIndex?.modal + 5}
                  width={400}
                  sx={{
                    height: { xs: 1, md: 550 },
                    bgcolor: 'transparent',
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none'
                  }}
                  open={open}
                  onClose={handleClose}
                  anchorEl={anchorRef.current}
                  arrow="right-center"
                >
                  <SelectCollaborates
                    title="intervenants"
                    field="assignee"
                    currents={assigne}
                    onClose={handleClose}
                    onChange={handeChange}
                  />
                </MenuPopover>
              )}
              {openP && (
                <PriorityFlags
                  open={openP}
                  onClose={onCloseP}
                  onChange={setPriority}
                  anchorRef={priorityRef}
                  value={priority}
                />
              )}
            </Stack>
          </ClickAwayListener>
        )}
      </Stack>

      {!edit && (startTime || endTime) && (
        <Stack justifyContent="center" sx={{ minWidth: 'max-content' }}>
          <DisplayTime
            noYear
            endTime={endTime}
            startTime={startTime}
            isSameDays={isSameDays}
            isSameMonths={isSameMonths}
            //onOpenPicker={handleOpenPicker}
            disabled={true}
            sx={{ fontSize: 10, color: 'grey.500' }}
          />
        </Stack>
      )}
      <OkyCalendar
        dates={[startTime, endTime]}
        open={openPicker}
        onClose={(e) => {
          onClosePicker(e);
          onCloseP3();
        }}
        onChange={handleDueDeteChange}
        anchorEl={anchorRef.current}
        dataInterval={dueDate}
      />

      {openD && (
        <ActionModal
          title="Supprimer la sous-tâche ?"
          desc="Cette action ne peut pas être annulée"
          color="error"
          open={openD}
          onClose={handleCloseD}
          moreAction={
            <Stack
              sx={{ cursor: 'pointer' }}
              direction="row"
              spacing={1}
              alignItems="center"
              onClick={() => setDeleteOK(!deleteOk)}
            >
              <Checkbox size="small" color="error" checked={deleteOk} />
              <Typography fontSize={13} fontWeight={120}>
                Je comprends qu'une fois supprimé, cette sous-tâche ne peut pas être récupérée.
              </Typography>
            </Stack>
          }
          action={
            <LoadingButton
              loading={deleting}
              color="error"
              variant="contained"
              disabled={!deleteOk}
              onClick={handleDelete}
            >
              Supprimer
            </LoadingButton>
          }
        />
      )}
    </Stack>
  );
};

export const SubTaskView = ({ taskId, theme, parent, disabled = false, rights = null, focusId = null }) => {
  const { loading, subTasks } = useSubTask({ taskId: taskId });
  const { onOpenSub } = useProjectContext();

  const onClick = (_sub) => {
    onOpenSub(parent, _sub);
  };

  // Triez les sous-tâches pour que celle qui est 'isFocus' soit en tête
  const sortedSubTasks = focusId ? [...subTasks]?.sort((a, b) => (focusId === b.id) - (focusId === a.id)) : subTasks;

  return loading ? (
    <Stack width={1} justifyContent="center" p={1}>
      <LoadingView />
    </Stack>
  ) : (
    sortedSubTasks &&
    sortedSubTasks.map((subTask) => (
      <SubTaskOneLine
        isFocus={focusId === subTask?.id}
        key={subTask?.id}
        subTask={subTask}
        taskId={taskId}
        onClick={onClick}
        theme={theme}
        disabled={disabled}
        rights={rights}
      />
    ))
  );
};

export const DescriptionView = ({ details, handleDetailChange, theme, rigths }) => {
  const [canAdd, setCanAdd] = useState(
    !isEmpty(details?.description) &&
    details?.description.trim() !== '<p></p>' &&
    details?.description.trim() !== '<p><br></p>'
  );

  return (
    <Stack width={1} spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <LabelStyle>DESCRIPTION</LabelStyle>
        {!canAdd ? (
          <IconButton size="small" color="primary" onClick={() => setCanAdd(true)}>
            <Icon icon={plusFill} />
          </IconButton>
        ) : null}
      </Stack>

      {canAdd ? (
        <QuillEditor
          disabled={!rigths?.edit}
          simple
          id="comment-compo-desc-id"
          placeholder="Description de la tâche"
          value={details.description}
          onChange={(e) => handleDetailChange('description', e)}
          canMention={true}
          setMensions={(mentions) => handleDetailChange('mentions', [...(details?.mentions || []), ...mentions])}
          sx={{
            backgroundColor: theme.palette.mode === 'dark' ? grey[900] : grey[50],
            color: theme.palette.mode === 'dark' ? grey[300] : grey[900],
            border: 'none',
            borderRadius: '5px',
            padding: '6px 12px',
            transition: 'all 200ms ease',
            '&:hover': {
              backgroundColor: theme.palette.mode === 'dark' ? null : grey[50],
              borderColor: theme.palette.mode === 'dark' ? grey[700] : grey[400]
            },
            '&:focus': {
              outline: `2px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]}`,
              outlineOffset: 0
            }
          }}
        />
      ) : (
        <Divider />
      )}
    </Stack>
  );
};

const OneAvatar = ({ user, idx, handleRemove }) => {
  const [hover, onHover, endHover] = useToggleV2();

  return (
    <Box display="inline-block" ml={-0.5} onMouseEnter={onHover} onMouseLeave={endHover}>
      {hover ? (
        <Box p={0.1} border={`2px solid ${createAvatar(user?.name).color2}`} borderRadius={10} position="relative">
          <Tooltip key={user.id + idx} title={user?.displayName || user?.name} arrow>
            <Avatar
              alt={user?.displayName || user?.name}
              src={user?.photoURL || user?.avatar}
              style={{
                color: 'white',
                backgroundColor: createAvatar(user?.displayName || user?.name).color2
              }}
            >
              {createAvatar(user?.displayName || user?.name).name}
            </Avatar>
          </Tooltip>
          <Box
            position="absolute"
            borderRadius={10}
            top={0}
            right={0}
            onClick={() => handleRemove(user.id)}
            sx={{ transform: 'translateY(-40%)', bgcolor: 'white', height: 20, cursor: 'pointer' }}
          >
            <Icon icon="eva:close-circle-fill" color={createAvatar(user?.name).color2} height={20} />
          </Box>
        </Box>
      ) : (
        <Tooltip key={user.id + idx} title={user?.displayName || user?.name} arrow>
          <Avatar
            alt={user?.displayName || user?.name}
            src={user?.photoURL || user?.avatar}
            style={{ color: 'white', backgroundColor: createAvatar(user?.displayName || user?.name).color2 }}
          >
            {createAvatar(user?.displayName || user?.name).name}
          </Avatar>
        </Tooltip>
      )}
    </Box>
  );
};

export const IntervenantView = ({ details, handleAssigne, disabled = false }) => {
  const anchorRef = useRef(null);
  const { open, handleOpen, handleClose } = useToggle();

  const handeChange = (field, value) => {
    const val = value.map((newValue) => {
      return {
        id: newValue.id,
        name: newValue?.displayName || newValue?.name || null,
        email: newValue.email,
        avatar: newValue?.photoURL || newValue?.photoUrl || newValue?.avatar || null
      };
    });
    handleAssigne('assignee', val);
  };

  const handleRemove = (id) => {
    const rest = details.assignee.filter((one) => one?.id !== id);
    handleAssigne('assignee', rest);
  };

  return (
    <Stack>
      <LabelStyle width="max-content" fontSize={11}>
        INTERVENANTS
      </LabelStyle>

      <Stack alignItems="center" direction="row" sx={{ cursor: 'pointer' }} ref={anchorRef}>
        {!isEmpty(details.assignee) && (
          <Avatar.Group maxCount={4}>
            {details.assignee.map((user, idx) => (
              <OneAvatar key={user.id + idx} user={user} idx={idx} handleRemove={handleRemove} />
            ))}
          </Avatar.Group>
        )}
        <Tooltip title=" Ajouter / Retirer les collaborateurs" arrow>
          <IconButton
            disabled={disabled}
            color="inherit"
            sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
            onClick={handleOpen}
          >
            <Icon icon="fluent:people-community-add-28-regular" color={baseColors.GREY} width={20} height={20} />
          </IconButton>
        </Tooltip>
      </Stack>

      <UserPicker
        anchorEl={anchorRef}
        open={open}
        onClose={handleClose}
        onChange={handeChange}
        users={details?.assignee || []}
        restrictToProjectFromTask={details}
      />
    </Stack>
  );
};

export const ResponsableView = ({ details, handleAssigne, disabled = false }) => {
  const anchorRef = useRef(null);
  const { open, handleOpen, handleClose } = useToggle();
  const [hover, onHover, endHover] = useToggleV2();

  const handeChange = (field, value) => {
    const val = value.map((newValue) => {
      return {
        id: newValue.id,
        name: newValue?.displayName || newValue?.name || null,
        email: newValue.email,
        avatar: newValue?.photoURL || newValue?.photoUrl || newValue?.avatar || null
      };
    });
    handleAssigne('responsable', val.at(0));
  };

  const handleRemove = (id) => {
    handleAssigne('responsable', null);
  };

  return (
    <Stack>
      <LabelStyle width="max-content" fontSize={11}>
        SUPERVISEUR
      </LabelStyle>
      <Stack alignItems="center" onMouseEnter={onHover} onMouseLeave={endHover}>
        {details.responsable ? (
          hover ? (
            <Box
              p={0.1}
              border={(t) => `2px solid ${createAvatar(details?.responsable?.name).color2}`}
              borderRadius={10}
              position="relative"
            >
              <Tooltip title={details?.responsable?.name || ''} arrow>
                <MAvatar
                  alt={details?.responsable?.name}
                  src={details?.responsable?.photoURL || details?.responsable?.avatar}
                  color={createAvatar(details?.responsable?.name).color}
                  style={{ color: 'white', height: 30, width: 30, fontSize: 13 }}
                >
                  {createAvatar(details?.responsable?.name).name}
                </MAvatar>
              </Tooltip>
              <Box
                position="absolute"
                borderRadius={10}
                top={0}
                right={0}
                onClick={handleRemove}
                sx={{ transform: 'translateY(-40%)', bgcolor: 'white', height: 20, cursor: 'pointer' }}
              >
                <Icon
                  icon="eva:close-circle-fill"
                  color={createAvatar(details?.responsable?.name).color2}
                  height={20}
                />
              </Box>
            </Box>
          ) : (
            <Tooltip title={details?.responsable?.name || ''} arrow>
              <MAvatar
                alt={details?.responsable?.name}
                src={details?.responsable?.photoURL || details?.responsable?.avatar}
                color={createAvatar(details?.responsable?.name).color}
                style={{ color: 'white', height: 35, width: 35, fontSize: 13 }}
              >
                {createAvatar(details?.responsable?.name).name}
              </MAvatar>
            </Tooltip>
          )
        ) : (
          <Tooltip title=" Ajouter / Retirer un superviseur" arrow>
            <IconButton
              disabled={disabled}
              color="inherit"
              sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
              onClick={handleOpen}
              ref={anchorRef}
            >
              <Icon icon="octicon:person-add-24" color={baseColors.GREY} width={20} height={20} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <UserPicker
        anchorEl={anchorRef}
        open={open}
        onClose={handleClose}
        onChange={handeChange}
        title={'intervenants'}
        field={'responsable'}
        users={details?.responsable ? [details?.responsable] : []}
        single
        restrictToProjectFromTask={details}
      />
    </Stack>
  );
};

const style = {
  typography: 'caption',
  cursor: 'pointer',
  '&:hover': { opacity: 0.72 },
  bgcolor: (theme) => (theme.palette.mode === 'dark' ? grey[900] : grey[50]),
  p: 1
};

const CustomDisplayTime = ({ startTime, endTime, onOpenPicker, sx }) => {
  return (
    <Stack justifyContent="space-between" direction="row">
      <Box onClick={onOpenPicker} sx={{ ...style, ...sx }}>
        <Stack direction="row" width={1} justifyContent="space-between">
          <LabelStyle>DATE </LabelStyle>
          <Box minWidth="max-content">{format(gDate(startTime), 'dd MMMM', { locale: frLocale })}</Box>
          &nbsp; - &nbsp;
          <Box minWidth="max-content">{format(gDate(endTime), 'dd MMMM yyyy', { locale: frLocale })}</Box>
        </Stack>
      </Box>
    </Stack>
  );
};

export const DateLimiteView = ({ details, handleDetailChange, disabled = false }) => {
  const Gtheme = useTheme();
  const anchorRef = useRef(null);

  const { dueDate, startTime, endTime, onChangeDueDate, openPicker, onOpenPicker, onClosePicker } = useDatePicker({
    date: dateConverter(details.due)
  });

  const handleDueDeteChange = (value) => {
    onChangeDueDate(value);
    handleDetailChange('due', value);
  };

  return (
    <Stack width={1} spacing={1} sx={{ pt: 1 }}>
      <Stack width={1} direction="row" justifyContent="space-between" alignItems="center">
        <LabelStyle sx={{ width: 150 }}> DURÉE PLANIFIÉE</LabelStyle>
        <Tooltip title=" Ajouter / Changer">
          <IconButton disabled={disabled} size="small" color="primary" onClick={onOpenPicker}>
            <Icon icon={plusFill} width={20} height={20} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Divider />
      <>
        {startTime && endTime && (
          <CustomDisplayTime
            startTime={startTime}
            endTime={endTime}
            onOpenPicker={onOpenPicker}
            sx={{ typography: 'body2' }}
            ref={anchorRef}
          />
        )}
        <OkyCalendar
          dates={[startTime, endTime]}
          open={openPicker}
          onClose={onClosePicker}
          onChange={handleDueDeteChange}
          anchorEl={anchorRef.current}
          dataInterval={dueDate}
        />
      </>
    </Stack>
  );
};

export const DateView = ({ details, handleDetailChange, title, first = false }) => {
  const [hover, onHover, endHover] = useToggleV2();
  const [open, onOpen, _, handleToogle] = useToggleV2();

  const onChange = (value, dateString) => {
    if (first) {
      return handleDetailChange('due', [gDate(dateString), details?.due.at(1)]);
    }
    handleDetailChange('due', [details?.due.at(0), gDate(dateString)]);
  };

  const onRemove = () => {
    if (first) {
      return handleDetailChange('due', [null, details?.due.at(1)]);
    }
    handleDetailChange('due', [details?.due.at(0), null]);
  };

  return (
    <Stack onMouseEnter={onHover} alignItems="center" spacing={1} onMouseLeave={endHover} sx={{ cursor: 'pointer' }}>
      <LabelStyle width="max-content" fontSize={11}>
        {' '}
        {title}{' '}
      </LabelStyle>
      <Stack direction="row" spacing={1}>
        <Box width={17} onClick={onRemove}>
          {hover && <Icon icon="eva:close-circle-fill" height={17} color="red" />}
        </Box>
        <Stack position="relative">
          <DatePicker
            open={open}
            onOpenChange={handleToogle}
            style={{ position: 'absolute', opacity: 0 }}
            popupStyle={{ zIndex: 1550 }}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onChange={onChange}
          />
          <Typography
            fontSize={12}
            sx={{ ...(hover && { textDecoration: 'underline', textDecorationStyle: 'dashed' }) }}
          >
            {first ? (
              details?.due.at(0) ? (
                format(gDate(details?.due.at(0)), 'dd MMM yy, HH:mm', { locale: frLocale })
              ) : (
                <Icon icon="healthicons:i-schedule-school-date-time" height={30} color={baseColors.GREY} />
              )
            ) : details?.due.at(1) ? (
              format(gDate(details?.due.at(1)), 'dd MMM yy, HH:mm', { locale: frLocale })
            ) : (
              <Icon icon="healthicons:i-schedule-school-date-time" height={30} color={baseColors.GREY} />
            )}
          </Typography>
          <Box onClick={onOpen} position="absolute" sx={{ cursor: 'pointer' }} top={0} bottom={0} right={0} left={0} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const RappelView = ({ details, handleDetailChange, disabled = false }) => {
  const anchorRef = useRef(null);
  const { open, handleOpen, handleClose } = useToggle();
  const [messageError, setMessageError] = React.useState('');
  const { open: show, handleOpen: onShow, handleClose: onHide } = useToggle();
  const [rappel, setRappel] = React.useState(null);
  const [useDate, setUseDate] = React.useState(false);

  const onOpenNew = () => {
    setRappel({ id: nanoid(30), number: 1, option: RAPPEL_EVENT_TIMES.HOURS });
    handleOpen();
  };

  const onOpenUpdate = (rapl) => {
    setRappel(rapl);
    handleOpen();
  };

  const onRappelOPtionChange = (e) => {
    setRappel((r) => {
      return { ...r, option: e };
    });
  };

  const onRappelNumberChange = (e) => {
    setRappel((r) => {
      return { ...r, number: e };
    });
  };

  const onCloseRappel = () => {
    handleClose();
    setRappel(null);
  };

  const onValidate = () => {
    let toSeconds = 0;
    const endDate = gDate(details?.due[1]);

    if (!endDate) {
      setMessageError("Une date de fin de tâche est nécessaire à la création d'un rappel");
      onShow();
      return;
    }

    if (useDate) {
      if (details?.rappels?.find((_r) => _r?.id === rappel?.id)) {
        let rapls = details?.rappels || [];

        rapls = [...rapls].map((_rap) => {
          if (_rap?.id === rappel?.id) {
            return rappel;
          }
          return _rap;
        });
        handleDetailChange('rappels', rapls);
      } else {
        handleDetailChange('rappels', [...(details?.rappels || []), rappel]);
      }
      onCloseRappel();
      return;
    }

    if (rappel.option === RAPPEL_EVENT_TIMES.MINUTE) {
      toSeconds = rappel.number * 60;
    }

    if (rappel.option === RAPPEL_EVENT_TIMES.HOURS) {
      toSeconds = rappel.number * 3600;
    }

    if (rappel.option === RAPPEL_EVENT_TIMES.DAY) {
      toSeconds = rappel.number * 86400;
    }

    if (rappel.option === RAPPEL_EVENT_TIMES.WEEK) {
      toSeconds = rappel.number * 604800;
    }

    const finalSeconds = endDate.getTime() / 1000 - toSeconds;

    const toDate = gDate(finalSeconds * 1000);
    const theRappel = { ...rappel, date: toDate };
    setRappel(theRappel);

    if (details?.rappels?.find((_r) => _r?.id === theRappel?.id)) {
      let rapls = details?.rappels || [];
      rapls = [...rapls].map((_rap) => {
        if (_rap?.id === theRappel?.id) {
          return theRappel;
        }
        return _rap;
      });
      //console.log(rapls);
      handleDetailChange('rappels', rapls);
    } else {
      handleDetailChange('rappels', [...(details?.rappels || []), theRappel]);
    }
    onCloseRappel();
  };

  const removeRappel = (remove) => {
    const rest = details?.rappels?.filter((_rap) => _rap.id !== remove.id);
    handleDetailChange('rappels', rest);
  };

  return (
    <>
      <Stack spacing={1} width={1}>
        <Stack spacing={1} width={1} direction="row" alignItems="center" justifyContent="space-between">
          <LabelStyle width={240}> RAPPELS ET ALERTES </LabelStyle>
          <Tooltip title="Ajouter">
            <IconButton disabled={disabled} size="small" color="primary" onClick={onOpenNew} ref={anchorRef}>
              <Icon icon={plusFill} width={20} height={20} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Divider />

        {details?.rappels && details?.rappels?.length
          ? details?.rappels?.map((_rap) => {
            return _rap?.option ? (
              <MenuItem key={_rap?.id}>
                <Box component={Icon} icon={bellFill} width={20} height={20} />
                <Box width={10} />
                <Typography sx={{ flexGrow: 1 }}>
                  <span style={{ fontWeight: 'bolder' }}>
                    {' '}
                    {_rap?.number} {_rap?.option}{' '}
                  </span>{' '}
                  avant la date de fin
                </Typography>
                <IconButton
                  onClick={() => {
                    setUseDate(false);
                    onOpenUpdate(_rap);
                  }}
                >
                  <Icon icon={editFill} width={20} height={20} />
                </IconButton>
                <IconButton onClick={() => removeRappel(_rap)}>
                  <Icon icon={closeFill} width={20} height={20} />
                </IconButton>
              </MenuItem>
            ) : (
              <MenuItem key={_rap?.id}>
                <Box component={Icon} icon={bellFill} width={20} height={20} />
                <Box width={10} />
                <Typography sx={{ flexGrow: 1 }}>{gDate(_rap?.date).toLocaleString()}</Typography>
                <IconButton
                  onClick={() => {
                    setUseDate(true);
                    onOpenUpdate(_rap);
                  }}
                >
                  <Icon icon={editFill} width={20} height={20} />
                </IconButton>
                <IconButton onClick={() => removeRappel(_rap)}>
                  <Icon icon={closeFill} width={20} height={20} />
                </IconButton>
              </MenuItem>
            );
          })
          : null}

        <ActionModal
          open={open}
          onClose={onCloseRappel}
          title="Définir un rappel"
          moreAction={
            <Stack spacing={3} width={1}>
              {useDate && <DateViewRappel rappel={rappel} setRappel={setRappel} />}
              <Stack direction="row" spacing={3}>
                <Checkbox checked={useDate} onChange={(e) => setUseDate(e.target.checked)} />
                <Typography color="grey.600" variant="subtitle2">
                  {' '}
                  Ajouter une date ou et une heure
                </Typography>
              </Stack>
              {!useDate && (
                <Stack direction="row" spacing={1} width={1} alignItems="center">
                  <Icon icon={bellFill} width={50} height={50} />

                  <MuiCustomInput
                    type="number"
                    value={rappel?.number}
                    onChange={(e) => onRappelNumberChange(e.currentTarget.value)}
                    sx={{ width: 60, height: 40, pt: 3 }}
                    size="small"
                  />

                  <FormControl fullWidth variant="standard">
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={rappel?.option || ''}
                      onChange={(e) => onRappelOPtionChange(e.target.value)}
                      input={<BootstrapInput fullWidth sx={{ maxWidth: 200 }} />}
                    >
                      {Object.entries(RAPPEL_EVENT_TIMES).map(([_, option], idx) => {
                        return (
                          <option value={option} key={option + idx}>
                            {option}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </FormControl>

                  <Stack width={1}>
                    <Typography sx={{ width: 200 }} fontWeight="bold" color="grey.700">
                      {' '}
                      avant la date de fin
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </Stack>
          }
          action={
            <Button
              color="success"
              onClick={onValidate}
              sx={{ bgcolor: (t) => alpha(t.palette.success.main, t.palette.action.hoverOpacity) }}
            >
              Ajouter
            </Button>
          }
        />
      </Stack>
      {show && <ShowModal open={show} onClose={onHide} desc={messageError} />}
    </>
  );
};

export const DateViewRappel = ({ rappel, setRappel }) => {
  const [open, __, _, handleToogle] = useToggleV2();

  const onChange = (value, dateString) => {
    setRappel({ id: rappel.id, date: gDate(dateString) });
  };

  const onRemove = () => {
    setRappel({ id: rappel.id, date: null });
  };

  return (
    <Stack width={1} position="relative" sx={{ cursor: 'pointer' }}>
      <Stack>
        <MuiCustomInput
          fullWidth
          size="small"
          placeholder="Clique pour entrer la date"
          value={gDate(rappel?.date) ? gDate(rappel?.date).toLocaleString() : ''}
          readOnly
        />
      </Stack>
      <Stack direction="row" width={1} position="absolute" height={1}>
        <Stack width={0.92} justifyContent="end">
          <DatePicker
            open={open}
            onOpenChange={handleToogle}
            style={{ opacity: 0, height: 60 }}
            popupStyle={{ zIndex: 1550 }}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onChange={(_, sd) => onChange(_, sd)}
          />
        </Stack>
        <Stack width={17} justifyContent="center" onClick={() => onRemove()}>
          <Icon icon="eva:close-circle-fill" height={20} color="red" />
        </Stack>
      </Stack>
    </Stack>
  );
};
