import { Backdrop, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import DocumentReader from 'src/components/only-office/DocumentReader';
import { IOkyDriverFile } from 'src/models/IOkyDriver';
import { useSelector } from 'src/redux/store';
import { gDate } from 'src/utils/formatTime';
import { useDriverContext } from '../../../context/DriverContext';
import { CircularProgress } from '@mui/joy';
import { PATH_PAGE } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import {
  AUTHORIZATION_STATE,
  AUTHORIZATION_TYPE,
  DRVIER_ACCESS,
  FILES_TYPES,
  driverElementType
} from '../../../annexes/helpers';
import LightboxModal from 'src/components/LightboxModal';
import FilesReader from 'src/components/FilesReader';
import ActionModal from 'src/components/ActionModal';
import VideoAndAudioPlayerModal from 'src/components/VideoAndAudioPlayerModal';

/**
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {function} props.onClose
 * @param {IOkyDriverFile} props.item
 * @param {'view' | 'edit'} props.openMode
 */
export default function DriverFileViewer({ open, onClose, item, openMode = 'view' }) {
  const { users } = useSelector((state) => state.user);
  const { loadingEdting, editingDoc } = useDriverContext();
  const { docKeys } = useSelector((state) => state.oky_driver);
  const [selectedImage, setSelectedImage] = useState(0);

  const [unsupportedFileTypeModalOpen, setUnsupportedFileTypeModalOpen] = useState(false);

  const type = driverElementType(item?.type);
  //console.log('type', type);

  const isUnsupportedFileType = useMemo(() => {
    return (
      type !== FILES_TYPES.WORD.trim() &&
      type !== FILES_TYPES.EXCEL.trim() &&
      type !== FILES_TYPES.POWERPOINT.trim() &&
      type !== FILES_TYPES.PDF.trim() &&
      type !== FILES_TYPES.IMAGE &&
      type !== FILES_TYPES.VIDEO &&
      type !== FILES_TYPES.AUDIO &&
      type !== FILES_TYPES.OTHERS[0] &&
      type !== FILES_TYPES.OTHERS[1]
    );
  }, [type]);

  useEffect(() => {
    if (isUnsupportedFileType) {
      setUnsupportedFileTypeModalOpen(true);
    }
  }, [isUnsupportedFileType]);

  const handleCloseUnsupportedFileTypeModal = () => {
    setUnsupportedFileTypeModalOpen(false);
    onClose();
  };

  const { user } = useAuth();
  //driverElementType

  const creaatedBy = useMemo(() => users.find((one) => one?.id === item?.uploadedBy), [users, item]);

  const currentDocKey = useMemo(() => docKeys[item?.id], [docKeys, item]);

  const canEdit = useMemo(() => {
    if (item.uploadedBy === user?.uid) return true;

    const userAccess = item?.sharedWith?.find((one) => one?.userId === user?.uid);

    return Boolean(
      userAccess &&
      (userAccess.access === DRVIER_ACCESS.EDIT.key ||
        userAccess.access === DRVIER_ACCESS.SHARE.key ||
        userAccess.access === DRVIER_ACCESS.ALL.key)
    );
  }, [item, user?.uid]);

  const isAutorized = useMemo(() => {
    if (item?.private?.value) {
      const _access = item?.autorizationRequest || {};
      const _access1 = _access[user.uid] || {};
      const _access2 = _access1[AUTHORIZATION_TYPE.SHARE] || {};
      const _access3 = _access2?.permission || [];

      const _allAccepted = [..._access3].every((perm) => perm?.auth === AUTHORIZATION_STATE.ACCEPTED);

      return _access3?.length && _allAccepted;
    }
    return false;
  }, [item, user?.uid]);

  const documentViewItem = useMemo(
    () => ({
      key: item.id,
      url: item.url,
      title: item?.name,
      fileType: item.type,
      owner: creaatedBy?.displayName,
      uploaded: format(gDate(item.createdAt), 'yyyy-MM-dd h:mm aa')
    }),
    [creaatedBy?.displayName, item.createdAt, item.id, item?.name, item.type, item.url]
  );

  const onHandleEditDocument = () => {
    const state = {
      document: documentViewItem,
      docKey: currentDocKey?.docKey || item?.id,
      uploadName: item?.metadata?.name || item?.name,
      openMode: 'edit'
    };

    window.open(
      `${PATH_PAGE.edit_document}/${documentViewItem.key}/${currentDocKey?.docKey}`,
      '_blank',
      'noopener noreferrer'
    );
    window.localStorage.setItem(documentViewItem.key, JSON.stringify(state));
    onClose();
  };

  useEffect(() => {
    if (openMode === 'edit' && currentDocKey !== undefined) {
      onHandleEditDocument();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocKey, openMode]);

  return (
    <>
      {(type === FILES_TYPES.WORD.trim() ||
        type === FILES_TYPES.EXCEL.trim() ||
        type === FILES_TYPES.POWERPOINT.trim() ||
        type === FILES_TYPES.OTHERS.txt.trim() ||
        type === FILES_TYPES.OTHERS.plain.trim() ||
        type === FILES_TYPES.OTHERS.html.trim()) &&
        (openMode === 'edit' ? (
          <Backdrop open slotProps={{ root: { style: { zIndex: 9999 } } }}>
            <Stack bgcolor="#FFF" px={5} py={3} borderRadius={1} direction="row" alignItems="center" spacing={1}>
              <CircularProgress size="sm" />
              <Typography fontSize={13}>Chargement en cours...</Typography>
            </Stack>
          </Backdrop>
        ) : (
          <DocumentReader
            onClose={onClose}
            open={Boolean(open)}
            document={documentViewItem}
            uploadName={item?.metadata?.name || item?.name}
            docKey={editingDoc?.docKey || item.id}
            openMode={openMode}
            loading={loadingEdting}
            editable={canEdit}
          />
        ))}

      {type === FILES_TYPES.PDF.trim() && (
        <FilesReader open={open} onClose={onClose} docUrl={item?.url} fileName={item?.name} docType={item?.type} />
      )}

      {type === FILES_TYPES.IMAGE && (
        <LightboxModal
          images={[item.url]}
          photoIndex={selectedImage}
          setPhotoIndex={setSelectedImage}
          isOpen={item?.type?.includes('image') && open}
          onClose={onClose}
        />
      )}
      {(type === FILES_TYPES.VIDEO || type === FILES_TYPES.AUDIO)
        && <VideoAndAudioPlayerModal open={open} onClose={onClose} url={item.url} name={item.name} />}

      <ActionModal
        color="error"
        title="Alert"
        open={unsupportedFileTypeModalOpen && isUnsupportedFileType}
        onClose={handleCloseUnsupportedFileTypeModal}
        desc={`Le type de fichier sélectionné n'est pas pris en charge par notre application. Veuillez sélectionner un fichier avec un format compatible`}
        closeText="Fermer"
      />
    </>
  );
}
