import React, { useRef, useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useDispatch } from 'src/redux/store';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { Form, FormikProvider, useFormik } from 'formik';
import { useToggleV2 } from 'src/hooks/useToggle';

import * as Yup from 'yup';
import { isEmpty, values } from 'lodash';
import { AUDIENCE_BASE_TYPE, AUDIENCE_TYPE } from 'src/constants';
import { PATH_DASHBOARD } from 'src/routes/paths';

// import numGenerate from "src/utils/numGenerate";
import { createAudience, updateAudience } from 'src/redux/slices/audience';
import { Field, PhoneField } from 'src/components/CustomInput';
import { gDate, gfDateTime } from 'src/utils/formatTime';

import { Typography, Stack, Divider, IconButton, Button, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from 'antd';
import Modal from 'src/components/Modal';
import AssigneeView from 'src/components/AssigneeView';
import Center from 'src/components/Center';
import AudienceTableComment from 'src/section/_dashboard/acceuil-v2/audience/AudienceTableComment';
import MorePopover from 'src/components/MorePopover';
import { LabelStyle } from 'src/section/tasks/kanban/KanbanTaskDetails';

import { Icon } from '@iconify/react';
import ContactAutoComplete from 'src/section/_dashboard/acceuil-v2/ContactAutoComplete';
import useAudiencePeople from 'src/hooks/useAudiencePeople';
import AcceuilFormAction from 'src/section/_dashboard/acceuil-v2/AcceuilFormAction';
import MeetDays, { audienceProps } from 'src/components/meet_days/MeetDays';
import useDisponibility from 'src/hooks/useDisponibility';
import AudienceMinuteFormater from 'src/section/_dashboard/acceuil-v2/audience/AudienceMinuteFormater';

import { nanoid } from '@reduxjs/toolkit';
import { APPOINTMENT_TYPE, APPOINTMENT_TYPE_IVITE, APPOINTMENT_TYPE_LABEL } from 'src/helpers/accueil-helper';

const RAISONS = ['Privé', 'Société', 'Client', 'Autre'];

export const gL = (label) => {
  if (label === 'Privé') return 'Privée';
  return label;
};
export default function AudienceForm({ selected, open = false, onClose }) {
  const isEdit = Boolean(selected);
  const title = isEdit ? 'Editer une audience' : 'Créer une audience';
  const [raison, setRaison] = useState(selected?.audienceRaison || 'Privé');
  const [type, setType] = useState(selected?.audienceType || APPOINTMENT_TYPE.Appointment);

  const {
    user: { displayName, email: userEmail, id }
  } = useAuth();

  // const users = useMemo(()=> uniqBy([...responsables, { displayName, email: userEmail, poste, phoneNumber, id }], 'email'),
  //                                   [displayName, userEmail, id, phoneNumber, poste, responsables]);
  const {
    user: { role }
  } = useAuth();
  const canEdit = isEdit ? role?.audience?.edit : role?.audience?.create;

  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { disponibility } = useDisponibility();
  const { register } = useAudiencePeople();

  const navigateToDetails = (state) => {
    navigate(`${PATH_DASHBOARD.general.accueil.fiche}`, { replace: false, state: state });
  };

  const formSchema = Yup.object().shape({
    lastName: Yup.string().required('Le nom est requis'),
    firstName: Yup.string().required('Le prénom est requis'),
    // tel: Yup.string().required('Le Téléphone est requis'),
    // email: Yup.string().email('Entrer un email correct'),
    society: Yup.string(),
    societyAdress: Yup.string(),
    societyContact: Yup.string(),
    societyActivity: Yup.string(),
    // appointmentDate: type === APPOINTMENT_TYPE.MakingAppointment ?
    //     Yup.date().required('champ obligatoire') :
    //     Yup.date().nullable(),
    personToMeet: Yup.array().required('champ obligatoire'),
    info: Yup.string()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      lastName: selected?.lastName || '',
      firstName: selected?.firstName || '',
      tel: selected?.tel || '',
      email: selected?.email || '',
      society: selected?.society || '',
      societyAdress: selected?.societyAdress || '',
      societyContact: selected?.societyContact || '',
      societyActivity: selected?.societyActivity || '',
      appointmentDate: gDate(selected?.appointmentDate) || null,
      state: selected?.state || AUDIENCE_TYPE.WAITING,
      personToMeet: selected?.personToMeet || [],
      info: selected?.info || '',
      comment: selected?.comment || '',
      proposedDates: selected?.proposedDates || [],
      title: selected?.title || '',
      description: selected?.description || '',
      countryCode: selected?.countryCode || null
    },
    validationSchema: formSchema,
    onSubmit: async (values, { setErrors, setFieldError, setSubmitting, resetForm }) => {
      try {
        // if (isToJustify(values?.state) && values?.comment?.trim()?.length === 0) {
        //     setFieldError('comment', 'Le justificatif est requis')
        //     return
        // }

        setLoading(true);
        const canAccessEmail = values.personToMeet.map((pers) => {
          return pers.email;
        });
        let canAccessId = values.personToMeet.map((pers) => {
          return pers.id;
        });

        canAccessId = [...canAccessId];
        canAccessEmail.push(userEmail);

        const callback = (id) => {
          //console.log('called');
          enqueueSnackbar(`${isEdit ? 'Mise à ajour avec succès' : 'Enrégistré avec succès'}`, { variant: 'success' });
          if (isMountedRef.current) {
            setSubmitting(false);
            setLoading(false);
            resetForm();
          }
          type === APPOINTMENT_TYPE.MakingAppointment && navigateToDetails({ ...audienceObject, id: id });
          onClose();

          const {
            lastName,
            firstName,
            tel,
            email,
            society,
            societyAdress,
            societyContact,
            societyActivity,
            countryCode,
            _user
          } = values;

          register({
            id: _user?.id,
            lastName,
            firstName,
            tel,
            email,
            society,
            societyAdress,
            societyContact,
            societyActivity,
            countryCode
          });
        };

        let audienceObject = {
          ...values,
          canAccessId,
          canAccessEmail,
          // appointmentDate: type === APPOINTMENT_TYPE.Appointment ? new Date() : new Date(values.appointmentDate),
          userId: id,
          userName: displayName,
          audienceType: type,
          audienceRaison: raison,
          // numFile: numGenerate(),
          createAt: new Date(),
          updateAt: new Date(),
          id: nanoid()
        };

        if (!isEdit) {
          dispatch(createAudience(audienceObject, callback));
        }

        if (isEdit) {
          audienceObject = {
            state: selected.state,
            ...audienceObject,
            id: selected.id,
            numFile: selected.numFile,
            createAt: selected.createAt
          };
          dispatch(updateAudience(audienceObject, callback, selected));
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSumit: error });
          console.error(error);
          enqueueSnackbar("une erreur s'est produite ", { variant: 'error' });
          setSubmitting(false);
          setLoading(false);
        }
      }
    }
  });

  const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  const canSetAppointment = useMemo(() => {
    return Boolean(values?.personToMeet?.find((el) => el?.id === id));
  }, [id, values]);

  const handlePersonSelection = (newValue) => {
    const data = newValue.map((pers) => {
      return {
        id: pers.id,
        displayName: pers.displayName,
        email: pers.email,
        phoneNumber: pers.phoneNumber,
        poste: pers.poste
      };
    });
    setFieldValue('personToMeet', data);
  };

  const startCase = (field, value) => {
    var fchart = value.charAt(0).toUpperCase() + value.slice(1);
    setFieldValue(field, fchart);
  };

  const handleSave = () => {
    handleSubmit();
  };

  const handleCompleteUser = (user) => {
    const firstName = user?.firstName || user?.name.split(' ').slice(0, -1).join(' ');
    const lastName = user?.lastName || user?.name.split(' ').slice(-1).join(' ');

    setFieldValue('firstName', firstName);
    setFieldValue('lastName', lastName);
    setFieldValue('tel', user?.tel || '');
    setFieldValue('email', user?.email || '');

    setFieldValue('society', user?.society || '');
    setFieldValue('societyAdress', user?.societyAdress || '');
    setFieldValue('societyContact', user?.societyContact || '');
    setFieldValue('societyActivity', user?.societyActivity || '');
    setFieldValue('countryCode', user?.countryCode || null);

    setFieldValue('_user', user);
  };

  const handleStateChange = (field, value) => {
    if (field === 'state' && values.appointmentDate === null) {
      enqueueSnackbar("La date d'audience est obligatoire", { variant: 'warning' });
      return;
    }
    setFieldValue(field, value);
  };

  // console.log("selected", values)

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={title}
        maxWidth={'lg'}
        secondaryAction={
          <AcceuilFormAction
            selected={values}
            onChange={handleStateChange}
            isEdit={isEdit}
            disabled={!canEdit}
            onSubmit={handleSave}
            loading={loading}
          />
        }
      >
        <Stack height={'80vh'}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack direction={'row'} width={1} spacing={2} height={1}>
                <Stack width={'50%'} height={1}>
                  <Stack>
                    <Stack direction={'row'} flexWrap={'wrap'} spacing={2} rowGap={2} py={1} px={2}>
                      <TypeView value={type} onChange={setType} disabled={!canEdit} />

                      <Stack height={40}>
                        <Divider light orientation="vertical" />
                      </Stack>

                      <RaisonView value={raison} onChange={setRaison} disabled={!canEdit} />

                      <Stack height={40}>
                        <Divider light orientation="vertical" />
                      </Stack>

                      {isEdit && (
                        <Stack direction={'row'} spacing={2}>
                          <DateView
                            enabled={canSetAppointment}
                            label={"Date d'audience"}
                            value={values?.appointmentDate}
                            onChange={(val) => setFieldValue('appointmentDate', val)}
                            hasError={errors?.appointmentDate}
                            disabled={!canEdit}
                          />
                          <Stack height={40}>
                            <Divider light orientation="vertical" />
                          </Stack>
                        </Stack>
                      )}

                      <Stack>
                        <LabelStyle width="max-content" fontSize={11}>
                          PERSONNE À RENCONTRER
                        </LabelStyle>
                        <Center>
                          <AssigneeView
                            maxCount={1}
                            users={values?.personToMeet}
                            onChange={(users) => setFieldValue('personToMeet', users)}
                            hasError={errors?.personToMeet}
                            disabled={!canEdit}
                          />
                        </Center>
                      </Stack>

                      <Stack height={40}>
                        <Divider light orientation="vertical" />
                      </Stack>

                      {/*<Stack>*/}
                      {/*    <LabelStyle width='max-content' fontSize={11}>*/}
                      {/*        LIER GOOGLE AGENDA*/}
                      {/*    </LabelStyle>*/}
                      {/*    <Center>*/}
                      {/*        <IconButton size="small"*/}
                      {/*                    sx={{border: t => `1px dashed ${t.palette.divider}`, p: 1}}>*/}
                      {/*            <Icon icon={"akar-icons:google-fill"} height={17}/>*/}
                      {/*        </IconButton>*/}
                      {/*    </Center>*/}
                      {/*</Stack>*/}
                      {/*<Stack height={40}>*/}
                      {/*    <Divider light orientation='vertical'/>*/}
                      {/*</Stack>*/}
                      {/*    /!*<Stack>*!/*/}
                      {/*    /!*    <LabelStyle width='max-content' fontSize={11}>*!/*/}
                      {/*    /!*        LIER OUTLOOK AGENDA*!/*/}
                      {/*    /!*    </LabelStyle>*!/*/}
                      {/*    /!*    <Center>*!/*/}
                      {/*    /!*        <IconButton size="small"*!/*/}
                      {/*    /!*                    sx={{border: t => `1px dashed ${t.palette.divider}`, p: 1}}>*!/*/}
                      {/*    /!*            <Icon icon={"simple-icons:microsoftoutlook"} height={17}/>*!/*/}
                      {/*    /!*        </IconButton>*!/*/}
                      {/*    /!*    </Center>*!/*/}
                      {/*    /!*</Stack>*!/*/}

                      {/*    /!*<Stack height={40}>*!/*/}
                      {/*    /!*    <Divider light orientation='vertical'/>*!/*/}
                      {/*    /!*</Stack>*!/*/}
                    </Stack>
                    <Divider />
                  </Stack>

                  <Stack spacing={3} mt={1} p={2} px={3}>
                    {selected?.audienceType === APPOINTMENT_TYPE_IVITE && (
                      <Stack spacing={3}>
                        <Field
                          fullWidth
                          label="Titre"
                          error={Boolean(touched.title && errors.title)}
                          helperText={touched.title && errors.title}
                          {...getFieldProps('title')}
                          disabled={!canEdit}
                        />
                        <Field
                          fullWidth
                          multiline
                          label="Description"
                          error={Boolean(touched.description && errors.description)}
                          helperText={touched.description && errors.description}
                          {...getFieldProps('description')}
                          disabled={!canEdit}
                        />
                      </Stack>
                    )}

                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                      <Stack width={1}>
                        <ContactAutoComplete
                          fullWidth
                          label="Nom"
                          value={values.lastName}
                          onPick={handleCompleteUser}
                          onChange={(e) => startCase('lastName', e.target.value)}
                          error={Boolean(touched.lastName && errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                          disabled={!canEdit}
                        />
                      </Stack>

                      <Stack width={1}>
                        <ContactAutoComplete
                          onPick={handleCompleteUser}
                          label="Prénom"
                          error={Boolean(touched.firstName && errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                          value={values.firstName}
                          onChange={(e) => startCase('firstName', e.target.value)}
                          disabled={!canEdit}
                        />
                      </Stack>
                    </Stack>

                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                      <Stack width={1}>
                        <PhoneField
                          fullWidth
                          label="Téléphone"
                          error={Boolean(touched.tel && errors.tel)}
                          helperText={touched.tel && errors.tel}
                          {...getFieldProps('tel')}
                          countryCode={values.countryCode}
                          onCountryCodeChange={(code) => setFieldValue('countryCode', code)}
                          disabled={!canEdit}
                        />
                      </Stack>
                      <Stack width={1}>
                        <Field
                          fullWidth
                          label="Email"
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          {...getFieldProps('email')}
                          disabled={!canEdit}
                        />
                      </Stack>
                    </Stack>

                    {/*<AudienceMeetDays value={values.proposedDates} onChange={setFieldValue}/>*/}

                    {raison === 'Société' && (
                      <Stack>
                        <Typography fontWeight={'bold'}>{'Société'?.toUpperCase()}</Typography>
                        <Divider />
                      </Stack>
                    )}

                    {raison === 'Société' && (
                      <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                        <Stack width={1}>
                          <Field
                            fullWidth
                            disabled={!canEdit || raison !== 'Société'}
                            label="Nom"
                            value={values.society}
                            onChange={(e) => startCase('society', e.target.value)}
                          />
                        </Stack>
                        <Stack width={1}>
                          <Field
                            fullWidth
                            disabled={!canEdit || raison !== 'Société'}
                            label="Adresse"
                            {...getFieldProps('societyAdress')}
                          />
                        </Stack>
                      </Stack>
                    )}

                    {raison === 'Société' && (
                      <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                        <Stack width={1}>
                          <Field
                            fullWidth
                            disabled={!canEdit || raison !== 'Société'}
                            label="Activité"
                            {...getFieldProps('societyActivity')}
                          />
                        </Stack>
                        <Stack width={1}></Stack>
                      </Stack>
                    )}

                    <Stack>
                      <Divider />
                    </Stack>
                    <Field
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={5}
                      label="Motif de la visite "
                      error={Boolean(touched.info && errors.info)}
                      helperText={touched.info && errors.info}
                      {...getFieldProps('info')}
                      disabled={!canEdit}
                    />

                    {!isEmpty(values?.comment) && (
                      <Field
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={5}
                        label="Commentaire"
                        error={Boolean(touched.info && errors.info)}
                        helperText={touched.info && errors.info}
                        {...getFieldProps('comment')}
                        disabled={!canEdit}
                      />
                    )}
                  </Stack>
                </Stack>
                <Stack width={'50%'} spacing={3} py={2} height={1}>
                  <Typography fontWeight={'bold'} fontSize={18}>
                    Propostion de date d'audience
                  </Typography>

                  <Stack height={1}>
                    <MeetDays
                      {...audienceProps(values)}
                      disponibility={disponibility}
                      onChange={(list) => setFieldValue('proposedDates', list)}
                      disabled={!canEdit}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Modal>
    </>
  );
}

const Actions = ({ selected, onChange, onSubmit, isEdit, loading }) => {
  const ref = useRef();
  const [visible, show, hide] = useToggleV2();

  const handleRejection = (comment) => {
    onChange('state', AUDIENCE_BASE_TYPE.REJECTED);
    onChange('comment', comment);
    hide();
  };

  return (
    <Stack direction={'row'} spacing={2}>
      {isEdit && (selected?.state === AUDIENCE_BASE_TYPE.REJECTED || selected?.state === AUDIENCE_BASE_TYPE.FINISHED) && (
        <Tooltip title={'Mettre en attente'}>
          <IconButton
            size={'small'}
            sx={{ bgcolor: 'white' }}
            onClick={() => onChange('state', AUDIENCE_BASE_TYPE.WAITING)}
          >
            <Icon icon={'ei:redo'} />
          </IconButton>
        </Tooltip>
      )}

      {isEdit && selected?.state === AUDIENCE_BASE_TYPE.WAITING && (
        <Button color={'error'} variant={'contained'} size={'small'} sx={{ color: 'white' }} onClick={show}>
          Rejeter
        </Button>
      )}

      {isEdit && selected?.state === AUDIENCE_BASE_TYPE.WAITING && (
        <Button
          color={'success'}
          variant={'contained'}
          size={'small'}
          sx={{ color: 'white' }}
          onClick={() => onChange('state', AUDIENCE_BASE_TYPE.FINISHED)}
        >
          Accepter
        </Button>
      )}

      {isEdit && selected?.state === AUDIENCE_BASE_TYPE.REJECTED && (
        <Button
          color={'error'}
          variant={'contained'}
          size={'small'}
          sx={{ color: 'white' }}
          startIcon={<Icon icon={'eva:close-outline'} />}
        >
          Rejeté
        </Button>
      )}

      {isEdit && selected?.state === AUDIENCE_BASE_TYPE.FINISHED && (
        <Button
          color={'success'}
          variant={'contained'}
          size={'small'}
          sx={{ color: 'white' }}
          startIcon={<Icon icon={'akar-icons:check'} />}
        >
          Accepté
        </Button>
      )}

      <LoadingButton
        loading={loading}
        color={'secondary'}
        variant={'contained'}
        size={'small'}
        sx={{ color: 'white' }}
        onClick={onSubmit}
      >
        Enregister
      </LoadingButton>

      <AudienceTableComment
        anchor={ref}
        open={visible}
        onClose={hide}
        value={selected?.comment}
        onSave={handleRejection}
      />
    </Stack>
  );
};

const TypeView = ({ value, onChange, disabled }) => {
  return (
    <>
      <View
        label={"Type d'audience"}
        options={values(APPOINTMENT_TYPE)?.map((el) => ({
          label: APPOINTMENT_TYPE_LABEL[el],
          value: el
        }))}
        value={APPOINTMENT_TYPE_LABEL[value] || 'Invitation'}
        onChange={onChange}
        defaultValue={'Invitation'}
        disabled={disabled}
      />
    </>
  );
};

const RaisonView = ({ value, onChange, disabled }) => {
  return (
    <>
      <View label={'RAISON DE LA VISITE'} options={RAISONS} value={gL(value)} onChange={onChange} disabled={disabled} />
    </>
  );
};

const View = ({ label = '', value, onChange, options = [], defaultValue, disabled = false }) => {
  const ref = useRef();
  const [open, onOpen, onClose] = useToggleV2();

  return (
    <>
      <Stack ref={ref} onClick={() => !disabled && onOpen()} sx={{ cursor: 'pointer' }}>
        <LabelStyle width="max-content" fontSize={11}>
          {label?.toUpperCase() || defaultValue}
        </LabelStyle>
        <Center>
          <Typography>
            {value} <Icon icon={'gridicons:dropdown'} />
          </Typography>
        </Center>
      </Stack>

      <MorePopover
        anchor={ref}
        open={open}
        onClose={onClose}
        options={[
          ...options.map((type) => ({
            label: type?.label || type,
            callback: () => !disabled && onChange(type?.value || type)
          }))
        ]}
      />
    </>
  );
};

const DateView = ({ label = '', value, onChange, hasError = false, enabled, disabled = false }) => {
  const ref = useRef();
  const [open, onOpen, onClose, , onStateChange] = useToggleV2();

  const handleOpen = () => {
    !disabled && onOpen();
  };
  const handleChange = (_, val) => {
    onChange(gDate(val));
    onClose();
  };

  return (
    <>
      <Stack ref={ref} onClick={handleOpen} sx={{ cursor: 'pointer' }}>
        <LabelStyle width="max-content" fontSize={11}>
          {label?.toUpperCase()}
        </LabelStyle>
        <Center>
          {Boolean(value) ? (
            <Typography>{gfDateTime(value) || ''}</Typography>
          ) : (
            <IconButton
              onClick={handleOpen}
              size="small"
              sx={{ border: (t) => `1px dashed ${t.palette.divider}`, p: 1 }}
            >
              <Icon icon={'fontisto:date'} height={17} />
            </IconButton>
          )}
        </Center>
      </Stack>

      <AudienceMinuteFormater trigger={open}>
        {open && (
          <DatePicker
            open={open}
            onOpenChange={onStateChange}
            style={{ position: 'absolute', opacity: 0 }}
            popupStyle={{ zIndex: 1550 }}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onChange={handleChange}
          />
        )}
      </AudienceMinuteFormater>
    </>
  );
};
