import JSZip from 'jszip';
import { DRIVER_TYPE_FOLDER } from 'src/section/doc/okydriver/annexes/helpers';
import { createFileFromUrl, createFilesFromUrl } from './downloadFile';

/**
 *
 * @param {{
 *  files: Array<File>,
 * withFolder: boolean,
 * download_name?: string,
 * onError: () =>{},
 * onSuccess: (blob:Blob) =>{},
 * }} params
 */
export async function compressAndDownload({
  withFolder = false,
  files,
  download_name = 'Okydook Driver',
  onSuccess,
  onError
}) {
  const zip = new JSZip();

  const addDocumentToZip = async (node, parentPath) => {
    const fullPath = parentPath ? `${parentPath}/${node.name}` : node.name;
    console.log(fullPath);
    if (node?.type === DRIVER_TYPE_FOLDER) {
      if (node?.children && node?.children?.length > 0) {
        for (const child of node?.children) {
          await addDocumentToZip(child, fullPath);
        }
      } else {
        zip.folder(fullPath);
      }
    } else {
      const file = await createFileFromUrl({ file: node });
      zip.file(fullPath, file);
    }
  };

  if (!withFolder) {
    files.forEach((file) => {
      zip.file(file.name, file);
    });
  } else {
    for (const document of files) {
      await addDocumentToZip(document, '');
    }
  }

  zip
    .generateAsync({ type: 'blob' })
    .then((content) => {
      const url = window.URL.createObjectURL(content);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${download_name}.zip`;
      a.click();
      window.URL.revokeObjectURL(url);
      if (onSuccess) onSuccess(content);
    })
    .catch((error) => {
      console.error('Error compressing files:', error);
      if (onError) onError(error);
    });
}
