import React, { useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { isEmpty, uniq } from 'lodash';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  alpha
} from '@mui/material';
import { CircularProgress, Sheet } from '@mui/joy';
import CIconButton from 'src/components/CIconButton';
import Iconify from 'src/components/Iconify';
import TextMaxLine from 'src/components/TextMaxLine';
import { Affectation_Setting_model, Affectation_v4_model } from 'src/models/Affectation_v4_type';
import ContactsDialog from 'src/components/ContactsDialog';
import InputLine from 'src/components/CustomInputLine';
import { Avatar } from 'antd';
import createAvatar, { getAvatarUrl } from 'src/utils/createAvatar';
import { dispatch, useSelector } from 'src/redux/store';
import { AFFECT_OPERATION_TYPE, AFFECT_TYPE } from 'src/constants/affectation';
import { useAffectation_v4Context } from 'src/contexts/Affectation_v4Context';
import { LoadingButton } from '@mui/lab';
import useAuth from 'src/hooks/useAuth';
import { serverTime } from 'src/utils/serverTime';
import { createCouriel, save_referenceAlreadyExist } from 'src/redux/slices/affectation_v4';
import { useSnackbar } from 'notistack';
import { multipleFilesSave } from 'src/redux/slices/document';
import { nanoid } from '@reduxjs/toolkit';
import { useToggleV2 } from 'src/hooks/useToggle';
import ActionModal from 'src/components/ActionModal';

export default function AffectionForm({ open, onClose, isEnter, formId, constraintsRef }) {
  const { settings: { organigramme, defaultRecevierID } = Affectation_Setting_model } = useAffectation_v4Context();
  const [saving, setSaving] = useState(false);
  const [uploadings, setUploadingFiles] = useState([]);
  const [minimize, setMinimize] = useState(false);
  const [toSaved, setToSaved] = useState(false);
  const { users } = useSelector((state) => state.user);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    courriel_type: Yup.string(),
    courriel_reference: Yup.string(),
    courriel_date: Yup.string().nullable(),
    courriel_object: Yup.string(),
    correspondant: Yup.object().shape({
      title: Yup.string()
    }),
    save_reference: Yup.string(),
    save_date: Yup.string().nullable(),
    operation_type: Yup.string()
  });

  const _createAffectation = (affectation) => {
    dispatch(
      createCouriel({
        affectation: affectation,
        callback: () => {
          setSaving(false);
          onClose();
          enqueueSnackbar('Affectation créée avec succès', { variant: 'success' });
        },
        onError: () => onClose()
      })
    );
  };

  const formik = useFormik({
    initialValues: { ...Affectation_v4_model, courriel_type: isEnter ? AFFECT_TYPE.Enter : AFFECT_TYPE.Out },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (organigramme.length === 0) {
        enqueueSnackbar('Veuillez définir un organigramme pour créer une affectation', { variant: 'info' });
        return null;
      }

      const saveFile = () => {
        const handleFilesSave = (files = []) => {
          let received = files.map((_file) => ({
            ..._file,
            id: nanoid(),
            createdAt: serverTime(),
            lastUpdated: {
              by: user.uid,
              name: user.displayName,
              at: serverTime()
            }
          }));

          const _ass = organigramme?.map((org) => ({ ...org, users: [] }));
          const _due = organigramme?.map((org) => ({
            label: org.label,
            assigneBy: null,
            assigneTo: null,
            date: null
          }));

          const toSave = {
            ...values,
            defaultReceiver: defaultRecevierID,
            toDoIds: uniq([...defaultRecevierID, user.uid, ...values.coReceiver]),
            attachments: { ...values.attachments, received: received },
            assignationOrganigramme: _ass,
            due: _due,
            createdAt: serverTime(),
            createdBy: user.uid,
            notes: values.notes,
            updated: {
              by: user.uid,
              at: serverTime()
            }
          };

          //console.log({organigramme});

          _createAffectation(toSave);
        };

        multipleFilesSave(values.attachments.received, handleFilesSave, null, setUploadingFiles);
      };

      dispatch(
        save_referenceAlreadyExist({
          save_reference: values.save_reference,
          reject: () => { },
          resolve: (value) => {
            //console.log({ value })
            if (value === true) {
              setSaving(false);
              formik.setFieldError('save_reference', "Le numéro d'enregistrement est déjà utilisé");
              //quitte onSubmit sans enregistrer
              return null;
            } else {
              //_createAffectation(toSave)
              try {
                saveFile();
                setSaving(true);
              } catch (error) {
                //console.log({ error });
              }
            }
          }
        })
      );
    }
  });

  const handleChangeSize = () => {
    setMinimize((old) => !old);
  };

  const { values, handleSubmit, setFieldValue, errors, touched, handleBlur, setFieldError } = formik;

  const handleAddFieldWithUsers = (field, val = []) => {
    const userId = val.map((one) => one?.id);
    setFieldValue(field, userId);
  };

  const onClickAwwayhandleVerifieSaveReference = () => {
    if (!isEmpty(values.save_reference)) {
      dispatch(
        save_referenceAlreadyExist({
          save_reference: values.save_reference,
          reject: () => { },
          resolve: (value) => {
            if (value) {
              setFieldError('save_reference', "le numero d'enregistrement est deja utilisé");
            }
          }
        })
      );
    }
  };

  const uploadingsMoyen = useMemo(() => {
    let progress = 0;
    if (uploadings.length) {
      uploadings.forEach((_file) => {
        progress += _file?.progress;
      });
      const result = (progress / uploadings.length).toFixed(2);
      return Number(result);
    }
    return progress;
  }, [uploadings]);

  return (
    <>
      <FormikProvider value={formik}>
        {!minimize ? (
          <Dialog
            maxWidth="sm"
            fullWidth
            open={open}
            onClose={onClose}
            PaperProps={{ sx: { border: 'none', boxShadow: 'none', bgcolor: 'transparent', p: 2 } }}
            scroll="paper"
          >
            <DialogTitle
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: 'primary.main',
                pb: 2,
                bgcolor: 'background.paper',
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20
              }}
            >
              <Typography fontWeight="bold">COURRIER {isEnter ? 'ENTRANT' : 'SORTANT'}</Typography>
            </DialogTitle>

            <DialogContent sx={{ p: 3, position: 'relative', bgcolor: 'background.paper' }}>
              <Form noValidate onSubmit={handleSubmit}>
                <Stack spacing={2} pt={1.3}>
                  <Sheet
                    variant="outlined"
                    sx={{ borderRadius: 'md', p: 3, border: (t) => `0.5px solid ${t.palette.neutral[300]}` }}
                  >
                    <Stack spacing={1}>
                      <Stack direction="row" spacing={3}>
                        <Stack width={1}>
                          {!isEnter ? (
                            <InputLine
                              label="Numéro d'enregistrement"
                              type="text"
                              value={values.save_reference}
                              field="save_reference"
                              onChange={setFieldValue}
                              error={Boolean(errors.save_reference)}
                              helperText={errors.save_reference}
                              onClickAway={onClickAwwayhandleVerifieSaveReference}
                            />
                          ) : (
                            <InputLine
                              label="Référence"
                              value={values.courriel_reference}
                              field="courriel_reference"
                              onChange={setFieldValue}
                              error={Boolean(touched.courriel_reference && errors.courriel_reference)}
                              helperText={touched.courriel_reference && errors.courriel_reference}
                            />
                          )}
                        </Stack>
                        <Stack width={1}>
                          <InputLine
                            label="Date"
                            type="date"
                            value={values.courriel_date}
                            field="courriel_date"
                            onChange={setFieldValue}
                            error={Boolean(touched.courriel_date && errors.courriel_date)}
                            helperText={touched.courriel_date && errors.courriel_date}
                          />
                        </Stack>
                      </Stack>

                      <Stack width={1}>
                        <InputLine
                          label="Objet"
                          type="text"
                          value={values.courriel_object}
                          field="courriel_object"
                          onChange={setFieldValue}
                          error={Boolean(touched.courriel_object && errors.courriel_object)}
                          helperText={touched.courriel_object && errors.courriel_object}
                        />
                      </Stack>

                      <Stack direction="row" spacing={3}>
                        <Stack width={1}>
                          <InputLine
                            label="Expéditeur"
                            type="text"
                            value={values.correspondant.title}
                            field="correspondant.title"
                            onChange={setFieldValue}
                            error={Boolean(touched?.correspondant?.title && errors.correspondant?.title)}
                            helperText={touched?.correspondant?.title && errors.correspondant?.title}
                          />
                        </Stack>
                        <Stack width={1}>
                          <InputLine
                            label="Numéro de téléphone"
                            type="phone"
                            value={values.correspondant.contact}
                            field="correspondant.contact"
                            onChange={setFieldValue}
                          />
                        </Stack>
                      </Stack>
                      <Stack width={1}>
                        <InputLine
                          label="Addresse email de l'expéditeur"
                          type="email"
                          value={values.correspondant.email}
                          field="correspondant.email"
                          onChange={setFieldValue}
                          error={Boolean(touched?.correspondant?.email && errors.correspondant?.email)}
                          helperText={touched?.correspondant?.email && errors.correspondant?.email}
                        />
                      </Stack>

                    </Stack>
                  </Sheet>

                  <Stack px={3} spacing={1}>
                    <Stack direction="row" spacing={3}>
                      <Stack width={1}>
                        {!isEnter ? (
                          <InputLine
                            label="Référence"
                            value={values.courriel_reference}
                            field="courriel_reference"
                            onChange={setFieldValue}
                            error={Boolean(touched.courriel_reference && errors.courriel_reference)}
                            helperText={touched.courriel_reference && errors.courriel_reference}
                          />
                        ) : (
                          <InputLine
                            label="Numéro d'enregistrement"
                            type="text"
                            value={values.save_reference}
                            field="save_reference"
                            onChange={setFieldValue}
                            error={Boolean(errors.save_reference)}
                            helperText={errors.save_reference}
                            onClickAway={onClickAwwayhandleVerifieSaveReference}
                          />
                        )}
                      </Stack>
                      <Stack width={1}>
                        <InputLine
                          label="Date d'enregistrement"
                          type="date"
                          value={values.save_date}
                          field="save_date"
                          onChange={setFieldValue}
                          error={Boolean(touched.save_date && errors.save_date)}
                          helperText={touched.save_date && errors.save_date}
                        />
                      </Stack>
                    </Stack>
                    <Stack direction="row" spacing={3}>
                      <Stack width={1}>
                        <InputLine
                          label="Type d'opération"
                          type="select"
                          options={AFFECT_OPERATION_TYPE}
                          value={values.operation_type}
                          field="operation_type"
                          onChange={setFieldValue}
                          error={Boolean(touched.operation_type && errors.operation_type)}
                          helperText={touched.operation_type && errors.operation_type}
                        />
                      </Stack>
                      <Stack width={1}>
                        <InputLine
                          label="Priorité"
                          type="checkbox"
                          value={values.isPriority}
                          field="isPriority"
                          onChange={setFieldValue}
                          error={Boolean(touched.isPriority && errors.isPriority)}
                          helperText={touched.isPriority && errors.isPriority}
                        />
                      </Stack>
                    </Stack>
                    <Stack direction="row" spacing={3}>
                      <Stack width={1} spacing={1}>
                        <Typography fontSize={11} fontWeight={400}>
                          Co-Destinataire (intérimaire)
                        </Typography>
                        <Stack direction="row" spacing={1}>
                          {!!values.coReceiver.length && (
                            <Avatar.Group maxCount={5}>
                              {values.coReceiver?.map((userId) => {
                                const user = users.find((one) => one?.id === userId);
                                return (
                                  <Tooltip key={userId} title={user?.displayName || ''} arrow>
                                    <Avatar
                                      key={userId}
                                      src={getAvatarUrl(user)}
                                      style={{
                                        backgroundColor: createAvatar(user?.displayName).color2,
                                        fontWeight: 'bold',
                                        borderRadius: 10,
                                        marginRight: 8
                                      }}
                                    >
                                      {createAvatar(user?.displayName).name}
                                    </Avatar>
                                  </Tooltip>
                                );
                              })}
                            </Avatar.Group>
                          )}

                          <ContactsDialog
                            assigne={values.coReceiver}
                            isSimpleUserID
                            onAssigne={(val) => handleAddFieldWithUsers('coReceiver', val)}
                            title="Liste de co-Destinataire"
                            action={(popoverRef, onOpen) => (
                              <Box ref={popoverRef}>
                                <CIconButton title="Ajouter des destinataires" onClick={onOpen}>
                                  <Iconify icon="eva:plus-fill" />
                                </CIconButton>
                              </Box>
                            )}
                          />
                        </Stack>
                      </Stack>
                      <Stack width={1}>
                        <InputLine
                          label="Pièces jointes"
                          type="file"
                          value={values.attachments.received}
                          field="attachments.received"
                          onChange={setFieldValue}
                        />
                      </Stack>
                    </Stack>
                    <Stack width={1} h={{ xs: 200 }}>
                      <InputLine
                        label="Notes"
                        type="textarea"
                        value={values.notes}
                        sxSheet={{ height: '100%' }}
                        field="notes"
                        onChange={setFieldValue}
                        error={Boolean(touched.notes && errors.notes)}
                        helperText={touched.notes && errors.notes}
                      />
                    </Stack>

                  </Stack>
                </Stack>

                <Box position="absolute" top={0} left={40} bgcolor="#FFF" px={1}>
                  <Typography fontWeight="bold" fontSize={15}>
                    COURRIER
                  </Typography>
                </Box>

                {saving && (
                  <Box
                    position="absolute"
                    height={1}
                    width={1}
                    top={0}
                    bottom={0}
                    left={0}
                    right={0}
                    bgcolor={alpha('#000', 0.5)}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Stack>
                      <CircularProgress />
                      <Typography fontWeight="bold" fontSize={15} color="white">
                        {uploadingsMoyen * 100}%
                      </Typography>
                    </Stack>
                  </Box>
                )}
              </Form>
            </DialogContent>

            <DialogActions
              sx={{ bgcolor: 'background.paper', p: 0, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
            >
              <LoadingButton
                loading={saving}
                size="small"
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
              >
                ENREGISTRER
              </LoadingButton>
            </DialogActions>

            <Stack
              direction="row"
              spacing={1}
              p={0.3}
              alignItems="center"
              sx={{
                position: 'absolute',
                top: '0px',
                right: '5px',
                borderRadius: 1,
                backgroundColor: 'transparent'
              }}
            >
              {saving && (
                <CIconButton
                  noBorder
                  title="Réduire"
                  onClick={handleChangeSize}
                  sx={{ color: '#fff', bgcolor: 'info.main', ':hover': { bgcolor: 'info.main' } }}
                >
                  <Iconify icon="fluent:minimize-12-filled" />
                </CIconButton>
              )}
              <CIconButton
                noBorder
                title="Fermer"
                onClick={onClose}
                sx={{
                  color: '#fff',
                  bgcolor: 'red',
                  ':hover': { bgcolor: 'red' },
                  ':disabled': { bgcolor: 'error.light' }
                }}
              >
                <Iconify icon="eva:close-fill" />
              </CIconButton>
            </Stack>
          </Dialog>
        ) : (
          <MinimizeForm
            desc={`AFF_${formId}`}
            onMaximize={handleChangeSize}
            onClose={onClose}
            constraintsRef={constraintsRef}
            uploadings={uploadingsMoyen}
          />
        )}
      </FormikProvider>
    </>
  );
}

const MinimizeForm = ({ desc, onClose, onMaximize, uploadings }) => {
  const constraintsRef = useRef();

  return ReactDOM.createPortal(
    <Box
      ref={constraintsRef}
      sx={{
        zIndex: (theme) => theme.zIndex.appBar + 1001,
        position: 'absolute',
        bottom: 0,
        right: 50,
        p: 5,
        overflow: 'hidden'
      }}
    >
      <Paper
        component={motion.div}
        dragConstraints={constraintsRef}
        drag
        variant="elevation"
        elevation={10}
        sx={{ color: '#fff', width: '330px' }}
      >
        <Stack
          width={1}
          bgcolor="neutral.main"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
          px={1}
          py={1}
          sx={{
            borderTopRightRadius: 6,
            borderTopLeftRadius: 6
          }}
        >
          <TextMaxLine fontSize={15} fontWeight="bold" line={1}>
            Enregistrement en cours...
          </TextMaxLine>

          <Stack direction="row" spacing={0.5}>
            <Tooltip title="Agrandir" placement="top" arrow>
              <IconButton onClick={onMaximize} sx={{ p: 0 }}>
                <Iconify icon="humbleicons:maximize" sx={{ height: 17, width: 17 }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Fermer" placement="top" arrow>
              <IconButton onClick={onClose} sx={{ p: 0 }}>
                <Iconify icon="ep:close-bold" sx={{ height: 17, width: 17 }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1} width={1} p={1}>
          <Stack>
            <CircularProgress size="sm" variant="soft" color="info" />
            {Boolean(uploadings) && (
              <Typography color="info.main" fontSize={10} fontWeight="bold">
                {uploadings * 100}%
              </Typography>
            )}
          </Stack>
          <TextMaxLine color="#000" fontSize={13} fontWeight="bold" line={1}>
            {desc}
          </TextMaxLine>
        </Stack>
      </Paper>
    </Box>,
    document.body
  );
};
