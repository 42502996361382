import { Icon } from '@iconify/react';
import { Checkbox, CircularProgress } from '@mui/joy';
import { Button, MenuItem, Stack, Tooltip, Box, IconButton } from '@mui/material';
import { isEmpty, uniq } from 'lodash';
import React, { useRef, useState } from 'react';
import { MuiCustomInput } from 'src/components/CustomInput';
import EllipsisText from 'src/components/EllipsisText';
import { CustomMenuPopover } from 'src/components/MenuPopover';
import Scrollbar from 'src/components/Scrollbar';
import useProject from 'src/hooks/useProject';
import useTaskProject from 'src/hooks/useTaskProject';
import { useToggleV2 } from 'src/hooks/useToggle';
import { updateColumn } from 'src/redux/slices/kanban';
import { dispatch, useSelector } from 'src/redux/store';

export default function AddProjectToTask({ task, user, onUpdate, disabled = false }) {
  const { project } = useSelector((state) => state.kanban);
  const [open, onOpen, onClose] = useToggleV2();
  const [openCol, onOpenCol, onCloseCol] = useToggleV2();
  const moreRef = useRef();
  const moreColsRef = useRef();
  const [currentProject, setCurrentProject] = useState(null);
  const [currentColumn, setCurrentColumn] = useState(null);
  const { board, loading } = useTaskProject({ projectId: currentProject?.id || '' });

  React.useEffect(() => {
    if (project?.length) {
      const exist = project?.find((_one) => _one?.id === task?.projectKey);
      setCurrentProject(exist);
    }
  }, [project, task?.projectKey]);

  React.useEffect(() => {
    Object.entries(board.columns).map(([_, col]) => {
      const cardIds = col?.cardIds || [];
      const existIndex = [...cardIds].findIndex((one) => one === task?.id);

      if (existIndex !== -1 && !currentColumn) {
        setCurrentColumn(col);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board, task?.id]);

  const onColumnSelected = (selected) => {
    if (selected) {
      dispatch(updateColumn(selected?.id, { ...selected, cardIds: uniq([...(selected?.cardIds || []), task?.id]) }));
    }

    if (!selected) {
      dispatch(onUpdate(task, { projectKey: null, projectName: '' }));
      if (currentColumn) {
        let cardIds = currentColumn?.cardIds || [];
        cardIds = cardIds.filter((one) => one !== task?.id);
        dispatch(updateColumn(currentColumn?.id, { ...currentColumn, cardIds: uniq([...cardIds]) }));
      }
    }

    setCurrentColumn(selected);
    onCloseCol();
  };

  const onProjectSelected = (selected) => {
    if (!selected) {
      dispatch(onUpdate(task, { projectKey: null, projectName: '' }));
      if (currentColumn) {
        let cardIds = currentColumn?.cardIds || [];
        cardIds = cardIds.filter((one) => one !== task?.id);
        dispatch(updateColumn(currentColumn?.id, { ...currentColumn, cardIds: uniq([...cardIds]) }));
      }
    }

    dispatch(onUpdate(task, { projectKey: selected?.id, projectName: selected?.name }));
    setCurrentProject(selected);
    if (selected?.id !== currentProject?.id) {
      setCurrentColumn(null);
      if (currentColumn) {
        let cardIds = currentColumn?.cardIds || [];
        cardIds = cardIds.filter((one) => one !== task?.id);
        dispatch(updateColumn(currentColumn?.id, { ...currentColumn, cardIds: uniq([...cardIds]) }));
      }
    }

    onClose();
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" height={20}>
      <Stack borderRadius={1} ref={moreRef} component={MenuItem} disableGutters dense onClick={onOpen}>
        {!isEmpty(currentProject?.name) ? (
          <Stack width={80}>
            <EllipsisText material style={{ fontSize: 16, fontWeight: '400' }} text={currentProject?.name || ''} />
          </Stack>
        ) : (
          <Button
            size="small"
            color="inherit"
            sx={{ fontWeight: '200', p: 0.1 }}
            startIcon={<Icon icon="ion:add" />}
            disabled={disabled}
          >
            Ajouter à un projet
          </Button>
        )}
      </Stack>

      {currentProject && currentColumn && <Box width={10} bgcolor="primary.main" height={2} />}

      <Box ref={moreColsRef}>
        {currentProject &&
          (loading ? (
            <CircularProgress size="sm" />
          ) : (
            <Stack component={MenuItem} borderRadius={1} dense onClick={onOpenCol}>
              {!isEmpty(currentColumn?.name) ? (
                <Stack width={70}>
                  <EllipsisText material style={{ fontSize: 16, fontWeight: '400' }} text={currentColumn?.name || ''} />
                </Stack>
              ) : (
                <Tooltip title="Ajouter une opération" arrow placement="top">
                  <IconButton size="small" disabled={disabled}>
                    <Icon icon="ion:add" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          ))}
      </Box>

      {moreRef.current && open && !disabled && (
        <ProjectList
          user={user}
          open={open}
          onClose={onClose}
          anchorEl={moreRef.current}
          currentProject={currentProject}
          onProjectSelected={onProjectSelected}
        />
      )}

      {moreColsRef.current && openCol && !disabled && (
        <ColumnList
          open={openCol}
          onClose={onCloseCol}
          anchorEl={moreColsRef.current}
          currentColumn={currentColumn}
          columns={board.columns}
          columnOrder={board.columnOrder}
          onColumnSelected={onColumnSelected}
        />
      )}
    </Stack>
  );
}

/**
 *
 * @param {{user: firebase.default.UserInfo, open: Boolean, onClose: function, anchorEl:Any, currentProject: Any, onProjectSelected: function(project) }} props
 */
const ProjectList = ({ user, open, onClose, anchorEl, currentProject, onProjectSelected }) => {
  const { projects, loading } = useProject({ id: user.uid, status: ['open'] });

  const projectSelected = (selected) => {
    onProjectSelected(selected);
  };

  return (
    <CustomMenuPopover
      open={Boolean(open)}
      onClose={onClose}
      anchorEl={anchorEl}
      sx={{ height: 300, py: 2 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      disabledArrow
    >
      <Stack height={1}>
        <Stack px={1} mb={2}>
          <MuiCustomInput fullWidth placeholder="rechercher..." size="small" sx={{ p: 0, px: 1, fontSize: 12 }} />
        </Stack>
        <Scrollbar>
          {loading && <CircularProgress />}
          {projects.map((prod, index) => (
            <Stack
              component={MenuItem}
              key={prod?.id + index}
              direction="row"
              alignItems="center"
              spacing={3}
              width={1}
              onClick={() => projectSelected(currentProject?.id === prod?.id ? null : prod)}
            >
              <Stack width={2}>
                <Checkbox checked={currentProject?.id === prod?.id} />
              </Stack>
              <EllipsisText text={prod?.name || ''} style={{ fontSize: 14 }} />
            </Stack>
          ))}
        </Scrollbar>
      </Stack>
    </CustomMenuPopover>
  );
};

const ColumnList = ({ open, onClose, anchorEl, columns, currentColumn, columnOrder, onColumnSelected }) => {
  return (
    <CustomMenuPopover
      anchorEl={anchorEl}
      open={Boolean(open)}
      onClose={onClose}
      sx={{ height: 300, py: 2 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      disabledArrow
    >
      <Stack px={1} mb={2}>
        <MuiCustomInput fullWidth placeholder="rechercher..." size="small" sx={{ p: 0, px: 1, fontSize: 12 }} />
      </Stack>
      {columnOrder.map((colId, index) => {
        const column = columns[colId];
        return (
          <Stack
            component={MenuItem}
            key={colId + index}
            direction="row"
            alignItems="center"
            spacing={3}
            width={1}
            onClick={() => onColumnSelected(colId === currentColumn?.id ? null : column)}
          >
            <Stack width={2}>
              <Checkbox checked={currentColumn?.id === colId} />
            </Stack>
            <EllipsisText text={column?.name || ''} style={{ fontSize: 14 }} />
          </Stack>
        );
      })}
    </CustomMenuPopover>
  );
};
