import { Icon } from '@iconify/react';
import { Button, Stack, useTheme } from '@mui/material';
import React from 'react';
import LabelStyle from 'src/components/LabelStyle';
import { useToggleV2 } from 'src/hooks/useToggle';
import { TaskType } from 'src/models/task_m';
import { SubTaskNewLine, SubTaskView } from '../kanban/KanbanTaskDetailsInfo';

/**
 *
 * @param {{task:TaskType, disabled:boolean}} props
 * @returns
 */
export default function TaskSubTask({ task, disabled = false, rights = null, focusId = null, ...rest }) {
  const [open, onOpen, onClose] = useToggleV2();
  const theme = useTheme();

  //console.log({rights});

  return (
    <Stack width={1} spacing={3}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {/* <LabelStyle sx={{ width: 100 }}>Sous-tâches</LabelStyle> */}
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          startIcon={<Icon icon="eva:plus-fill" />}
          onClick={onOpen}
          disabled={disabled}
        >
          Ajouter une sous-tâche
        </Button>
      </Stack>
      {open && <SubTaskNewLine theme={theme} details={task} exit={onClose} />}
      <Stack>
        <SubTaskView taskId={task?.id} theme={theme} parent={task} disabled={disabled} rights={rights} focusId={focusId} />
      </Stack>
    </Stack>
  );
}
