import React, { useEffect, useMemo } from 'react';
import { Stack, Typography, Divider, IconButton, Paper, Grid, Tooltip } from '@mui/material';
import Iconify from 'src/components/Iconify';
import MailDetailAnswerBox from 'src/section/mail_v2/MailDetailAnswerBox';
import Scrollbar from 'src/components/Scrollbar';
import MailDetailAttachment from 'src/section/mail/MailDetailAttachments';
import { useMailContext } from 'src/section/mail/MailContext';
import { formatHTML } from 'src/utils/toHTML';
import { dispatch, useSelector } from 'src/redux/store';
import MailV2MessageDetailSkeleton from './MailV2MessageDetailSkeleton';
import { getMailByUId } from 'src/redux/slices/customMail';
import { gDate } from 'src/utils/formatTime';

const OpenableEmail = ({ email }) => {
  const { openComposer, handleChangeMailComposerMulti, account } = useMailContext();

  const openComposerWithMessage = (email) => {
    handleChangeMailComposerMulti({
      to: [email] || []
    });
    openComposer();
  };

  return (
    <Typography
      variant="caption"
      sx={{
        ml: 0,
        cursor: 'pointer',
        color: 'text.primary',

        '&:hover': {
          textDecoration: 'none',
          color: 'primary.main'
        }
      }}
      onClick={() => openComposerWithMessage(email)}
    >
      {`${email}`}
    </Typography>
  );
};

const MailProgramedEvent = ({ mail }) => {

  const MiscrosoftMeetingMessageType = {
    meetingRequest: 'Réunion planifiée',
    meetingResponse: 'Réponse à une réunion',
    meetingCanceled: 'Réunion annulée',
    meetingAccepted: 'Réunion acceptée',
    meetingDeclined: 'Réunion refusée',
    meetingTenativelyAccepted: 'Réunion acceptée provisoirement'
  };

  if (!mail?.meetingMessageType) {
    return null
  }
  return (
    <Stack direction="row" spacing={1} my={2}>
      <Grid container flexDirection={'column'} spacing={1} m={2} p={2} sx={{ border: '1px solid #e0e0e0', borderRadius: '5px' }}>

        <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} spacing={1}>
          <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Iconify icon="mdi:schedule" mr={1} />
          </Grid>
          <Grid item>
            <Typography variant="caption" sx={{ color: 'text.primary' }}>
              {mail?.subjet} -  {MiscrosoftMeetingMessageType[mail?.meetingMessageType] || mail?.meetingMessageType}
            </Typography>
          </Grid>
          <Tooltip title="Vous avez une réunion planifiée attachée à ce mail " arrow placement="top">
            <IconButton size="small" ml={2}>
              <Iconify icon="mdi:information-outline" sx={{ fontSize: '0.75rem' }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} spacing={1}>
          <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Iconify icon="mdi:calendar-clock" mr={1} />
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {gDate(mail?.startDateTime?.dateTime).toLocaleDateString()} &nbsp;
            </Typography>
            <Typography variant="caption">
              {gDate(mail?.startDateTime?.dateTime).toLocaleTimeString()}
              -
              {gDate(mail?.endDateTime?.dateTime).toLocaleTimeString()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};



const MailV2MessageDetail = ({ message, onReset = null, isModal = false, onCloseModal = null, modalRef = null }) => {
  const { openComposer, handleChangeMailComposerMulti, account } = useMailContext();
  const { folder, mail_UID } = message;
  const { mailByUID } = useSelector((state) => state.customMail);

  const mailContent = useMemo(() => {
    if (message?.account?.platform === 'custom') {
      if (mailByUID[folder] && mailByUID[folder][mail_UID]) {
        return mailByUID[folder][mail_UID];
      }

      return { loading: true, mail: null };
    }
    return { loading: false, mail: message };
  }, [message, mailByUID, folder, mail_UID]);

  const openComposerWithMessage = (message) => {
    handleChangeMailComposerMulti({
      to: [message?.from?.email] || []
    });
    openComposer();
  };

  useEffect(() => {
    if (message?.account?.platform === 'custom') {
      const _content = mailByUID[folder] && mailByUID[folder][mail_UID];
      if (!_content?.mail) {
        dispatch(
          getMailByUId({ account: message?.account, folderType: message?.folder, messageUID: message?.mail_UID })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder, message]);

  return mailContent?.loading ? (
    <MailV2MessageDetailSkeleton />
  ) : (
    <Paper sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Stack
        sx={{ p: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}
      >
        <Stack sx={{ p: 0, display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>
          <Stack direction="column" spacing={0}>
            <Typography
              variant="subtitle2"
              sx={{
                ml: 0,
                cursor: 'pointer',
                color: 'text.primary',

                '&:hover': {
                  textDecoration: 'none',
                  color: 'primary.main'
                }
              }}
              onClick={() => openComposerWithMessage(mailContent)}
            >
              {mailContent?.mail?.from?.email === mailContent?.mail?.account?.email
                ? 'Moi'
                : mailContent?.mail?.from.email}
            </Typography>
            {mailContent?.mail?.to?.length !== 0 ? (
              <Stack direction="row" idth={'100'} spacing={1} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Typography variant="caption" >
                  {`À: `}
                </Typography>
                <Stack width={1} direction={'row'} spacing={1} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} flexWrap={'wrap'} >
                  {mailContent?.mail?.to?.map((to, idx) => (
                    <OpenableEmail key={to?.email + idx} email={to.email} />
                  ))}
                </Stack>
              </Stack>
            ) : null}

            {mailContent?.mail?.cc?.length !== 0 ? (
              <Stack width={1} direction={'row'} spacing={1} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} flexWrap={'wrap'} >
                <Typography variant="caption">{`Cc: `}</Typography>
                {mailContent?.mail?.cc?.map((cc, idx) => (
                  <OpenableEmail key={cc?.email + idx} email={cc?.email} />
                ))}
              </Stack>
            ) : null}
            {mailContent?.mail?.cci?.length !== 0 &&
              (mailContent?.mail?.to?.email === mailContent?.mail?.account?.email ||
                mailContent?.mail?.cci?.some((cci) => cci?.email === mailContent?.mail?.account?.email)) ? (
              <Stack width={1} direction={'row'} spacing={1} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} flexWrap={'wrap'} >
                <Typography variant="caption">{`Cci: `}</Typography>
                <OpenableEmail email={mailContent?.mail?.account?.email} />
              </Stack>
            ) : null}
            <Typography variant="caption">
              {(!mailContent?.mail?.date && !mailContent?.mail?.sentDate) ||
                isNaN(new Date(mailContent?.mail?.date || mailContent?.mail?.sentDate).getTime())
                ? ''
                : `Le ${new Date(mailContent?.mail?.date || mailContent?.mail?.sentDate).toLocaleString('fr-FR', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                })}`}
            </Typography>
          </Stack>
        </Stack>
        {onReset && (
          <Iconcaption
            sx={{
              position: 'relative',
              top: '10px',
              left: '10px',
              zIndex: 1,
              width: '40px',
              height: '40px'
            }}
            onClick={onReset}
          >
            <Iconify icon="eva:close-fill" />
          </Iconcaption>
        )}
      </Stack>
      <Divider sx={{ width: '100%' }} />
      <Stack sx={{ p: 3 }} height={'60vh'} overflow={'auto'} flexGrow={1}>
        <Typography variant="h5">{mailContent?.mail?.subject}</Typography>
        <MailProgramedEvent mail={mailContent?.mail} />
        <Typography
          sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
          fontSize={14}
          dangerouslySetInnerHTML={{ __html: formatHTML(mailContent?.mail?.body) }}
        />

        <MailDetailAttachment mail={mailContent?.mail} />
      </Stack>
      <Stack flexGrow={1} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Scrollbar sx={{ flexGrow: 1 }}>
          <MailDetailAnswerBox
            message={mailContent?.mail}
            isModal={isModal}
            onCloseModal={onCloseModal}
            modalRef={modalRef}
          />
        </Scrollbar>
      </Stack>
    </Paper>
  );
};

export default MailV2MessageDetail;
