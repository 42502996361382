import React from 'react';
import { Dialog, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import MinimizeIcon from '@mui/icons-material/Minimize';
import { IOnlyOfficeDoc } from 'src/models/IOnlyOfficeDoc';
import CIconButton from '../CIconButton';
import Iconify from '../Iconify';
import { PATH_PAGE } from 'src/routes/paths';
import DocumentOnlineEdit from './DocumentOnlineEdit';
import { CircularProgress } from '@mui/joy';

/**
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {string} props.uploadName
 * @param {string} props.docKey
 * @param {boolean} props.loading
 * @param {string} [props.parentId]
 * @param {string} [props.module]
 * @param {IOnlyOfficeDoc} props.document
 * @param {'edit' | 'view'} props.openMode
 * @param {function} props.onClose
 * @param {function} props.onUpdate
 * @param {boolean} props.editable
 */
export default function DocumentReader({
  document,
  uploadName,
  openMode = 'view',
  onUpdate,
  parentId = null,
  module = null,
  docKey,
  onClose,
  open,
  loading,
  editable = false
}) {
  const [fullscreen, setFullScreen] = React.useState(false);

  const toogleScreen = () => {
    setFullScreen(!fullscreen);
  };

  const onHandleEditDocument = () => {
    const state = {
      document,
      docKey,
      uploadName,
      openMode: 'edit',
      onUpdate,
      parentId,
      module
    };

    window.open(`${PATH_PAGE.edit_document}/${document.key}/${docKey}`, '_blank', 'noopener noreferrer');
    window.localStorage.setItem(document.key, JSON.stringify(state));
    onClose();
  };

  return (
    <Dialog
      fullWidth
      fullScreen={fullscreen}
      {...(!fullscreen && { maxWidth: 'md' })}
      open={open}
      onClose={onClose}
      sx={{
        zIndex: 9990,
        '& .MuiPaper-rounded': {
          borderRadius: 0,
          bgcolor: 'transparent'
        }
      }}
      PaperProps={{
        sx: {
          border: 'none',
          boxShadow: 'none',
          bgcolor: 'transparent',
          height: fullscreen ? '100vh' : '90vh'
        }
      }}
    >
      <Stack
        sx={{
          p: 1,
          position: 'relative',
          bgcolor: 'transparent',
          height: fullscreen ? '100vh' : '90vh'
        }}
      >
        {loading ? (
          <ChargingView />
        ) : (
          <DocumentOnlineEdit params={{ document, openMode, uploadName, module, parentId, docKey }} />
        )}
      </Stack>

      <Stack
        p={0.3}
        spacing={1}
        direction="row"
        alignItems="center"
        width={110}
        sx={{
          top: 2,
          left: 5,
          borderRadius: 0.5,
          position: 'absolute',
          backgroundColor: 'background.paper'
        }}
      >
        <CIconButton noBorder title="Fermer" size="small" onClick={onClose} sx={{ p: 0.2 }}>
          <CloseIcon fontSize="small" />
        </CIconButton>

        <CIconButton
          noBorder
          title={fullscreen ? 'Réduire' : 'Agrandir'}
          size="small"
          onClick={toogleScreen}
          sx={{ p: 0.2 }}
        >
          {!fullscreen ? <FullscreenIcon fontSize="small" /> : <MinimizeIcon fontSize="small" />}
        </CIconButton>

        <CIconButton
          onClick={onHandleEditDocument}
          noBorder
          title="Editer"
          size="small"
          sx={{ p: 0.2, ml: 1 }}
          disabled={!editable}
        >
          <Iconify icon="fluent:edit-32-filled" />
        </CIconButton>
      </Stack>
    </Dialog>
  );
}

export const ChargingView = () => {
  return (
    <Stack width={1} height="90vh" alignItems="center" justifyContent="center">
      <Stack bgcolor={(t) => t.palette.grey[700]} px={5} py={2} direction="row" spacing={1} borderRadius={1}>
        <CircularProgress size="sm" />
        <Typography fontSize={13} color="common.white">
          Chargement en cours...
        </Typography>
      </Stack>
    </Stack>
  );
};
