import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  colors
} from '@mui/material';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { format } from 'date-fns';
import { fr as LocalFr } from 'date-fns/locale';
import Iconify from 'src/components/Iconify';
import { TASK_STATE_VALIDATION, TASK_VISIBILITY } from 'src/constants';
import { useWorldTime } from 'src/contexts/WorldTimeContext';
import { dateConverter } from 'src/helpers/dueDateConverter';
import CIconButton from 'src/components/CIconButton';
import DateTineRangeSelector from 'src/components/DateTineRangeSelector';
import { dispatch } from 'src/redux/store';
import { updateTaskWithoutProject } from 'src/redux/slices/task';
import { useTaskV4Context } from 'src/contexts/TaskContext';
import { Avatar } from 'antd';
import createAvatar from 'src/utils/createAvatar';
import useToggle, { useToggleV2 } from 'src/hooks/useToggle';
import { useDatePicker } from 'src/section/tasks/kanban/KanbanTaskAdd';
import ContactsDialog from 'src/components/ContactsDialog';
import PriorityFlags from 'src/section/tasks/PriorityFlags';
import { priorityColor } from 'src/helpers/priorityColor';
import { isEmpty, isEqual } from 'lodash';
import V4ProjectContentTaskComponent from './V4ProjectContentTaskComponent';
import { MuiCustomInput } from 'src/components/CustomInput';
import { baseColors } from 'src/constants/color';
import { handleKeyDown } from 'src/helpers/lockEnterShiftKeyDown';
import { useV4ProjectContext } from '..';
import { Draggable } from 'react-beautiful-dnd';
import { TaskType } from 'src/models/task_m';
import { addTask } from 'src/redux/slices/kanban';
import { nanoid } from '@reduxjs/toolkit';
import TextMaxLine from 'src/components/TextMaxLine';
import { useProjectContext } from 'src/contexts/ProjectContext';
import { getProjectById } from 'src/utils/project';
import V4TaskJointe from 'src/section/tasks/v4/V4TaskJointe';
import useAuth from 'src/hooks/useAuth';
import { gDate } from 'src/utils/formatTime';
import { useOneProject } from './../../../../../../hooks/useProject';
import useSubTask from 'src/hooks/useSubTask';
import { serverTime } from 'src/utils/serverTime';
import OkyCalendar from 'src/components/calendar/OkyCalendar';

export default function V4ProjectContentListTask({ column }) {
  const { board } = useV4ProjectContext();

  return (
    <Stack width={1} borderTop="1px solid" borderColor={(t) => t.palette.divider}>
      {column?.cardIds?.map((cardIds, index) => {
        const card = board.cards[cardIds];
        if (card) {
          return <V4TaskLine key={card?.id + index} task={card} index={index} />;
        }
        return;
      })}
    </Stack>
  );
}

export const Visibility = ({ task, hover = false, hideTitle = false, hide = false, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPrivate, setIsPrivate] = useState(task?.visibility === TASK_VISIBILITY.PRIVATE);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleVisibility = () => {
    const newVisibility = isPrivate ? TASK_VISIBILITY.PUBLIC : TASK_VISIBILITY.PRIVATE;
    setIsPrivate(!isPrivate);
    // Mettre à jour la visibilité de la tâche dans votre état global ou Redux
    dispatch(updateTaskWithoutProject(task, { visibility: newVisibility }, () => { }));
    handleClose(); // Fermer le menu déroulant après le changement de visibilité
  };
  if (!hover || hide) return null;
  return (
    <>
      <Stack direction="row" alignItems="center" ref={anchorEl}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <CIconButton
            size="small"
            noBorder
            onClick={disabled ? null : handleClick}
            title={isPrivate ? 'Privé' : 'Public'}
            disabled={disabled}
          >
            <Icon
              icon={isPrivate ? 'eva:lock-fill' : 'eva:lock-outline'}
              height={13}
              width={13}
              color={isPrivate ? baseColors.GREY : baseColors.GREEN}
            />
          </CIconButton>
          {!hideTitle && (
            <Typography variant="overline" fontSize={9}>
              {isPrivate ? 'Privé' : 'Public'}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={disabled ? null : handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={disabled ? null : toggleVisibility}>
          <Stack direction="row" alignItems="center">
            <Iconify icon="eva:lock-outline" height={13} width={13} />
            <Typography variant="body2" sx={{ ml: 1 }}>
              {isPrivate ? 'Rendre public' : 'Rendre privé'}
            </Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};

export const useCompletedAllSubTasksByTaskId = (taskId) => {
  const { loading, subTasks } = useSubTask({ taskId });
  const { deleteTask, updateTask } = useProjectContext();
  const auth = useAuth();

  return () => {
    //TODO : to disabled features comment this 
    subTasks.forEach((subTask) => {
      const user = auth.currentUser;
      updateTask(
        {
          ...subTask,
          completed: true,
          state: TASK_STATE_VALIDATION.DONE,
          updatedAt: serverTime(),
          lastUpdatedBy: {
            id: user?.id || null,
            name: user?.displayName || '',
            email: user?.email || '',
            avatar: user?.photoURL || null
          }
        },
        subTask,
        false
      );
    });
  }
}

const V4TaskLine = ({ task, index }) => {
  const [completed, setCompleted] = useState(task?.completed);
  const { openTaskDetails } = useTaskV4Context();
  const [__hover, onHover, endHover] = useToggleV2();
  const [edit, setEdit] = useState(false);
  const { board, projectId, taskRights } = useV4ProjectContext();
  const { project } = useProjectData(projectId);
  const { user } = useAuth();
  const completedAllSubTasksByTaskId = useCompletedAllSubTasksByTaskId(task.id);

  const canEdit = taskRights?.edit;
  const canDelete = taskRights?.delete;
  const canCreate = taskRights?.create;

  const hover = __hover;

  useEffect(() => setCompleted(task?.completed), [task?.completed]);

  const handleCompleted = () => {
    setCompleted(!completed);
    //console.log({task});
    dispatch(updateTaskWithoutProject(task, { completed: !completed }, () => {
      completedAllSubTasksByTaskId();
    }));
  };

  const onClick = () => {
    openTaskDetails(task, taskRights?.edit, taskRights?.delete, taskRights?.create);
  };

  //const currentuser = getCurrentUserAccess();

  return (
    <>
      <Draggable draggableId={task?.id} index={index} isDragDisabled={!canEdit}>
        {(provided, snap) => (
          <Stack
            width={1}
            {...provided.draggableProps}
            ref={provided.innerRef}
            direction="row"
            onMouseEnter={onHover}
            onMouseLeave={endHover}
          >
            <Box pt={0.4} width={27} {...provided.dragHandleProps}>
              {(hover || snap.isDragging) && (
                <IconButton size="small">
                  <Icon icon="radix-icons:drag-handle-dots-2" height={15} width={15} />
                </IconButton>
              )}
            </Box>
            <Stack width={1}>
              <V4ProjectContentTaskComponent
                name={
                  <Stack width={1} position="relative">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButton size="small" sx={{ p: 0.2 }} onClick={handleCompleted} disabled={!taskRights?.edit}>
                        <Iconify
                          icon={!completed ? 'mingcute:check-circle-line' : 'ph:check-circle-fill'}
                          color={completed ? 'green' : 'inherit'}
                          sx={{ height: 20, width: 20 }}
                        />
                      </IconButton>

                      <Stack width={1} sx={{ cursor: 'pointer', position: 'relative' }}>
                        <Box height={30} position="absolute" top={-14} left={0} onClick={onClick} />
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                          width={0.9}
                          position="absolute"
                          top={-14}
                          height={30}
                        >
                          <Name
                            task={task}
                            hover={hover}
                            edit={edit}
                            setEdit={canEdit ? setEdit : undefined}
                            disabled={!canEdit}
                          />
                          {!edit && <V4TaskJointe task={task} handleClick={onClick} />}
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack position="absolute" right={0} top={-5} height={30} px={1}>
                      {!edit && hover && (
                        <CIconButton title="Ouvrir" size="small" color="inherit" onClick={onClick}>
                          <Icon icon="ic:round-keyboard-arrow-right" />
                        </CIconButton>
                      )}
                    </Stack>
                  </Stack>
                }
                intervenant={<Assigne task={task} hover={hover} disabled={!canEdit} />}
                priority={<Priority task={task} hover={hover} disabled={!canEdit} />}
                due={<DateDue task={task} hover={hover} disabled={!canEdit} />}
                visibility={
                  <Visibility
                    task={task}
                    hover={hover || task?.visibility === TASK_VISIBILITY.PRIVATE}
                    disabled={!canEdit}
                  />
                }
              />
            </Stack>
          </Stack>
        )}
      </Draggable>
    </>
  );
};

export const V4TaskLineWithoutDrag = ({ task, index }) => {

  //console.log({ task });

  const completed = task?.completed || task?.metadata?.done?.doneDate;
  return (
    <Stack width={1} key={task?.id} direction="row" spacing={0} sx={{ height: 40 }}>
      <Stack width={1}>
        <V4ProjectContentTaskComponent
          name={
            <Stack width={1} position="relative">
              <Stack direction="row" spacing={1} alignItems="center">
                <IconButton size="small" sx={{ p: 0.2 }}>
                  <Iconify
                    icon={!completed ? 'mingcute:check-circle-line' : 'ph:check-circle-fill'}
                    color={completed ? 'green' : 'inherit'}
                    sx={{ height: 20, width: 20 }}
                  />
                </IconButton>
                <Stack width={1} sx={{ cursor: 'pointer', position: 'relative' }}>
                  <Box height={30} position="absolute" top={-14} left={0} />
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={0.5}
                    width={0.9}
                    position="absolute"
                    top={-14}
                    height={30}
                  >
                    <Name task={task} />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          }
          intervenant={<Assigne task={task} disabled />}
          priority={<Priority task={task} disabled />}
          due={<DateDue task={task} disabled />}
          visibility={<Visibility task={task} disabled />}
        />
      </Stack>
    </Stack>
  );
};

export const Name = ({ task, edit, setEdit, disabled }) => {
  const [name, setName] = useState(task?.name);

  const [_hover, onHover, endHover] = useToggleV2();

  useEffect(() => setName(task?.name), [task?.name]);

  const handleChange = (e) => {
    setName(e.currentTarget.value);
  };

  const handleCloseAndUpdate = () => {
    setEdit(false);
    endHover();
    dispatch(updateTaskWithoutProject(task, { name }));
  };

  const handleKeyUp = (e) => {
    if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) {
      handleCloseAndUpdate();
    }
  };

  const handleEdit = () => {
    setEdit(true);
  };

  return !edit ? (
    <Stack onMouseEnter={onHover} onMouseLeave={endHover} onClick={handleEdit}>
      <TextMaxLine
        line={1}
        variant="caption"
        sx={{
          px: 1,
          py: 0.4,
          ...(_hover && !disabled && { border: `0.5px solid ${baseColors.GREY}`, borderRadius: 0.5 })
        }}
      >
        {name}
      </TextMaxLine>
    </Stack>
  ) : (
    <Stack width={1}>
      <ClickAwayListener onClickAway={handleCloseAndUpdate}>
        <MuiCustomInput
          fullWidth
          disabled={disabled}
          value={name}
          autoFocus
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          sx={{
            p: 0,
            pl: 1,
            fontSize: 12,
            border: 'none',
            '&.MuiInputBase-root.Mui-focused': {
              outline: 'none'
            }
          }}
        />
      </ClickAwayListener>
    </Stack>
  );
};

const Assigne = ({ task, hover = false, handleChange, isNew = false, disabled }) => {
  const [assigne, setAssigne] = useState(task?.assignee || []);
  const { handleOpenAddToProjectConfirmation, currentProject } = useProjectContext();
  useEffect(() => setAssigne(task?.assignee || []), [task]);

  const onAssigne = React.useCallback(
    (_assigne) => {
      if (isEqual(_assigne, assigne)) return;
      if (disabled) return; // Ajouté pour désactiver les modifications

      const isNotInTheProject = [];
      _assigne.forEach((_user) => {
        if (!currentProject?.canAccessId.find((_id) => _id === _user.id)) {
          isNotInTheProject.push({ ..._user });
        }
      });

      if (isNotInTheProject.length) {
        handleOpenAddToProjectConfirmation({
          task,
          usersToAdd: isNotInTheProject,
          values: { assignee: _assigne },
          callback: () => {
            if (isNew && handleChange) {
              handleChange(_assigne);
              return;
            }

            dispatch(updateTaskWithoutProject(task, { assignee: _assigne }));
          }
        });
        return;
      }

      //console.log('onAssigne', _assigne);

      setAssigne(_assigne);
      dispatch(updateTaskWithoutProject(task, { assignee: _assigne }));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [task?.id, disabled, assigne]
  );
  return (
    <Stack>
      <Stack direction="row" alignItems="center" sx={{ cursor: 'pointer' }}>
        <ContactsDialog
          action={(popoverRef, onOpen) =>
            !!assigne?.length ? (
              <Stack ref={popoverRef} onClick={disabled ? null : onOpen} alignItems="center" width={1}>
                <Avatar.Group maxPopoverPlacement="top" size="small" maxCount={assigne?.length > 2 ? 1 : 2}>
                  {assigne?.map((part) => (
                    <Tooltip key={part?.id} title={part?.name} arrow>
                      <Avatar
                        size="small"
                        srcSet={part?.avatar}
                        src={part?.avatar}
                        style={{ backgroundColor: createAvatar(part?.name).color2 }}
                      >
                        {createAvatar(part?.name).name}
                      </Avatar>
                    </Tooltip>
                  ))}
                </Avatar.Group>
              </Stack>
            ) : (
              <Stack ref={popoverRef}>
                {hover && (
                  <CIconButton noBorder title="Ajouter des intervenants" onClick={disabled ? null : onOpen}>
                    <Icon icon="fluent:person-add-20-regular" height={13} width={13} />
                  </CIconButton>
                )}
              </Stack>
            )
          }
          assigne={assigne}
          onAssigne={onAssigne}
        />
      </Stack>
    </Stack>
  );
};

const useProjectData = (projectId) => {
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getProjectById(projectId)
      .then((res) => {
        setProject(res);
        setLoading(false);
      })
      .catch((err) => {
        //console.log('err', err);
        setLoading(false);
      });
  }, [projectId]);

  return { project, loading };
};

export const getDueDateColor = (dueDate, now) => {
  //const diffDays = differenceInDays(dueDate, now);
  const dueDateIsPassed = dueDate < now;
  //console.log({ diffDays, dueDate, now, dueDateIsPassed });
  if (dueDateIsPassed) return colors.red[500];
  return;
};
const differenceInDays = (date1, date2) => {
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const DateDue = ({ task, hover = false, handleDueChange, isNew = false, disabled: _disabled }) => {
  const anchorEl = useRef();
  const { now } = useWorldTime();
  const { open, handleOpen, handleClose } = useToggle();
  const [_hover, onHover, endHover] = useToggleV2();
  const { projectId, loading: loadingProject, taskRights } = useV4ProjectContext();
  const { project, loading: loadingProjectData } = useOneProject({ id: projectId });
  const auth = useAuth();

  //isAllowed uniquement au manager et createur du projet
  const isAllowed = useMemo(() => {
    if (auth?.user?.id === project?.createdBy?.id) return true;
    if (project?.managers?.map((user) => user?.id).includes(auth?.user?.id)) return true;
    return false;
  }, [auth, project]);
  const disabled = !taskRights?.edit && !isAllowed;

  //console.log({ isAllowed, disabled ,_disabled});
  //const dataInterval = project?.dataInterval;

  const { startTime, endTime, onChangeDueDate } = useDatePicker({
    date: dateConverter(task?.due)
  });

  const handleUpdate = (values) => {
    //console.log({ values });
    if (isNew && handleDueChange) {
      handleDueChange(values);
      return;
    }
    dispatch(updateTaskWithoutProject(task, { due: values }));
  };

  const onCloseDate = (val) => {
    //onChangeDueDate(val);
    handleClose();
    //handleUpdate(val);
  };

  const handleChange = (val) => {
    onChangeDueDate(val);
    handleUpdate(val);
  };

  const onRemove = () => {
    handleClose();
    onChangeDueDate([null, null]);
    handleUpdate([null, null]);
  };

  return (
    <>
      <Stack sx={{ cursor: disabled ? 'default' : 'pointer' }} ref={anchorEl}>
        {endTime ? (
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="left"
            pt={0.5}
            onMouseEnter={disabled ? null : onHover}
            onMouseLeave={disabled ? null : endHover}
          >
            <Typography
              onClick={disabled ? null : handleOpen}
              color={task.completed ? 'text.disabled' : getDueDateColor(new Date(endTime), now)}
              //getDueDateColor(new Date(endTime), now)}
              fontSize={11}
              fontWeight="600"
              sx={{
                ':hover': {
                  textDecoration: disabled ? 'none' : 'underline'
                }
              }}
            >
              {format(gDate(endTime) || new Date(), 'dd/MM/yy', { locale: LocalFr })}
            </Typography>
            {_hover && (
              <IconButton size="small" sx={{ p: 0.1 }} onClick={disabled ? null : onRemove}>
                <Icon height={13} width={13} icon="eva:close-fill" color="red" />
              </IconButton>
            )}
          </Stack>
        ) : (
          <Stack direction="row" width={1} onClick={disabled ? null : handleOpen}>
            {(_hover || hover) && (
              <CIconButton size="small" noBorder title="Ajouter une échéance">
                <Icon icon="ph:calendar-blank-light" height={13} width={13} />
              </CIconButton>
            )}
          </Stack>
        )}
      </Stack>

      {open && (
        <OkyCalendar
          dates={task?.due}
          open={open}
          onClose={onCloseDate}
          onChange={handleChange}
          anchorEl={anchorEl.current}
          startTime={startTime}
          endTime={endTime}
          disabled={disabled}
        />
      )}
    </>
  );
};

export const Priority = ({ task, hover = false, handlePriorityChange, isNew = false, disabled, hideTitle = false }) => {
  const anchorEl = useRef();
  const { open, handleOpen, handleClose } = useToggle();
  const [priority, setPriority] = useState(task?.priority);

  useEffect(() => setPriority(task?.priority), [task?.priority]);

  const handleUpdate = (val) => {
    setPriority(val);
    if (isNew && handlePriorityChange) {
      handlePriorityChange(val);
      return;
    }
    dispatch(updateTaskWithoutProject(task, { priority: val }));
    // save firestore
  };

  const handleChangePriority = (val) => {
    handleUpdate(val);
    handleClose();
  };

  return (
    <Stack direction="row" alignItems="center" ref={anchorEl}>
      {(!isEmpty(priority) || hover) && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <CIconButton
            size="small"
            noBorder
            onClick={handleOpen}
            title={priority || 'Ajouter une priorité'}
            disabled={disabled}
          >
            <Icon
              icon={isEmpty(priority) ? 'eva:flag-outline' : 'eva:flag-fill'}
              height={13}
              width={13}
              color={priorityColor(priority)}
            />
          </CIconButton>
          {!hideTitle && (
            <Typography variant="overline" fontSize={9}>
              {priority}
            </Typography>
          )}
        </Stack>
      )}
      <PriorityFlags
        value={priority}
        onChange={handleChangePriority}
        open={open}
        onClose={handleClose}
        anchorRef={anchorEl}
      />
    </Stack>
  );
};

export const V4TaskLineNew = ({ operation, endCreate }) => {
  const [task, setTask] = useState(TaskType);

  const handleNameChange = (e) => {
    setTask((prev) => ({ ...prev, name: e.target.value }));
  };

  const handleAssigneChange = (assignee) => {
    setTask((prev) => ({ ...prev, assignee }));
  };

  const handleDateDueChange = (due) => {
    setTask((prev) => ({ ...prev, due }));
  };

  const handlePriorityChange = (priority) => {
    setTask((prev) => ({ ...prev, priority }));
  };

  const handleCreateNewTask = () => {
    const onReset = () => {
      endCreate();
    };
    dispatch(addTask({ card: { ...task, id: nanoid() }, columnId: operation?.id }, onReset, onReset));
  };

  let _counter = 0;
  const handleCloseEndCheckValidate = () => {
    if (!isEmpty(task.name)) {
      if (_counter < 1) {
        handleCreateNewTask();
        endCreate();
        counter += 1;
        return;
      }
    }
    endCreate();
  };

  let counter = 0;
  const handleKeyUp = (e) => {
    if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) {
      if (counter < 1) {
        handleCreateNewTask();
        counter += 1;
        return;
      }
    }
  };

  return (
    <ClickAwayListener onClickAway={handleCloseEndCheckValidate}>
      <Stack pl={3}>
        <V4ProjectContentTaskComponent
          name={
            <MuiCustomInput
              fullWidth
              value={task?.name || ''}
              placeholder="le titre de la tâche ..."
              multiline
              autoFocus
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              onChange={handleNameChange}
              sx={{
                p: 0,
                pl: 1,
                fontSize: 14,
                border: 'none',
                '&.MuiInputBase-root.Mui-focused': {
                  outline: 'none'
                }
              }}
            />
          }
          intervenant={<Assigne task={task} handleChange={handleAssigneChange} hover isNew />}
          priority={<Priority task={task} handlePriorityChange={handlePriorityChange} hover isNew />}
          due={<DateDue task={task} handleDueChange={handleDateDueChange} hover isNew />}
        />
      </Stack>
    </ClickAwayListener>
  );
};
