import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { COL_WIDTH } from 'src/constants/task';

export default function V4ProjectContentTaskComponent({
  name,
  intervenant,
  priority,
  due,
  header = false,
  visibility,
  customSize = COL_WIDTH
}) {
  return (
    <Stack
      width={1}
      direction="row"
      alignItems="center"
      px={0.5}
      sx={{ borderBottom: '1px solid', borderColor: (t) => `${t.palette.divider}` }}
      divider={<Box height={30} width={1.01} bgcolor={(t) => t.palette.divider} mr={1} />}
    >
      {name && (
        <Stack width={1}>
          {header ? (
            <Typography variant="body1" fontSize={13} fontWeight="bold">
              {name}
            </Typography>
          ) : (
            name
          )}
        </Stack>
      )}

      {intervenant && (
        <Stack minWidth={customSize} alignItems="left">
          {header ? (
            <Typography variant="body1" fontSize={13} fontWeight="bold">
              {intervenant}
            </Typography>
          ) : (
            intervenant
          )}
        </Stack>
      )}

      {visibility && (
        <Stack minWidth={customSize} alignItems="lfet">
          {header ? (
            <Typography variant="body1" fontSize={13} fontWeight="bold">
              Visibilité
            </Typography>
          ) : (
            visibility
          )}
        </Stack>
      )}

      {priority && (
        <Stack minWidth={customSize} alignItems="lfet">
          {header ? (
            <Typography variant="body1" fontSize={13} fontWeight="bold">
              {priority}
            </Typography>
          ) : (
            priority
          )}
        </Stack>
      )}

      {due && (
        <Stack minWidth={customSize} alignItems="lfet">
          {header ? (
            <Typography variant="body1" fontSize={13} fontWeight="bold">
              {due}
            </Typography>
          ) : (
            due
          )}
        </Stack>
      )}
    </Stack>
  );
}
