import React, { createContext, useContext, useMemo, useState } from 'react';
import { TaskType } from 'src/models/task_m';
import { getTaskById } from 'src/redux/slices/kanban';
import { dispatch } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import TaskItemDetail from 'src/section/tasks/v4/TaskItemDetail';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import TaskLoadingSreen from 'src/components/TaskLoadingScreen';

const TaskContextProvider = createContext({
  /**
   * @param {TaskType} task
   */
  openTaskDetails: (task, canEdit = null, canDelete = null, canCreate = null, openAttachements = null) => { },
  closeTaskDetails: () => { },
  openV4TaskById: (taskId, create = null, edit = null, del = null) => { },
  taskItemloading: false,
  setTaskItemLoading: (loading) => { }
});

export const useTaskV4Context = () => useContext(TaskContextProvider);

export default function TaskProvider({ children }) {
  //TODO disable input when user right is not enough
  const [open, setOpen] = useState(false);
  const [task, setTasks] = useState(null);
  const [openAttachements, setOpenAttachements] = useState(false);
  const [taskItemloading, setTaskItemLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const openTaskDetails = (_task, canEdit = null, canDelete = null, canCreate = null, openAttachements = null) => {
    setOpen(true);
    setTasks(_task);
    openAttachements && setOpenAttachements(openAttachements);

  };

  const openV4TaskById = (taskId, create = null, edit = null, del = null) => {
    //console.log({ taskId, create, edit, del });
    setTaskItemLoading(true);
    dispatch(
      getTaskById({
        taskId,
        resolve: (task) => {
          const projectKey = task?.projectKey || task?.idProject;
          if (projectKey)
            navigate(`${PATH_DASHBOARD.general.tasks}/${projectKey}`, {
              state: {
                projectName: ' ',
                detailId: taskId
              }
            });

          openTaskDetails(task, edit, del, create);
          setTaskItemLoading(false);
        },
        reject: () => {
          enqueueSnackbar(`Cette tâche n'existe plus`, { variant: 'warning' })
          setTaskItemLoading(false);
        }
      })
    );
  };

  const closeTaskDetails = () => {
    setOpen(false);
    setTasks(null);
  };

  const store = { openTaskDetails, closeTaskDetails, openV4TaskById, taskItemloading, setTaskItemLoading };

  return (
    <TaskContextProvider.Provider value={store}>
      {children}
      {open && (
        <TaskItemDetail
          task={task}
          open={open}
          onClose={closeTaskDetails}
          openAttachements={openAttachements}
          setOpenAttachements={setOpenAttachements}
        />
      )}
      {taskItemloading && <TaskLoadingSreen open={taskItemloading} />}
    </TaskContextProvider.Provider>
  );
}
