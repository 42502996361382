import { CircularProgress, Sheet, Tooltip } from '@mui/joy';
import { Box, Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import TextMaxLine from 'src/components/TextMaxLine';
import useToggle from 'src/hooks/useToggle';

/**
 *
 * @param {{
 * uploadings: Array<{name:string, progress: number, error: any, size};
 * onClose: ()=>{},
 * }} props
 */
export default function UploadingFileView({ uploadings, onClose }) {
  const { open, handleSwitch } = useToggle(true);
  const container = document.getElementById('oky_driver');

  return ReactDOM.createPortal(
    <Box
      sx={{
        zIndex: (t) => t.zIndex.appBar + 1500,
        position: 'absolute',
        bottom: 0,
        right: 40,
        p: 3,
        overflow: 'hidden',
        display: container ? 'initial' : 'none'
      }}
    >
      <Paper variant="elevation" elevation={2} sx={{ width: 400 }}>
        <Stack>
          <Sheet variant="soft" color="neutral" sx={{ borderRadius: 'sm' }}>
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" p={1}>
              <TextMaxLine fontSize={13} fontWeight="bold" noTooltip>
                Téléchargement de {uploadings.length} document{uploadings?.length > 1 ? 's' : ''}
              </TextMaxLine>
              <Stack direction="row" spacing={1}>
                <Tooltip title={!open ? 'Ouvrir' : 'Reduire'} placement="top">
                  <IconButton size="small" onClick={handleSwitch}>
                    <Iconify icon="eva:arrow-ios-downward-fill" rotate={open ? 0 : 2} />
                  </IconButton>
                </Tooltip>
                <IconButton size="small" title="Fermer" onClick={onClose}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              </Stack>
            </Stack>
          </Sheet>

          {open && (
            <Stack component={Scrollbar} p={1} spacing={1} maxHeight={220} overflow="auto">
              {uploadings.map((one, index) => (
                <React.Fragment key={one.name + index}>
                  <UploadingLine name={one.name} progress={one.progress} error={one.error} />
                  <Divider light />
                </React.Fragment>
              ))}
            </Stack>
          )}
        </Stack>
      </Paper>
    </Box>,
    document.body
  );
}

const UploadingLine = ({ name, progress, error }) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" p={1}>
      <Stack width={1} direction="row" alignItems="center" spacing={1}>
        <TextMaxLine noTooltip fontSize={12}>
          {name}
        </TextMaxLine>
        <Typography fontSize={12}>({Math.ceil(progress * 100)}%)</Typography>
      </Stack>
      {error ? (
        <IconButton size="small" title="Une erreur s'est produite">
          <Iconify icon="eva:info-fill" color="red" />
        </IconButton>
      ) : progress < 1 ? (
        <CircularProgress variant="plain" size="sm" />
      ) : (
        <IconButton size="small">
          <Iconify icon="lets-icons:done-ring-round-fill" color="green" />
        </IconButton>
      )}
    </Stack>
  );
};
