import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import useAuth from './useAuth';
import { epochToDate } from '../utils/formatTime';
import { dispatch, useDispatch } from '../redux/store';
import { chatForsakeNotification, getAllNotification, setNotificationsAsRead } from '../redux/slices/notifications';
import { useLocation, useNavigate } from 'react-router-dom';
import { NOTIFICATION_TYPES } from '../constants';
import { PATH_DASHBOARD, DOC_PATH } from '../routes/paths';
import { DateTime } from 'luxon';
import { useAudienceContext } from 'src/contexts/AudienceContext';
import { useCommunityContext } from 'src/contexts/CommunityContext';
import { useAffectation_v4Context } from 'src/contexts/Affectation_v4Context';
import { useTaskV4Context } from 'src/contexts/TaskContext';

const getResult = (getList, id) => {
  const preResult = isEmpty(getList) ? [] : getList;
  return preResult
    ?.filter((el) => el?.type !== NOTIFICATION_TYPES.CHAT)
    ?.map((one) => {
      return {
        ...one,
        rawCreatedAt: one?.createdAt,
        createdAt: epochToDate(one.createdAt),
        isUnRead: one.isUnRead ? one?.isUnRead[id] : true
      };
    });
};

const dataF = DateTime.now().minus({ days: 7 }).toJSDate();

export default function useNotifications() {
  const {
    user: { id }
  } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const getNotifications = useSelector((state) => state.firestore.ordered.notifications);
  const getBroadcasts = useSelector((state) => state.firestore.ordered.broadcasts);

  const notifications = useMemo(() => getResult(getNotifications, id), [getNotifications, id]);
  const broadcasts = useMemo(() => getResult(getBroadcasts, id), [getBroadcasts, id]);

  const combine = useMemo(() => [...notifications, ...broadcasts], [notifications, broadcasts]);
  const { openV4TaskById } = useTaskV4Context();

  const { openAudienceById } = useAudienceContext();
  const { selectedChannel, openChannelById } = useCommunityContext();

  const couriel = useAffectation_v4Context();
  //const { taskRights } = useTaskOpenWithRight();

  const executeNotification = (notification) => {
    const { isUnRead, type, action, id } = notification;
    //console.log({type, action, id});
    switch (type) {
      case NOTIFICATION_TYPES.DOCUMENT_SHARED:
        //console.log(`${DOC_PATH.shareWithMe}/${action.projectKey}`);
        if (action?.type === 'folder') {
          navigate(`${DOC_PATH.shareWithMe}/${action.projectKey}`);
        } else {
          navigate(`${DOC_PATH.shareWithMe}`);
        }
        break;
      case NOTIFICATION_TYPES.DOCUMENT_SHARED_USER_LEAVE:
        navigate(`${DOC_PATH.folders}/${action?.projectKey}`);
        break;
      case NOTIFICATION_TYPES.SEND_AUTORIZATION_FOR_ELEMENT:
        navigate(`${DOC_PATH.auth}`);
        break;

      case NOTIFICATION_TYPES.ADD_FILE_TO_FOLDER:
        navigate(`${DOC_PATH.folders}/${action?.projectKey}`);
        break;

      case NOTIFICATION_TYPES.ACCEPTED_AUTORIZATION_FOR_ELEMENT:
        if (action?.type === 'folder') {
          navigate(`${DOC_PATH.shareWithMe}/${action.projectKey}`);
        } else {
          navigate(`${DOC_PATH.shareWithMe}`);
        }
        break;
      case NOTIFICATION_TYPES.REJECTED_AUTORIZATION_FOR_ELEMENT:
        navigate(`${DOC_PATH.auth}`);
        break;

      case NOTIFICATION_TYPES.COMMUNICATION_ADD_TO_CHANNEL:
        {
          navigate(PATH_DASHBOARD.general.app);
          openChannelById(action?.id);
          selectedChannel(action?.id);
        }
        break;
      
      
      case NOTIFICATION_TYPES.COMMUNICATION_NEW_MESSAGE:
        {
          navigate(PATH_DASHBOARD.general.app);
          openChannelById(action?.id);
          selectedChannel(action?.id);
        }
        break;

      case NOTIFICATION_TYPES.COMMUNICATION_NEW_ANSWER_MESSAGE:
        {
          navigate(`${PATH_DASHBOARD.general.app}/${action?.id}/${action?.messageId}`);
          openChannelById(action?.id);
          selectedChannel(action?.id);
        }
        break;

      case NOTIFICATION_TYPES.AUDIENCE:
        {
          const audienceId = action?.audienceId;
          if (audienceId) openAudienceById(audienceId);
        }
        break;
      case NOTIFICATION_TYPES.BLOG:
        action.postId && navigate(PATH_DASHBOARD.blog.post.replace(':title', action.postId));
        break;

      case NOTIFICATION_TYPES.PROJECT_CREATE:
        action?.projectKey &&
          navigate(`${PATH_DASHBOARD.general.tasks}/${action?.projectKey}`, {
            state: {
              projectName: ' '
            }
          });
        break;
      case NOTIFICATION_TYPES.PROJECT_UPDATE:
        action?.projectKey &&
          navigate(`${PATH_DASHBOARD.general.tasks}/${action?.projectKey}`, {
            state: {
              projectName: ' '
            }
          });
        break;

      case NOTIFICATION_TYPES.PROJECT_DEADLINE_EXTEND:
        action?.projectKey &&
          navigate(`${PATH_DASHBOARD.general.tasks}/${action?.projectKey}`, {
            state: {
              projectName: action?.projectName
            }
          });
        break;

      case NOTIFICATION_TYPES.PROJECT_DEADLINE_EXTEND_PERMISSION:
        action?.projectKey &&
          navigate(`${PATH_DASHBOARD.general.tasks}/${action?.projectKey}`, {
            state: {
              projectName: action?.projectName
            }
          });
        break;

      case NOTIFICATION_TYPES.OPPORTUNITIES:
        navigate(PATH_DASHBOARD.general.opportunity.root);
        break;

      case NOTIFICATION_TYPES.STAGE:
        navigate(PATH_DASHBOARD.general.accueil.root);
        break;

      case NOTIFICATION_TYPES.CHAT:
        navigate(PATH_DASHBOARD.chat.root);
        break;

      case NOTIFICATION_TYPES.LOAN:
        navigate(PATH_DASHBOARD.gestion_client.root);
        break;

      case NOTIFICATION_TYPES.PROJECT_CHAT:
        action?.projectId &&
          navigate(`${PATH_DASHBOARD.general.tasksProject.replace(':projectId', action?.projectId)}?tab=Conversation`, {
            state: { projectName: '  ' }
          });
        break;

      case NOTIFICATION_TYPES.ARCHIVE:
        navigate(
          `${PATH_DASHBOARD.general.archive.root}/${action?.folderId && `?folderId=${action?.folderId}`}${
            action?.fileId && `&fileId=${action?.fileId}`
          }`
        );
        break;
      case NOTIFICATION_TYPES.COURIEL_ARCHIVE:
        //console.log(action?.projectKey);
         navigate(
          `${PATH_DASHBOARD.general.affectation}/${action?.projectKey}`
        );
        break;
      case NOTIFICATION_TYPES.COURIEL_UN_ARCHIVE:
        //console.log(action?.projectKey);
         navigate(
          `${PATH_DASHBOARD.general.affectation}/${action?.projectKey}`
        );
        break;
      case NOTIFICATION_TYPES.COURIEL_TRASH:
        //console.log(action?.projectKey);
         navigate(
          `${PATH_DASHBOARD.general.affectation}/${action?.projectKey}`
        );
        break;
      case NOTIFICATION_TYPES.COURIEL_UN_TRASH:
        //console.log(action?.projectKey);
         navigate(
          `${PATH_DASHBOARD.general.affectation}/${action?.projectKey}`
        );
        break;
      case NOTIFICATION_TYPES.COURIEL_HISTORY:
        //console.log(action?.projectKey);
         navigate(
          `${PATH_DASHBOARD.general.affectation}/${action?.projectKey}`
        );
        break;
      case NOTIFICATION_TYPES.COURIEL_DELETE:
        //console.log(action?.projectKey);
        break;
      case NOTIFICATION_TYPES.COURIEL_ASSIGNED:
        //console.log(action?.projectKey);
        navigate(`${PATH_DASHBOARD.general.affectation}/${action?.projectKey}`);
        break;
      
      case NOTIFICATION_TYPES.COURIEL_CREATION:
        navigate(
          `${PATH_DASHBOARD.general.affectation}/${action?.projectKey}`
        );
        break;
      
    
      
      case NOTIFICATION_TYPES.COURIEL_REJECT:
        //console.log(action?.projectKey);
        navigate(`${PATH_DASHBOARD.general.affectation}/${action?.projectKey}`);
        break;
      case NOTIFICATION_TYPES.COURIEL_ADD_ATTACHMENT:
        //console.log(action?.projectKey);
        navigate(`${PATH_DASHBOARD.general.affectation}/${action?.projectKey}`);
        break;

      case NOTIFICATION_TYPES.COURIEL_ADD:
        //console.log(action?.projectKey);
        navigate(`${PATH_DASHBOARD.general.affectation}/${action?.projectKey}`);
        break;
      
      case NOTIFICATION_TYPES.TASK_COMMENT_ADD:
        const taskId = action?.taskId;
        if (taskId) openV4TaskById(taskId);
        break;
      case NOTIFICATION_TYPES.TASK_MENTION:
        {
          const taskId = action?.taskId;
          if (taskId) openV4TaskById(taskId);
        }
        break;
      default: {
        if (type?.includes('couriel')) {
          return couriel?.openFormById(action?.id);
        }

        if (type?.toLowerCase()?.includes('task')) {
          const taskId =
            action?.id ||
            action?.cardId ||
            action?.detailId ||
            action?.taskId ||
            action?.returnId ||
            notification?.returnId;

          if (taskId) openV4TaskById(taskId);
        } else {
          console.error(`Type "${type}" for the notification with id "${id}" not recognized!`);
        }
      }
    }

    isUnRead &&
      dispatch(
        setNotificationsAsRead({
          notificationIds: [notification.id]
        })
      );
  };

  useFirestoreConnect(() => [
    {
      collection: 'notifications',
      where: [
        ['canAccess', 'array-contains', id],
        ['createdAt', '>=', dataF]
      ],
      orderBy: ['createdAt', 'desc'],
      storeAs: 'notifications'
    },
    {
      collection: 'notifications',
      where: [
        ['isBroadcast', '==', true],
        ['createdAt', '>=', dataF]
      ],
      orderBy: ['createdAt', 'desc'],
      limit: 30,
      storeAs: 'broadcasts'
    }
  ]);

  useEffect(() => {
    const state = !isLoaded(getNotifications) || !isLoaded(getBroadcasts);

    setLoading(state);

    state && dispatch(getAllNotification([...(getNotifications || []), ...(getBroadcasts || [])]));
  }, [getNotifications, getBroadcasts, combine, dispatch]);

  //Forsake notifications when the user is on the chat
  useEffect(() => {
    const onChatPath = location.pathname === PATH_DASHBOARD.chat.root;
    const unReadNotifications = notifications.filter(
      (item) => item.isUnRead === true && item.type === NOTIFICATION_TYPES.CHAT
    );
    const unReadIds = [...unReadNotifications.map((one) => one.id)];
    const unReadNotifTrueData = getNotifications?.filter((one) => unReadIds.includes(one.id)) || [];

    if (onChatPath && !isEmpty(unReadNotifTrueData)) {
      dispatch(chatForsakeNotification(unReadNotifTrueData));
    }
  }, [dispatch, getNotifications, location, notifications]);

  return { loading, notifications, broadcasts, combine, executeNotification };
}

export const useMarkAsReadNotificationById = () => {
  const { combine: _notifications } = useNotifications();

  return (id, NOTIFICATIONS_TYPES) => {
    //console.log('id', id);
    const notifications = () => {
      return _notifications.filter((item) => NOTIFICATIONS_TYPES.includes(item.type));
    };

    const unReadNotifications = notifications().filter((item) => item.isUnRead === true);

    //console.log('unReadNotifications', unReadNotifications);
    const targetsIdsNotifications = unReadNotifications.filter((item) => {
      if (item.action.id) return item.action.id === id;
      else if (item.action.projectKey) return item.action.projectKey === id;
      return false;
    });
    //console.log('targetNotification', targetsIdsNotifications);
    dispatch(
      setNotificationsAsRead({
        notificationIds: targetsIdsNotifications.map((one) => one.id)
      })
    );
  };
};
