export const envResolver = (key) => {
  return process.env[`REACT_APP_${key}`];
  // try {
  //   // eslint-disable-next-line no-undef
  // } catch (e) {
  //   return import.meta.env[`VITE_REACT_APP_${key}`];
  // }
};

//  fallback: {
//         "crypto": require.resolve("crypto-browserify"),
//         "stream": require.resolve("stream-browserify")
//       },

export const firebaseConfig = {
  apiKey: envResolver('FIREBASE_API_KEY'),
  authDomain: envResolver('FIREBASE_AUTH_DOMAIN'),
  databaseURL: envResolver('FIREBASE_DATABASE_URL'),
  projectId: envResolver('FIREBASE_PROJECT_ID'),
  storageBucket: envResolver('FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: envResolver('FIREBASE_MESSAGING_SENDER_ID'),
  appId: envResolver('FIREBASE_APPID')
};

export const firebaseConfigOKYDOOK = {
  apiKey: envResolver('FIREBASE_API_KEY_OKYDOOK'),
  authDomain: envResolver('FIREBASE_AUTH_DOMAIN_OKYDOOK'),
  databaseURL: envResolver('FIREBASE_DATABASE_URL_OKYDOOK'),
  projectId: envResolver('FIREBASE_PROJECT_ID_OKYDOOK'),
  storageBucket: envResolver('FIREBASE_STORAGE_BUCKET_OKYDOOK'),
  messagingSenderId: envResolver('FIREBASE_MESSAGING_SENDER_ID_OKYDOOK'),
  appId: envResolver('FIREBASE_APPID_OKYDOOK')
};

export const firebaseConfigDiwaproducts = {
  apiKey: envResolver('FIREBASE_API_KEY_DIWA'),
  authDomain: envResolver('FIREBASE_AUTH_DOMAIN_DIWA'),
  databaseURL: envResolver('FIREBASE_DATABASE_URL_DIWA'),
  projectId: envResolver('FIREBASE_PROJECT_ID_DIWA'),
  storageBucket: envResolver('FIREBASE_STORAGE_BUCKET_DIWA'),
  messagingSenderId: envResolver('FIREBASE_MESSAGING_SENDER_ID_DIWA'),
  appId: envResolver('FIREBASE_APPID_DIWA')
};

export const cognitoConfig = {
  userPoolId: envResolver('AWS_COGNITO_USER_POOL_ID'),
  clientId: envResolver('AWS_COGNITO_CLIENT_ID')
};

export const auth0Config = {
  clientId: envResolver('AUTH0_CLIENT_ID'),
  domain: envResolver('AUTH0_DOMAIN')
};

export const mapConfig = envResolver('MAP_MAPBOX');

export const googleAnalyticsConfig = envResolver('GA_MEASUREMENT_ID');

export const CONFIG_KEYS = {
  LINK_PREVIEW_KEY: envResolver('LINK_PREVIEW_API_KEY'),
  LINK_PREVIEW_KEY2: envResolver('LINK_PREVIEW_API_KEY2'),
  LINK_PREVIEW_KEY3: envResolver('LINK_PREVIEW_API_KEY3')
};

export const cloudAudience = {
  get: envResolver('CLOUD_GET_AUDIENCE'),
  set: envResolver('CLOUD_SET_AUDIENCE')
};

export const MICROSOFT_CONFIG = {
  clientId: envResolver('CLIENT_ID'),
  clientSecret: 's4x8Q~xfq2Gpl6xE.6SKQZDiAtRAj2HpzADaObfQ',
  redirectUri: 'http://localhost:3000',
  BASE_URL: envResolver('MICROSOFT_BASE_URL'),
  GRAPH_URL: envResolver('MICROSOFT_GRAPH_URL')
};

export const NAVBAR = {
  BASE_WIDTH: 220,
  DASHBOARD_WIDTH: 220,
  DASHBOARD_COLLAPSE_WIDTH: 60,
  COLLAPSE_WIDTH: 57,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32
};

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
  APPBAR_MOBILE: 50,
  APPBAR_DESKTOP: 50
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20
};

export const mail = {
  from: envResolver('MAIL_SENDER'),
  subject: envResolver('MAIL_SENDER'),
  domaine: window.location.host,
  protocole: window.location.protocol,
  template: {
    members: envResolver('EMAIL_MEMBER_TEMPLATE')
  }
};

export const MAIL_CONFIG = {
  from: envResolver('MAIL_SENDER'),
  subject: envResolver('MAIL_SENDER'),
  domaine: window.location.host,
  protocole: window.location.protocol,
  template: {
    members: envResolver('EMAIL_MEMBER_TEMPLATE'),
    audience: envResolver('AUDIENCE_TEMPLATE_ID'),
    invitation: envResolver('EMAIL_INVITATION_TEMPLATE')
  }
};

export const ALGOLIA_KEYS = {
  appId: envResolver('ALGOLIA_APP_KEY'),
  apiKey: envResolver('ALGOLIA_API_KEY'),
  index_name: envResolver('ALGOLIA_INDEX_NAME_KEY')
};

export const NOTIF_CONFIG = {
  fb_notif: envResolver('FBNOTIF')
};

export const LOCAL_KEY_CONFIG = envResolver('API_KEY_LOCAL');
export const API_KEY = envResolver('API_KEY');

export const DOC_READER_URL = envResolver('DOCUMENT_READER_API_URL');

export const APP_INFO = {
  name: envResolver('NAME')
};


export const CLOUD_MAIL_API_KEY = `${API_KEY}/mail`;
export const CLOUD_MAIL_CUSTOM_API_KEY = `${envResolver('EMAIL_API_KEY')}`;

export const DOCUMENT_READER_SERVER_URL =
  process.env.NODE_ENV === 'development'
    ? `${envResolver('DRIVER_DEV_LINK')}/okydookk/us-central1/api/track`
    : `${API_KEY}/track`;
