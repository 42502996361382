import { Dialog } from '@mui/material';

import useAuth from 'src/hooks/useAuth';

import { getFileFormat } from '../utils/getFileFormat';

import LightboxModal from './LightboxModal';
import WordFileEditor from './WordFileEditor';
import PdfFileEditor from './PdfFileEditor';
import ExcelFileEditor from './ExcelFileEditor';
import VideoAndAudioPlayerModal from 'src/components/VideoAndAudioPlayerModal';

export const isFileHasReader = (file) => {
  const supported = ['image', 'word', 'excel', 'pdf', 'video'];

  if (file) {
    const type = getFileFormat(file?.type.replace('/', '.'));
    return supported.includes(type);
  }

  return false;
};
export default function AttachmentReader({ file, open, onClose, onUpdate }) {
  const { user } = useAuth();

  if (!open) return <></>;
  if (!file) return <></>;

  const type = getFileFormat(file?.type.replace('/', '.'));

  const handleUpdate = (metas) => {
    if (onUpdate) {
      onUpdate(metas);
    }
  };

  const handleCloseView = (metas) => {
    onClose();
    if (onUpdate) {
      onUpdate(metas);
    }
  };

  if (type === 'image') {
    return <LightboxModal isOpen images={[file?.url]} photoIndex={0} onClose={onClose} setPhotoIndex={() => { }} />;
  }

  if (type === 'word' || type === 'powerpoint' || type === 'excel', type === 'pdf') {
    return (
      <DocumentReader
        onClose={onClose}
        open={Boolean(open)}
        document={{
          key: file?.id,
          url: file?.url,
          title: file?.name,
          fileType: file?.type

        }}
        uploadName={file?.name}
        docKey={file?.docKey || file?.id}
        openMode={'view'}
        loading={file}
        editable={false}
      />
    );
  }


  if (type?.includes('video') || type?.includes('audio')) {
    return (
      <VideoAndAudioPlayerModal
        open
        file={file}
        {...file}
        email={user?.email}
        handleUpdate={handleUpdate}
        onClose={handleCloseView}
      />
    );
  }

  return <></>;
}

const Modal = ({ children }) => {
  return (
    <Dialog open fullWidth fullScreen>
      {children}
    </Dialog>
  );
};
