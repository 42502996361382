import React from 'react';
import { Box, IconButton, Paper, Tooltip } from '@mui/material';

/** @type {IconButton} props  */
const CIconButton = React.forwardRef(function (props, ref) {
  const { children, title = null, noBorder, noBackground, sx, size, ...other } = props;

  return title ? (
    <Tooltip title={title} arrow slotProps={{ popper: { sx: { zIndex: 9999 } } }}>
      <Box ref={ref}>
        <Paper
          component={IconButton}
          {...other}
          variant={noBorder ? 'elevation' : 'outlined'}
          size={size || 'small'}
          sx={{ ...sx, ...(noBackground && { bgcolor: 'transparent' }) }}
        >
          {children}
        </Paper>
      </Box>
    </Tooltip>
  ) : (
    <Paper
      component={IconButton}
      ref={ref}
      {...other}
      variant={noBorder ? 'elevation' : 'outlined'}
      size={size || 'small'}
      sx={{ ...sx, ...(noBackground && { bgcolor: 'transparent' }) }}
    >
      {children}
    </Paper>
  );
});

export default CIconButton;
