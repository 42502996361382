import { getFileFormat } from 'src/utils/getFileFormat';

export const TABLE_GRID_SIZE = { NAME: 1, SHARE: 200, EDIT: 170 };

export const DRIVER_TYPE_FOLDER = 'folder';

export const AUTHORIZATION_REQUEST_TYPE = { RECEIVED: 'received', SEND: 'send' };

export const ZIPPING_STATES = {
  START: 'start',
  LOADING: 'loading',
  DONE: 'done',
  ERROR: 'error'
};

export const permissionTitle = (autType) => {
  if (autType === AUTHORIZATION_TYPE.SHARE) return "Demande d'autorisation pour le partage du document";
  if (autType === AUTHORIZATION_TYPE.DELETE) return "Demande d'autorisation pour la supression du document";
  if (autType === AUTHORIZATION_TYPE.PRIVACY) return "Demande d'autorisation pour rendre public le document";
  return "Demande d'autorisation pour le document ";
};

export const DRVIER_ACCESS = {
  VIEW: { key: 'view', label: 'Afficher', desc: 'Peut afficher uniquement' },
  DOWNLOAD: { key: 'download', label: 'Télécharger', desc: 'Peut afficher et télécharger' },
  EDIT: { key: 'edit', label: 'Modifier', desc: 'Peut modifier' },
  SHARE: { key: 'share', label: 'Partager', desc: 'Peut partager et modifier' },
  ALL: { key: 'all', label: 'Tout', desc: 'Tous les droits' }
};

export const ALGOLIA_TRANSLATION = {
  button: {
    buttonText: '',
    buttonAriaLabel: ''
  },
  modal: {
    searchBox: {
      resetButtonTitle: 'Vider la rechercher',
      resetButtonAriaLabel: 'Vider la rechercher',
      cancelButtonText: 'Annuler',
      cancelButtonAriaLabel: 'Annuler'
    },
    startScreen: {
      recentSearchesTitle: 'Recent',
      noRecentSearchesText: 'Pas de recherche recente',
      saveRecentSearchButtonTitle: 'Enregistrer',
      removeRecentSearchButtonTitle: "Retirer des l'historiques",
      favoriteSearchesTitle: 'Favorite',
      removeFavoriteSearchButtonTitle: "Retirer des l'historiques"
    },
    errorScreen: {
      titleText: 'Unable to fetch results',
      helpText: 'You might want to check your network connection.'
    },
    footer: {
      selectText: 'selection',
      selectKeyAriaLabel: 'Touche enter',
      navigateText: 'Navigation',
      navigateUpKeyAriaLabel: 'Arrow up',
      navigateDownKeyAriaLabel: 'Arrow down',
      closeText: 'Fermer',
      closeKeyAriaLabel: 'Echap',
      searchByText: 'Rechercher par '
    },
    noResultsScreen: {
      noResultsText: 'Aucun resultat',
      suggestedQueryText: 'Essayez de rechercher',
      reportMissingResultsText: 'Vous pensez que cette requête devrait renvoyer des résultats ?',
      reportMissingResultsLinkText: 'Faites le nous savoir.'
    }
  }
};

/**
 *
 * @param {string} value  Peut-être URL, Type, Nom du fichier
 * @returns {'Dossier' | 'image'| 'video' | 'word' | 'txt' | 'html' | 'excel' | 'powerpoint'| 'pdf' | 'photoshop' | 'illustrator' | 'plain' }
 */
export const driverElementType = (value) => {
  if (value === DRIVER_TYPE_FOLDER) return 'Dossier';
  return `${getFileFormat(value)}`;
};

export const PRIVATE_TEXT = {
  ACCESS: "Contrôle d'accès",
  ACCESS_SUB: 'Ajouter les administrateurs pour ce',
  ACCESS_TO_PUBLIC: 'Il vous faut les autorisations des utilisateurs ci-dessous pour rendre ce document plubic',
  ACCESS_TO_SHARE: 'Il vous faut les autorisations des utilisateurs ci-dessous pour partager ce document',
  ACCESS_TO_DELETE: 'Il vous faut les autorisations des utilisateurs ci-dessous pour supprimer ce document'
};

export const AUTHORIZATION_TYPE = {
  SHARE: 'share',
  DELETE: 'delete',
  PRIVACY: 'privacy'
};

export const AUTHORIZATION_TYPE_CONVERTER = {
  [AUTHORIZATION_TYPE.SHARE]: 'Partage',
  [AUTHORIZATION_TYPE.DELETE]: 'Suppression',
  [AUTHORIZATION_TYPE.PRIVACY]: 'Confidentialité'
};

export const AUTHORIZATION_STATE = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECT: 'reject'
};

export const AUTHORIZATION_STATE_CONVERTER = {
  pending: 'En attente',
  accepted: 'Validée',
  reject: 'Rejetée'
};

export const FILES_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  PDF: 'pdf',
  WORD: 'word',
  EXCEL: 'excel',
  POWERPOINT: 'powerpoint',
  OTHERS: { txt: 'txt', html: 'html', plain: 'plain' }
};
