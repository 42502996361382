import { isArray, uniq } from 'lodash';
import { dispatch } from 'src/redux/store';
import { createSlice } from '@reduxjs/toolkit';
import { serverTime } from 'src/utils/serverTime';
import axiosRequest from 'src/utils/axiosRequest';
import { asyncForEach } from 'src/utils/asyncForeach';
import { auth, firestore } from 'src/contexts/FirebaseContext';
import { compareArraysOfObjects } from 'src/utils/changeOnObject';
import { compressAndDownload } from 'src/helpers/compressAndDownload';
import { IOkyDriverFile, IOkyDriverFolder } from 'src/models/IOkyDriver';
import { createFilesFromUrl, downloadFile } from 'src/helpers/downloadFile';
import {
  acceptedAutorizationForElementNotification,
  addFileToFolderNotification,
  documentSharedNotification,
  documentSharedUserLeaveNotification,
  rejectedAutorizationForElementNotification,
  sendAutorizationForElementNotification
} from '../notifications';
import { AUTHORIZATION_STATE, DRIVER_TYPE_FOLDER } from 'src/section/doc/okydriver/annexes/helpers';

const initialState = {
  driver: [],
  error: false,
  isLoading: false,
  docKeys: {},
  docHistories: {}
};

const slice = createSlice({
  name: 'driver',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error(action.payload);
    },

    getDocKey(state, action) {
      const { id, data } = action.payload;
      state.docKeys[id] = data;
    },
    getHistories(state, action) {
      const { id, data } = action.payload;
      state.docHistories[id] = data;
    }
  }
});

export default slice.reducer;

const { actions } = slice;

export const driverReference = firestore.collection('okydook_driver');
export const driverTagReference = firestore.collection('okydook_driver_tags');

//#region HELPERS

const removeFileExtension = (fileName) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    return fileName.slice(0, lastDotIndex);
  }
  return fileName;
};

const getFileExtension = (fileName) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    return `.${fileName.slice(lastDotIndex + 1)}`;
  }
  return '';
};

const checkNameExistance = ({ list = [], element, num }) => {
  const nameWithoutExtension = removeFileExtension(element?.name);

  const nameExist = list.find((item) => {
    if (item?.type === element?.type) {
      const name = Boolean(num) ? `${nameWithoutExtension} (${num})` : nameWithoutExtension;
      return name === removeFileExtension(item?.name);
    }
    return false;
  });

  if (Boolean(nameExist)) {
    return checkNameExistance({ list, element, num: num + 1 });
  }

  return Boolean(num)
    ? { ...element, name: `${nameWithoutExtension} (${num})${getFileExtension(element?.name)}` }
    : element;
};

/**
 *
 * @param {{
 * existItems: Array<IOkyDriverFolder | IOkyDriverFile>,
 * element: IOkyDriverFolder | IOkyDriverFile | Array<IOkyDriverFile>
 * }} params
 * @returns {IOkyDriverFolder | IOkyDriverFile | Array<IOkyDriverFile>}
 */
export const checkNameExistanceAndRename = ({ existItems = [], element }) => {
  if (!element.length) {
    return checkNameExistance({ list: existItems, element, num: 0 });
  }

  return element.map((one) => {
    return checkNameExistance({ list: existItems, one, num: 0 });
  });
};
//#endregion

//#region FOLDER
export const addFolderAction = ({ parentId, name, items, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;
      const folderRef = driverReference.doc();
      const folderId = folderRef.id;

      const element = checkNameExistanceAndRename({ existItems: items, element: { name, type: 'folder' } });

      let toSave = {
        name: element?.name,
        url: '',
        size: 0,
        tags: {},
        id: folderId,
        type: 'folder',
        sharedWith: [],
        updatedBy: uid,
        uploadedBy: uid,
        isDeleted: false,
        sharedWithIds: [],
        createdAt: serverTime(),
        updatedAt: serverTime(),
        parentFolderId: parentId
      };

      await folderRef.set(toSave, { merge: true });
      if (onSuccess) onSuccess(folderId);
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

export const getFolderById = ({ folderId, onSuccess, onError }) => {
  return async (dispatch) => {
    try {
      const folderDoc = await driverReference.doc(folderId).get();
      if (folderDoc.exists) {
        const data = folderDoc.data();
        if (onSuccess) onSuccess(data);
      }
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

/**
 * @param {Array} treeList
 * @param {string} parentId
 * @param {Array} newChildren
 * @returns
 */
export function addChildToTreeList(treeList, parentId, newChildren) {
  let parentFound = false;

  const addChildToNode = (node) => {
    if (node.id === parentId) {
      if (!node.children) {
        node.children = [];
      }
      newChildren.forEach((child) => {
        if (!node.children.find((one) => one?.id === child?.id)) {
          node.children.push(child);
        }
      });
      parentFound = true;
    } else if (node.children) {
      node.children.forEach(addChildToNode);
    }
  };

  treeList.forEach(addChildToNode);

  if (!parentFound) {
    treeList.push(...newChildren);
  }

  return treeList;
}

export const getFoldersContentAction = ({ folderId, onSuccess, onError }) => {
  return async (dispatch) => {
    try {
      if (folderId) {
      }

      const foldersDoc = await driverReference
        .where('parentFolderId', '==', folderId)
        .where('type', '==', 'folder')
        .where('isDeleted', '==', false)
        .orderBy('updatedAt', 'asc')
        .get();

      let items = [];

      foldersDoc.docs.map((doc) => {
        const data = doc?.data();
        items.push({ name: data?.name, id: doc?.id });
      });

      if (onSuccess) {
        onSuccess({ parentFolderId: folderId, items });
      }
    } catch (error) {
      console.error(error);
      if (onError) onError();
    }
  };
};

const _getFolderPath = async (folderId, path = [], topLevel) => {
  const folderDoc = await driverReference.doc(folderId).get();
  if (folderDoc.exists) {
    const folderData = folderDoc.data();
    path.unshift({ name: folderData?.name, id: folderData?.id });

    if (folderData?.parentFolderId && folderData?.id !== topLevel) {
      await _getFolderPath(folderData?.parentFolderId, path, topLevel);
    }
  }

  return path;
};

export const getFolderPath = ({ currentFolder, topLevel, onSuccess, onError }) => {
  return async (dispatch) => {
    try {
      const path = await _getFolderPath(currentFolder, [], topLevel);
      if (onSuccess) onSuccess(path);
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};
//#endregion

//#region FILES
export const addFileAction = ({ parentId, name, url, type, size, items, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;
      const fileRef = driverReference.doc();
      const fileId = fileRef.id;

      const element = checkNameExistanceAndRename({ existItems: items, element: { name, type } });

      let toSave = {
        name: element?.name,
        metadata: { name, type, size },
        url,
        size,
        type,
        tags: [],
        id: fileId,
        sharedWith: [],
        updatedBy: uid,
        uploadedBy: uid,
        isDeleted: false,
        createdAt: serverTime(),
        updatedAt: serverTime(),
        parentFolderId: parentId
      };

      const parentData = (await driverReference.doc(parentId).get()).data();

      // console.log({ parentData });
      await fileRef.set(toSave, { merge: true });
      if (onSuccess) onSuccess(fileId);

      dispatch(
        addFileToFolderNotification({
          item: toSave,
          targetIds: [...parentData?.sharedWithIds || []],
          callback: () => { }
        })
      );
      
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

//#endregion

//#region BOTH FOLDER FILE
export const updateElementNameAction = ({ elementId, name, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;
      const folderRef = driverReference.doc(elementId);

      let toUpdate = {
        name,
        updatedBy: uid,
        updatedAt: serverTime()
      };

      await folderRef.set(toUpdate, { merge: true });
      if (onSuccess) onSuccess(elementId);
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

export const updateElementDescriptionAction = ({ elementId, description, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;
      const folderRef = driverReference.doc(elementId);

      let toUpdate = {
        description,
        updatedBy: uid,
        updatedAt: serverTime()
      };

      await folderRef.set(toUpdate, { merge: true });
      if (onSuccess) onSuccess(elementId);
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

/**
 *
 * @param {{
 * favorite: Array<{elementId:string, favorite: object }>;
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const updateElementFavorisAction = ({ favorite, onSuccess, onError }) => {
  return async () => {
    try {
      const batch = firestore.batch();

      favorite.forEach((element) => {
        const folderRef = driverReference.doc(element?.elementId);
        batch.update(folderRef, { favorite: element?.favorite });
      });
      await batch.commit();

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

/**
 *
 * @param {{
 * elementId: string;
 * tags: Array<string>,
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const updateElementTagsAction = ({ elementId, tags, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;

      let elementRef = driverReference.doc(elementId);
      elementRef.update({ tags });
      const tagToArray = tags[uid];
      elementRef.collection('tags').doc(uid).set({ tags: tagToArray, elementId }, { merge: true });
      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

/**
 *
 * @param {{
 * items: Array<IOkyDriverFile | IOkyDriverFolder>;
 * parentId: string
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const copyElementAction = ({ items, parentId, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;

      const batch = firestore.batch();

      items.forEach((element) => {
        const folderRef = driverReference.doc();
        batch.set(folderRef, {
          ...element,
          id: folderRef.id,
          parentFolderId: parentId,
          updatedBy: uid,
          updatedAt: serverTime()
        });
      });
      await batch.commit();

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

/**
 *
 * @param {{
 * items: Array<IOkyDriverFile | IOkyDriverFolder>;
 * parentId: string
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const moveElementAction = ({ items, parentId, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;

      const batch = firestore.batch();

      items.forEach((element) => {
        const folderRef = driverReference.doc(element?.id);

        batch.update(folderRef, {
          parentFolderId: parentId,
          updatedBy: uid,
          updatedAt: serverTime()
        });
      });
      await batch.commit();

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

const _movetoTrash = async (elementId, batch, uid, updateValue) => {
  const elementRef = driverReference.doc(elementId);
  const subItemsSnapshot = await driverReference.where('parentFolderId', '==', elementId).get();

  batch.update(elementRef, { ...updateValue });

  for (const doc of subItemsSnapshot.docs) {
    await _movetoTrash(doc?.id, batch, uid, {
      ...updateValue,
      parentFolderId: elementId,
      tempParentFolderId: elementId
    });
  }
};

/**
 *
 * @param {{
 * items: Array<IOkyDriverFile | IOkyDriverFolder>;
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const toTrashElementAction = ({ items, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;

      const batch = firestore.batch();

      for (const element of items) {
        await _movetoTrash(element?.id, batch, uid, {
          isDeleted: true,
          updatedBy: uid,
          updatedAt: serverTime(),
          parentFolderId: uid,
          tempParentFolderId: element?.parentFolderId
        });
      }

      await batch.commit();

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

const _moveOutToTrash = async (elementId, batch, uid, updateValue) => {
  const elementRef = driverReference.doc(elementId);
  const subItemsSnapshot = await driverReference.where('parentFolderId', '==', elementId).get();

  batch.update(elementRef, { ...updateValue });

  for (const doc of subItemsSnapshot.docs) {
    const tempParentFolderId = doc.data()?.tempParentFolderId;

    await _movetoTrash(doc?.id, batch, uid, {
      ...updateValue,
      parentFolderId: tempParentFolderId,
      tempParentFolderId: null
    });
  }
};

/**
 *
 * @param {{
 * items: Array<IOkyDriverFile | IOkyDriverFolder>;
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const restoreFromTrashElementAction = ({ items, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;

      const batch = firestore.batch();

      for (const element of items) {
        await _moveOutToTrash(element?.id, batch, uid, {
          isDeleted: false,
          updatedBy: uid,
          updatedAt: serverTime(),
          parentFolderId: element?.tempParentFolderId,
          tempParentFolderId: null
        });
      }

      await batch.commit();

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

const _wipetoChildrens = async (elementId, batch) => {
  const elementRef = driverReference.doc(elementId);
  const subItemsSnapshot = await driverReference.where('parentFolderId', '==', elementId).get();

  batch.delete(elementRef);

  for (const doc of subItemsSnapshot.docs) {
    await _wipetoChildrens(doc?.id, batch);
  }
};

/**
 *
 * @param {{
 * items: Array<IOkyDriverFile | IOkyDriverFolder>;
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 */
export const wipeTrashElementsAction = ({ items, onSuccess, onError }) => {
  return async (dispatch) => {
    try {
      const batch = firestore.batch();

      for (const element of items) {
        await _wipetoChildrens(element?.id, batch);
      }

      await batch.commit();

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError();
      // console.log(error);
    }
  };
};

/**
 *
 * @param {{
 * item: IOkyDriverFile | IOkyDriverFolder;
 * sharedWith: Array,
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const shareElementAction = ({ item, sharedWith, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;
      const folderRef = driverReference.doc(item?.id);
      const sharedWithIds = sharedWith.map((one) => one?.userId);
      // console.log({ item });

      const { added, removed } = compareArraysOfObjects(item.sharedWith, sharedWith, 'userId');

      if (removed.length) {
        //console.log({ removed });
        dispatch(
          documentSharedUserLeaveNotification({
            item: item,
            targetIds: [item?.uploadedBy],
            callback: () => {
              console.log('callback');
            }
          })
        );
        //send leave notification to createdby
      }

      if (added.length) {
        //console.log({ added });
        dispatch(
          documentSharedNotification({
            item: item,
            targetIds: added.map((one) => one?.userId),
            callback: () => {
              // console.log('callback');
            }
          })
        );
        //send add notifcation to persons
      }

      let toUpdate = {
        sharedWith,
        sharedWithIds,
        lastShareBy: uid,
        lastShareAt: serverTime()
      };

      await folderRef.set(toUpdate, { merge: true });
      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

/**
 * Removes a user from the sharedWith array for the given item.
 *
 * @param {IOkyDriverFile | IOkyDriverFolder} item - The item to update
 * @param {Array} sharedWith - The userId to remove from sharedWith
 * @param {Function} onSuccess - Callback for success
 * @param {Function} onError - Callback for errors
 */
export const removeSharedUserAction = ({ item, sharedWith, onSuccess, onError }) => {
  return async () => {
    try {
      //console.log({ item, sharedWith });

      const { uid } = auth.currentUser;
      const folderRef = driverReference.doc(item?.id);

      const _sharedWith = item?.sharedWith.filter((one) => one?.userId !== sharedWith);
      const _sharedWithIds = item?.sharedWithIds.filter((one) => one !== sharedWith);

      const toUpdate = {
        sharedWith: _sharedWith,
        sharedWithIds: _sharedWithIds,
        lastShareBy: uid,
        lastShareAt: serverTime()
      };

      //console.log({ toUpdate });

      await folderRef.set(toUpdate, { merge: true });
      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

/**
 * Updates the sharedWith array for the given item with the new sharedWith user.
 *
 * @param {IOkyDriverFile | IOkyDriverFolder} item - The item to update
 * @param {Array} sharedWith - The new sharedWith user to update to
 * @param {Function} onSuccess - Callback for success
 * @param {Function} onError - Callback for errors
 */
export const updateElementAction = ({ item, sharedWith: sharedWiths, onSuccess, onError }) => {
  return async () => {
    try {
      const sharedWith = sharedWiths[0];
      //console.log({ item, sharedWith });
      const { uid } = auth.currentUser;
      const folderRef = driverReference.doc(item?.id);

      const _sharedWith = item?.sharedWith.map((one) => {
        if (one?.userId === sharedWith?.userId) {
          return sharedWith;
        }
        return one;
      });

      let toUpdate = {
        ...item,
        sharedWith: _sharedWith,
        lastShareBy: uid,
        lastShareAt: serverTime()
      };

      await folderRef.set(toUpdate, { merge: true });
      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

/**
 *
 * @param {{
 * item: IOkyDriverFile | IOkyDriverFolder;
 * privacy: Array,
 * isPublic: boolean,
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const makeElementAsPrivateOrPublic = ({ item, privacy, isPublic = false, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;
      const folderRef = driverReference.doc(item?.id);

      let toUpdate = {
        private: {
          by: uid,
          value: isPublic ? false : true,
          canAutorize: isPublic ? [] : privacy,
          ...(isPublic && { autorizationRequestIds: [], autorizationRequest: {} })
        },
        updatedAt: serverTime()
      };

      await folderRef.set(toUpdate, { merge: true });
      if (onSuccess) onSuccess(toUpdate);
    } catch (error) {
      console.error(error);
      if (onError) onError();
    }
  };
};

const _getFolderTree = async (elementId) => {
  let documents = [];
  const subItemsSnapshot = await driverReference.where('parentFolderId', '==', elementId).get();

  for (const doc of subItemsSnapshot.docs) {
    const data = doc?.data();
    if (data?.type === DRIVER_TYPE_FOLDER) {
      const children = await _getFolderTree(doc?.id);
      documents.push({ name: data?.name, id: data?.id, url: data?.url, type: data?.type, children });
    } else {
      await documents.push({ name: data?.name, id: doc?.id, url: data?.url, type: data?.type });
    }
  }

  return documents;
};

/**
 *
 * @param {{
 *  elements: Array<IOkyDriverFile | IOkyDriverFolder>;
 *  onSuccess: () =>{},
 *  onError: () =>{}
 * }} params
 * @returns
 */
export const getFoldersChildren = ({ elements, onSuccess, onError }) => {
  return async () => {
    try {
      const folders = [];
      for (const element of elements) {
        if (element?.type === DRIVER_TYPE_FOLDER) {
          const folder = await _getFolderTree(element?.id);
          folders.push({
            name: element?.name,
            id: element?.id,
            type: element?.type,
            url: element?.url,
            children: folder
          });
        } else {
          folders.push({ ...element });
        }
      }

      if (onSuccess) onSuccess(folders);
    } catch (error) {
      console.error(error);
      if (onError) onError();
    }
  };
};

/**
 *
 * @param {{
 *  item: Array<IOkyDriverFile | IOkyDriverFolder>,
 *  callback: (blob?: Blob) =>{},
 *  onError: () => {}
 * }} params
 */
export const downloadItems = ({ item, callback, onError }) => {
  return async () => {
    try {
      if (item.length === 1 && item.at(0).type !== DRIVER_TYPE_FOLDER) {
        downloadFile(item.at(0).url, item.at(0).name, callback, onError);
        return;
      }

      if (isArray(item)) {
        const containeFolder = item.some((one) => one.type === DRIVER_TYPE_FOLDER);

        if (!containeFolder) {
          createFilesFromUrl({
            files: item,
            onSuccess: async (files) => {
              await compressAndDownload({ files, onSuccess: callback, onError });
            },
            onError
          });
          return;
        }

        if (containeFolder) {
          dispatch(
            getFoldersChildren({
              elements: item,
              onSuccess: async (folders) => {
                await compressAndDownload({ files: folders, withFolder: true, onSuccess: callback, onError });
              },
              onError
            })
          );
        }
      }
    } catch (error) {
      console.error(error);
      if (onError) onError(error);
    }
  };
};

/**
 *
 * @param {{
 * onError: () => {},
 * onSuccess: () =>{},
 * autorisationType:string,
 * item: IOkyDriverFile |IOkyDriverFolder,
 * }} params
 * @returns
 */
export const sendAutorizationForElement = ({ item, autorisationType, onSuccess, onError }) => {
  return async () => {
    try {
      // console.log({ item });
      const { uid } = auth.currentUser;
      const authIds = [];

      const folderRef = driverReference.doc(item?.id);

      await firestore.runTransaction(async (trans) => {
        const doc = await trans.get(folderRef);


        if (doc.exists) {
          const data = doc.data();
              // console.log({ data });


          let currentAuthorization = {};

          if (data?.private) {
            let _autorization = { ...(data?.autorizationRequest || {}) };
            let privacies = data?.private?.canAutorize;

            const accessAutorization = privacies.map((_id) => ({
              id: _id,
              auth: AUTHORIZATION_STATE.PENDING,
              note: null,
              at: null
            }));

            _autorization[uid] = {
              ...(_autorization[uid] || {}),
              [autorisationType]: { at: serverTime(), permission: accessAutorization }
            };

            currentAuthorization = { ..._autorization };
          }

          Object.entries(currentAuthorization).forEach(([_, val]) => {
            const _authtype = val[autorisationType] || {};
            const _privacies = _authtype['permission'];

            _privacies.forEach((one) => authIds.push(one?.id));
          });

          authIds.push(uid);

          trans.update(folderRef, { autorizationRequest: currentAuthorization, autorizationRequestIds: uniq(authIds) });
        }
      });

      if (onSuccess) onSuccess();
      dispatch(
        sendAutorizationForElementNotification({
          item,
          targetIds: [...authIds],
          autorisationType,
          callback: () => {}
        })
      );
    } catch (error) {
      console.error(error);
      if (onError) onError(error);
    }
  };
};

/**
 * @param {{
 * id: string,
 * auth: 'pending' | 'accepted' | 'reject',
 * autorisationType: string,
 * onSuccess: () => {},
 * onError: () => {}
 * }} params
 * @returns {Function}
  */

export const updateAuthorizationState = ({ item, autorisation, autorisationType, onSuccess, onError }) => {
  return async () => {
  
    try {
      const { uid } = auth.currentUser;
      const folderRef = driverReference.doc(item?.docId);

      const newItem = item?.permission.map((one) => {
        
          return { ...one, auth: autorisation, at: serverTime() };
        
      });

      // console.log({ newItem,uid });

      let toUpdate = {
        autorizationRequest: {
          ...item?.autorizationRequest,
          [uid]: {
            ...item?.autorizationRequest?.[uid],
            [autorisationType]: { at: serverTime(), permission: newItem }
          }
        }
      };

      await folderRef.set(toUpdate, { merge: true });
      if (onSuccess) onSuccess();

    } catch (error) {
      // console.log({ error });
      onError();
    }
  }
}


/**
 *
 * @param {{
 * note?: string,
 * sendId: string,
 * elementId: string,
 * onError: () => {},
 * onSuccess: () =>{},
 * autorisationType:string,
 * value: 'pending' | 'accepted' | 'reject'
 * }} params
 * @returns
 */
export const updateAutorizationForElement = ({
  element,
  sendId,
  autorisationType,
  value,
  note = null,
  onSuccess,
  onError
}) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;

      const folderRef = driverReference.doc(element?.docId);

      await firestore.runTransaction(async (trans) => {
        const doc = await trans.get(folderRef);

        if (doc.exists) {
          const data = doc.data();

          let currentAuthorization = {};
          const authIds = [];

          if (data?.private) {
            let _autorization = { ...(data?.autorizationRequest || {}) };
            const _authByUser = _autorization[sendId] || {};
            const _accessPerms = { ...(_authByUser[autorisationType] || {}) }?.permission || [];

            const accessAutorization = _accessPerms?.map((one) => {
              if (one?.id === uid) {
                return { ...one, auth: value, note, at: serverTime() };
              }
              return one;
            });

            _autorization[sendId] = {
              ...(_autorization[sendId] || {}),
              [autorisationType]: { at: serverTime(), permission: accessAutorization }
            };

            currentAuthorization = { ..._autorization };

            Object.entries(currentAuthorization).forEach(([_, val]) => {
              const _authtype = val[autorisationType] || {};
              const _privacies = _authtype['permission'];

              _privacies.forEach((one) => authIds.push(one?.id));
            });
          }

          authIds.push(uid);
          authIds.push(sendId);

          trans.update(folderRef, { autorizationRequest: currentAuthorization, autorizationRequestIds: uniq(authIds) });
        }
      });

      if (onSuccess) onSuccess();

      if (value === 'accepted') {
        dispatch(
          acceptedAutorizationForElementNotification({
            element,
            autorisationType,
            targetIds: [sendId],
            callback: () => {}
          })
        );
      } else if (value === 'reject') {
        dispatch(
          rejectedAutorizationForElementNotification({
            element,
            autorisationType,
            targetIds: [sendId],
            callback: () => {}
          })
        );
      }
    } catch (error) {
      console.error(error);
      if (onError) onError(error);
    }
  };
};

//#endregion

//#region TAGS
/**
 *
 * @param {{
 * tag: {label: string, color: string},
 * onError: ()=> {},
 * onSuccess: () =>{}
 * }} props
 */
export const createTagAction =
  ({ tag, onSuccess, onError }) =>
  async (dispatch) => {
    try {
      const { uid } = auth.currentUser;
      const tagRef = driverTagReference.doc();
      const tagId = tagRef.id;

      const toSave = {
        id: tagId,
        label: tag?.label,
        color: tag?.color,
        sharedWith: [],
        createdBy: uid,
        updatedBy: uid,
        createdAt: serverTime(),
        updatedAt: serverTime()
      };
      await tagRef.set(toSave, { merge: true });
      if (onSuccess) onSuccess(tagId);
    } catch (error) {
      if (onError) onError(error);
      // console.log(error);
    }
  };

/**
 *
 * @param {{
 * tag: {id: string, label: string, color: string},
 * onError: ()=> {},
 * onSuccess: () =>{}
 * }} props
 */
export const editTagAction =
  ({ tag, onSuccess, onError }) =>
  async (dispatch) => {
    try {
      const { uid } = auth.currentUser;
      const tagRef = driverTagReference.doc(tag?.id);

      const toSave = {
        label: tag?.label,
        color: tag?.color,
        updatedBy: uid,
        updatedAt: serverTime()
      };

      await tagRef.set(toSave, { merge: true });
      if (onSuccess) onSuccess(tag?.id);
    } catch (error) {
      if (onError) onError(error);
      // console.log(error);
    }
  };

/**
 *
 * @param {{
 * tagId: string,
 * onError: ()=> {},
 * onSuccess: () =>{}
 * }} props
 */
export const getTagsElemntsAction =
  ({ tagId, onSuccess, onError }) =>
  async (dispatch) => {
    try {
      const { uid } = auth.currentUser;

      const elementTags = await firestore.collectionGroup('tags').where('tags', 'array-contains', tagId).get();
      const docs = [];

      const getDocuments = async () => {
        await asyncForEach(elementTags.docs, async (doc) => {
          const elementId = doc?.data()?.elementId;
          const element = await driverReference.doc(elementId).get();
          const data = element.data();
          docs.push({ id: element.id, ...data });
        });

        if (onSuccess) onSuccess(docs);
      };

      getDocuments();
    } catch (error) {
      if (onError) onError(error);
      // console.log(error);
    }
  };

//#endregion

//#region ONLY OFFICE DOCUMENT

/**
 *
 * @param {object} params
 * @param {string} params.name  - Nom du fichier
 * @param {string} params.id  - identifiant du fichier
 * @param {string} params.isEditingDocKey  - identifiant d'ouverture de onlyoffice
 * @param {boolean} params.isEditing  - Si le fichier est déjà en édition ou non
 * @param {string} params.url - url du fichier
 * @param {string} params.doc_path - le chemin complet du fichier
 * @param {string} [params.parentId] - identifiant du parent du fichier si le fichier est lié à une affectation ou une tâche. exemple: taskId ou affectationId
 * @param {string} [params.module]  -  le nom du module auquel le fichier est lié
 * @param {function} [params.callback]  -  le nom du module auquel le fichier est lié
 */
export const saveOnlyOfficeFileCopy = ({
  name,
  id,
  url,
  doc_path,
  parentId = null,
  module = null,
  isEditingDocKey,
  isEditing,
  callback
}) => {
  return async () => {
    try {
      await axiosRequest.post('/onlyoffice-file-info', {
        name,
        id,
        url,
        doc_path,
        parentId,
        module,
        isEditingDocKey,
        isEditing
      });
      if (callback) callback();
    } catch (error) {
      console.error(error);
    }
  };
};

export const getOnlyOfficeFileCopy =
  ({ id, callback, onError }) =>
  async (dispatch, getStore) => {
    try {
      const store = getStore();
      const docKeys = store?.oky_driver?.docKeys || {};
      const exist = docKeys[id];

      if (exist !== undefined) {
        if (callback) return callback(exist);
      }

      const docRef = firestore.collection('onlyOffice').doc(id);
      docRef.onSnapshot((docSnap) => {
        if (docSnap.exists) {
          const data = { ...docSnap.data() };
          if (callback) callback(data);
          dispatch(actions.getDocKey({ id, data }));
          return;
        }
        dispatch(actions.getDocKey({ id, data: null }));
        if (callback) callback(null);
      });
    } catch (error) {
      console.error(error);
      if (onError) onError();
    }
  };

export const getOnlyOfficeFileLastHistories =
  ({ id, callback, onError }) =>
  async (dispatch, getStore) => {
    try {
      const store = getStore();
      const docHistories = store?.oky_driver?.docHistories || {};
      const exist = docHistories[id];

      if (exist !== undefined) {
        if (callback) return callback(exist);
      }

      const docRef = firestore.collection('onlyOffice').doc(id).collection('versions').orderBy('savedAt', 'desc');

      docRef.onSnapshot((docSnap) => {
        const histories = [];
        if (!docSnap.empty) {
          docSnap.docs.forEach((doc) => {
            const data = { id: doc.id, ...doc.data() };
            histories.unshift(data);
          });
        }
        dispatch(actions.getHistories({ id, data: histories }));
        if (callback) callback(histories);
      });
    } catch (error) {
      console.error(error);
      if (onError) onError();
    }
  };
//#endregion
