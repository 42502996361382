import { useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { nanoid } from '@reduxjs/toolkit';
import { defaultDb } from './FirebaseContext';
import { useNotifSnackbarContext } from '../App';
import { NOTIFICATION_TYPES } from 'src/constants';
import { NotificationSnackbar } from './../components/NotificationBody';
import { playNotificationSound } from './../utils/webNotif';
import { matchPath } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
// import { Button } from '@mui/material';

export default function NotificationContext({ children }) {
  const { setNotificationIds, canSendChatNotif, pathname } = useNotifSnackbarContext();
  // const { addDialog, removeDialog } = useMultiDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCloseNotification = useCallback((id, _notif) => {
    const thisNotif = _notif.find((one) => one?.id === id);
    closeSnackbar(thisNotif?.key);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultDb.messaging.isSupported()) {
      let stopSound = null;
      //TODO : add 
      defaultDb.messaging().onMessage((payload) => {
        const { title, body } = payload.notification;
        let customTitle = title;
        let customDesc = body;

        const bodyParse = JSON.parse(payload?.data['data']);
        if (
          bodyParse?.type === NOTIFICATION_TYPES.COMMUNICATION_NEW_MESSAGE &&
          !!matchPath({ path: PATH_DASHBOARD.general.app, end: false }, pathname)
        ) {
          return;
        }
        if (bodyParse?.type === NOTIFICATION_TYPES.RAPPEL) {
          stopSound = playNotificationSound(true);
        }

        const snackId = nanoid();
        let persist = true;

        if (bodyParse?.type === NOTIFICATION_TYPES.CHAT && !canSendChatNotif) {
          persist = false;
          return;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.CHAT) {
          persist = false;
          customTitle = 'Vous avez reçu un nouveau message';
          customDesc = `<strong> ${bodyParse?.by?.displayName}</strong> vous a envoyé un nouveau message`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.TASK_VALIDATION_DEMANDE) {
          customTitle = 'Demande de validation';
          customDesc = `Vous avez une demande de validation de la part de <strong>
            ${bodyParse?.by?.displayName}</strong> sur la tâche <strong> << ${bodyParse?.cardTitle} >> </strong>
            du projet <strong> << ${bodyParse?.other?.projectName} >> </strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.TASK_MENTION) {
          const taskName = bodyParse?.action?.cardName || bodyParse?.action?.taskName;
          const projectName = bodyParse?.other?.projectName || bodyParse?.action?.projectName;

          customDesc = `Vous avez été mentionné ${bodyParse?.action?.target} sur la tâche <strong> <i>${taskName}</i></strong> du projet <strong> << ${projectName} >> </strong> par  <strong> <i>${bodyParse?.by?.displayName}</i></strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.TASK) {
          customDesc = bodyParse?.description;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.TASK_VALIDATION_REJECT) {
          customTitle = 'Demande de validation rejetée';
          customDesc = `La demande de validation de la tâche
           <strong> << ${bodyParse?.cardTitle} >> </strong> du projet  <strong> << ${bodyParse?.other?.projectName} >> </strong> 
           a été rejetée par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.TASK_BEGINING) {
          customTitle = 'Tâche démarrée';
          customDesc = `La tâche
           <strong> << ${bodyParse?.cardTitle} >> </strong> du projet  <strong> << ${bodyParse?.other?.projectName} >> </strong> 
           a été démarrée par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.TASK_VALIDATION_ACCEPTED) {
          customTitle = 'Demande de validation acceptée';
          customDesc = `La demande de validation de la tâche 
          <strong> << ${bodyParse?.cardTitle} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> 
          a été acceptée par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.TASK_VALIDATION_DONE) {
          customTitle = 'Tâche Terminée';
          customDesc = `La tâche <strong> << ${bodyParse?.cardTitle} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> a été marquée comme terminée par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (
          bodyParse?.type === NOTIFICATION_TYPES.PROJECT_CREATE ||
          bodyParse?.type === NOTIFICATION_TYPES.PROJECT_UPDATE
        ) {
          customTitle = 'Vous avez été ajouter à un projet';
          customDesc = `Vous avez été ajouter au projet 
          <strong> << ${bodyParse?.cardTitle} >> </strong> par <strong>${bodyParse?.by?.displayName}</strong>`;
        }
        if (bodyParse?.type === NOTIFICATION_TYPES.PROJECT_MANAGER) {
          customTitle = 'Vous avez été nommé gestionnaire';
          customDesc = `Vous avez été ajouter au projet 
          <strong> << ${bodyParse?.cardTitle} >> </strong> par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.TASK_VALIDATION_ASSIGN_USER) {
          customTitle = 'Vous avez été ajouté à une tâche';
          customDesc = `Vous avez été assigné à la tâche 
          <strong> << ${bodyParse?.cardTitle} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.TASK_RETRIEVE) {
          customTitle = 'Une tâche vous a été retirée';
          customDesc = `La tâche <strong> << ${bodyParse?.cardTitle} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> vous a été retirée par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.TASK_DUE_CHANGE) {
          customTitle = 'L’échéance d’une tâche qui vous est attribuée a été modifiée';
          customDesc = `L’échéance de la tâche <strong> << ${bodyParse?.cardTitle} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> a été modifiée par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.TASK_PIECE_JOINTES) {
          customTitle = 'Une tâche que vous suivez a de nouvelles pièces jointes';
          customDesc = `La tâche <strong> << ${bodyParse?.cardTitle} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> a de nouvelles pièces jointes, ajouté par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.TASK_PIECE_JOINTE) {
          customTitle = 'Une tâche que vous suivez a une nouvelle pièce jointe';
          customDesc = `La tâche <strong> << ${bodyParse?.cardTitle} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> a une nouvelle pièce jointe, ajouté par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.TASK_COMMENT_ADD) {
          customTitle = 'Un commentaire a été publié dans une tâche que vous suivez.';
          customDesc = `Un commentaire a été publié dans la tâche <strong> << ${bodyParse?.cardTitle} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.SUB_TASK_VALIDATION_ASSIGN_USER) {
          customTitle = 'Ajout à une sous tâche';
          customDesc = `Vous avez été assigné à la sous tâche <strong> <<${bodyParse?.cardTitle}>> </strong> de la tâche 
          <strong> << ${bodyParse?.other?.taskName} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.RELANCE_TASK) {
          customTitle = 'Relance sur une tâche';
          customDesc = `Vous avez été relancé sur la tâche <strong> <<${bodyParse?.cardTitle}>> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type?.includes('affect')) {
          customTitle = 'Affectation';
          customDesc = bodyParse?.description;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.CHAT_MENTION) {
          customTitle = 'Vous avez été mentionné';
          customDesc = `Vous avez été mentionné dans une coversation par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type?.includes('couriel')) {
          customTitle = bodyParse?.html?.title || bodyParse?.title;
          customDesc = bodyParse?.html?.description || bodyParse?.description;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.DOCUMENT_SHARED) {
          customTitle = 'Un document vous a été partagé';
          customDesc = `Un document vous a été partagé par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.DOCUMENT_SHARED_USER_LEAVE) {
          customTitle = 'Un utilisateur a quitté un document partagé';
          customDesc = `L'utilisateur <strong>${bodyParse?.by?.displayName}</strong> a quitté le document partagé par <strong>${bodyParse?.other?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.SEND_AUTORIZATION_FOR_ELEMENT) {
          customTitle = 'Demande d’autorisation';
          customDesc = `Vous avez une demande d’autorisation de la part de <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.ADD_FILE_TO_FOLDER) {
          customTitle = 'Fichier ajouté';
          customDesc = `Un fichier a été ajouté à <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.ACCEPTED_AUTORIZATION_FOR_ELEMENT) {
          customTitle = 'Autorisation accordée';
          customDesc = `Vous avez accordé l’autorisation à <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.REJECTED_AUTORIZATION_FOR_ELEMENT) {
          customTitle = 'Autorisation refusée';
          customDesc = `<strong>${bodyParse?.by?.displayName}</strong> a refusé l’autorisation`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.AFFECTATION_ASSIGN) {
          customTitle = 'Une affectation vous a été assignée';
          customDesc = `Vous avez été assigné à une affectation par <strong>${bodyParse?.by?.displayName}</strong>`;
        }
        if (bodyParse?.type === NOTIFICATION_TYPES.COURIEL_TO_REVIEW) {
          customTitle = 'Couriel à valider';
          customDesc = `Vous avez un couriel à valider par <strong>${bodyParse?.by?.displayName}</strong>`;
        }
        if (bodyParse?.type === NOTIFICATION_TYPES.COURIEL_TO_DO) {
          customTitle = 'Couriel à traiter';
          customDesc = `Vous avez un couriel à traiter par <strong>${bodyParse?.by?.displayName}</strong>`;
        }
        if (bodyParse?.type === NOTIFICATION_TYPES.COURIEL_CREATION) {
          customTitle = 'Nouveau couriel';
          customDesc = `Vous avez un nouveau couriel par <strong>${bodyParse?.by?.displayName}</strong>`;
        }
        if (bodyParse?.type === NOTIFICATION_TYPES.COURIEL_CO_RECEIVER) {
          customTitle = 'Couriel en copie';
          customDesc = `Vous avez été mis en copie d'un couriel par <strong>${bodyParse?.by?.displayName}</strong>`;
        }
        if (bodyParse?.type === NOTIFICATION_TYPES.COURIEL_DEFAULT_RECEIVER) {
          customTitle = 'Destinataire par défaut a été modifié';
          customDesc = `Vous êtes le destinataire par défaut d'un couriel par <strong>${bodyParse?.by?.displayName}</strong>`;
        }
        if (bodyParse?.type === NOTIFICATION_TYPES.COURIEL_HISTORY) {
          customTitle = 'Couriel en historique';
          customDesc = `Vous avez un couriel en historique par <strong>${bodyParse?.by?.displayName}</strong>`;
        }
        if (bodyParse?.type === NOTIFICATION_TYPES.COURIEL_ADD_ATTACHMENT) {
          customTitle = 'De nouvelles pièces jointes ont été ajoutées';
          customDesc = `De nouvelles pièces jointes ont été ajoutées à l'affection N° <strong>${bodyParse?.other?.save_reference}</strong> par <strong>${bodyParse?.by?.displayName}</strong>`;
        }
        if (bodyParse?.type === NOTIFICATION_TYPES.COURIEL_DELETE) {
          customTitle = 'Une Affection a été definitivement supprimée';
          customDesc = `L'affection  N° <strong>${bodyParse?.other?.save_reference}</strong> a été supprimée par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.COURIEL_ARCHIVE) {
          customTitle = 'Une Affection a été archivée';
          customDesc = `L'affection  N° <strong>${bodyParse?.other?.save_reference}</strong> a été archivée par <strong>${bodyParse?.by?.displayName}</strong>`;
        }
        if (bodyParse?.type === NOTIFICATION_TYPES.COURIEL_UN_ARCHIVE) {
          customTitle = 'Une Affection a été désarchivée';
          customDesc = `L'affection  N° <strong>${bodyParse?.other?.save_reference}</strong> a été désarchivée par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.COURIEL_TRASH) {
          customTitle = 'Une Affection a été déplacée vers la corbeille';
          customDesc = `L'affection  N° <strong>${bodyParse?.other?.save_reference}</strong> a été déplacée vers la corbeille par <strong>${bodyParse?.by?.displayName}</strong>`;
        }
        if (bodyParse?.type === NOTIFICATION_TYPES.COURIEL_UN_TRASH) {
          customTitle = 'Affection restaurée';
          customDesc = `L'affection  N° <strong>${bodyParse?.other?.save_reference}</strong> a été restaurée par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.COURIEL_ASSIGNED) {
          customTitle = 'Courrier assigné';
          customDesc = `Vous avez un courrier assigné par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        if (bodyParse?.type === NOTIFICATION_TYPES.COURIEL_REJECT) {
          customTitle = 'Affection rejeté';
          customDesc = `L'affection N° <strong>${bodyParse?.other?.save_reference}</strong> a été rejeté par <strong>${bodyParse?.by?.displayName}</strong>`;
        }

        const snackKey = enqueueSnackbar(
          <NotificationSnackbar
            open={true}
            title={customTitle}
            stopSound={stopSound}
            desc={customDesc}
            url={bodyParse?.url}
            action={bodyParse?.action}
            type={bodyParse?.type}
            id={snackId}
            onClose={handleCloseNotification}
          />,
          { persist: persist }
        );

        setNotificationIds((prev) => [...prev, { id: snackId, key: snackKey }]);
        return;
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSendChatNotif, pathname]);

  return <>{children}</>;
}
