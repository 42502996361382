import { IOkyDriverFile } from 'src/models/IOkyDriver';
import { blobToFile } from './blobToFile';

export const downloadFile = (url, name, onSuccess, onError) => {
  fetch(url)
    .then((res) => res.blob())
    .then((file) => {
      let tempUrl = URL.createObjectURL(file);
      let a = document.createElement('a');
      a.href = tempUrl;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(tempUrl);
      if (onSuccess) onSuccess();
    })
    .catch(() => {
      //console.log('erreur de telechargement du fichier');
      if (onError) onError();
    });
};

/**
 *
 * @param {{
 * files: Array<IOkyDriverFile>,
 * onSuccess: ()=>{},
 * onError: () => {}
 * }} params
 */
export const createFilesFromUrl = async ({ files, onSuccess, onError }) => {
  try {
    const blobs = await Promise.all(files.map((file) => fetch(file.url).then((response) => response.blob())));

    const _files = blobs.map((blob, index) => {
      return blobToFile(blob, files[index].name);
    });

    if (onSuccess) onSuccess(_files);
  } catch (error) {
    if (onError) onError(error);
    console.error('Error fetching files:', error);
  }
};
/**
 *
 * @param {{
 * file: IOkyDriverFile,
 * }} params
 */
export const createFileFromUrl = async ({ file }) => {
  const resp = await fetch(file.url);
  const blob = resp.blob();
  return blobToFile(blob, file.name);
};
