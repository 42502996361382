import React from 'react';
import { orderBy } from 'lodash';
import { Avatar, Box, Button, IconButton, Stack, styled, Typography } from '@mui/material';
import { fr as LocalFr } from 'date-fns/locale';
import { formatDistanceToNowStrict } from 'date-fns';
import Scrollbar from './Scrollbar';
import { MessageContainer } from 'src/section/_dashboard/chat/ChatMessageItem';
import { gDate } from 'src/utils/formatTime';
import Markdown from './Markdown';
import { useToggleV2 } from 'src/hooks/useToggle';
import { Icon } from '@iconify/react';
import MenuPopover from './MenuPopover';
import createAvatar from 'src/utils/createAvatar';
import { baseColors } from 'src/constants/color';
import { MenuLine } from './MenuLine';
import MusicPlayerSlider from './MusicPlayerSlider';
import { SELECT_OPTIONS, TaskHistoryLine } from 'src/section/tasks/v4/TaskItemDetail';

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(0)
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(0.75),
    fontSize: 10,
    color: theme.palette.text.secondary
}));

export default function CustomCommentList({ list = [], user, details, onDeleteComment, view = null }) {
    const scrollRef = React.useRef();
    const history = details?.metadata?.history;
    const [showAll, setShowAll] = React.useState(false);
    const [showAllActivity, setShowAllActivity] = React.useState(false);
    const [showAllComments, setShowAllComments] = React.useState(false);

    // console.log({ list, details, view });

    const activities = React.useMemo(() => {
        return orderBy(history, "createdAt", 'desc').slice(0, showAllActivity ? undefined : 3).reverse();
    }, [history, showAllActivity]);

    const comments = React.useMemo(() => {
        return orderBy(list, "createdAt", 'desc').slice(0, showAllComments ? undefined : 5).reverse();
    }, [list, showAllComments]);

    const all = React.useMemo(() => {
        let mergedList = list || [];
        if (view === SELECT_OPTIONS?.ALL) {
            mergedList = [...(list || []), ...(history || [])];
        }
        return orderBy(mergedList, "createdAt").slice(0, showAll ? undefined : 5);
    }, [list, history, view, showAll]);

    React.useEffect(() => {
        const scrollMessagesToBottom = () => {
            if (scrollRef.current) {
                scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
            }
        };
        scrollMessagesToBottom();
    }, [list]);

    const handleShowMore = () => {
        setShowAll(true);
    };

    return (
        <Scrollbar
            scrollableNodeProps={{ ref: scrollRef }}
            sx={{ p: 1, flexGrow: 1 }}
        >
            <Stack direction="column" spacing={1} sx={{ borderBottom: '1px solid #d9dde1', py: 0.5 }}>
                {(activities?.length >= 3 && !showAllActivity && view === SELECT_OPTIONS?.ALL) && (
                    <Button

                        onClick={() => setShowAllActivity(true)}
                        fullWidth

                    >
                        Voir plus d'activités
                    </Button>
                )}
                {activities?.length ? activities.map((comment, idx) => {
                    if (comment?.actionType && view === SELECT_OPTIONS?.ALL) {
                        return <TaskHistoryLine key={idx} task={comment} />;
                    }

                }) : null}

            </Stack>
            <Stack >
                {(comments?.length >= 5 && !showAllComments) && (
                    <Button
                        onClick={() => setShowAllComments(true)}
                        fullWidth
                    >
                        Voir plus de commentaires
                    </Button>
                )}

                {comments?.length ? comments.map((comment, idx) => {

                    return <CommentItem key={comment.id + idx} comment={comment} details={details} user={user} onDeleteComment={onDeleteComment}
                        sx={{
                            backgroundColor: baseColors.GREYLIGHTER,
                            padding: 1,
                            borderRadius: 0,
                        }}
                    />;
                }) : null}
            </Stack>
        </Scrollbar>
    );
}

const CommentItem = ({ comment, details, user, onDeleteComment, sx = {} }) => {
    const deleteRef = React.useRef();
    const [hover, onHover, endHover] = useToggleV2();
    const [openD, onOpenD, closeD] = useToggleV2();
    const [openM, onOpenM, closeM] = useToggleV2();
    const isMe = React.useMemo(() => comment.userId === user?.id, [comment, user?.id]);
    const anchorEl = React.useRef();

    const message = React.useMemo(() => {
        return comment?.message?.replace('contenteditable="false"', 'suppressContentEditableWarning="true"');
    }, [comment]);

    const deleteComment = () => {
        onDeleteComment({ id: comment.id, details });
        closeD();
    };

    return (
        <RootStyle>
            <Box
                onMouseEnter={onHover}
                onMouseLeave={endHover}
                ref={deleteRef}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    width: 1,
                    borderBottom: '1px solid #d9dde1',
                    ...sx
                }}
            >

                <Stack direction="row" alignItems="center" spacing={1} width={1}>

                    <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" height={1} sx={{ backgroundColor: isMe ? baseColors.green : baseColors.grey }}>
                        <Avatar
                            size="small"
                            // srcSet={part?.avatar}
                            // src={part?.avatar}
                            sx={{ width: 30, height: 30, fontSize: 10 }}
                            style={{ backgroundColor: createAvatar(comment.name).color, color: 'ButtonText', fontSize: 10 }}
                        >
                            {createAvatar(comment.name).name}
                        </Avatar>
                    </Stack>

                    <Stack width={1}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width={1}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                    noWrap
                                    maxHeight={20}
                                    variant="subtitle2"
                                    sx={{ mx: 2 }} // Marge horizontale autour du nom du commentateur
                                >
                                    {comment.name}
                                </Typography>

                                <Typography
                                    noWrap
                                    maxHeight={20}
                                    variant="caption"
                                    sx={{ fontSize: 10, color: baseColors.grey }} // Marge horizontale autour du nom du commentateur

                                >
                                    {comment?.createdAt &&
                                        formatDistanceToNowStrict(gDate(comment?.createdAt), {
                                            addSuffix: true,
                                            locale: LocalFr
                                        })}
                                </Typography>
                                {hover &&
                                    <IconButton
                                        ref={anchorEl}
                                        onClick={onOpenM}
                                        size="small"
                                        sx={{ position: 'relative' }}
                                    >
                                        <Icon icon="eva:arrow-ios-downward-fill" />
                                    </IconButton>
                                }

                            </Box>
                        </Stack>
                        <Stack direction="row" width={1}>
                            <MessageContainer isMe={isMe}
                                sx={{
                                    m: 0,
                                    pb: 0,
                                    pt: 1,
                                    px: 1,
                                    width: 1,
                                    maxWidth: 1,
                                    borderRadius: 1,
                                    selfAlign: 'center',
                                    bgcolor: 'transparent'
                                }}
                            >
                                {comment.messageType === 'audio' ? (
                                    <MusicPlayerSlider
                                        song={comment.message}
                                    />
                                ) : (
                                    <Markdown children={message} sx={{ p: 0 }} />
                                )}
                            </MessageContainer>
                        </Stack>
                    </Stack>



                    {
                        openM && (
                            <MenuPopover open={openM} onClose={closeM} width={200} anchorEl={anchorEl.current} disabledArrow
                                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            >
                                <MenuLine title="Supprimer" color={baseColors.RED} icon="eva:trash-fill" onClick={() => { onOpenD(); closeM() }} />
                            </MenuPopover>
                        )
                    }
                    {/* {hover && (
                        <IconButton onClick={onOpenD} size="small" sx={{ p: 0, alignSelf: 'flex-end', position: 'absolute', right: 0 }}>
                           
                            <Icon icon="eva:trash-fill" color="red" height={15} />
                        </IconButton>
                    )} */}
                </Stack>
            </Box>

            {
                openD && (
                    <MenuPopover
                        open={openD}
                        onClose={closeD}
                        anchorEl={deleteRef.current}
                        horizontal="center"
                        anchorOrigin="top"
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Box sx={{ p: 1 }}>
                            <Typography fontSize={13}>Êtes-vous sûr de vouloir supprimer ce commentaire ?</Typography>
                            <Stack direction="row" justifyContent="space-between">
                                <Button color="inherit" onClick={closeD}>Annuler</Button>
                                <Button color="error" onClick={deleteComment}>Supprimer</Button>
                            </Stack>
                        </Box>
                    </MenuPopover>
                )
            }
        </RootStyle >
    );

}
