import { Icon } from '@iconify/react';
import React, { useMemo } from 'react';
import { IconButtonAnimate } from 'src/components/animate';
import { themeColor } from 'src/constants/color';
import { dateConverter } from 'src/helpers/dueDateConverter';
import useDateRangePicker from 'src/hooks/useDateRangePicker';
import { TaskType } from 'src/models/task_m';
import { dispatch } from 'src/redux/store';
import DatePickerDialog from 'src/components/DatePickerDialog';
import DisplayTime from 'src/components/DisplayTime';
import { useSnackbar } from 'notistack';
import { useToggleV2 } from 'src/hooks/useToggle';
import ActionModal from 'src/components/ActionModal';
import { LoadingButton } from '@mui/lab';
import { getCurrentUserAccess } from 'src/helpers/user';
import { useProjectData } from 'src/section/project/v4/content/project_content/kanban/V4ProjectCotentKanbanCard';
import { updateProject } from 'src/redux/slices/kanban';
import { ProjectDeadlineExtendNotification, ProjectDeadlineExtendsPermissionNotification } from 'src/redux/slices/notifications';
import { useDatePicker } from 'src/section/tasks/kanban/KanbanTaskAdd';
import { Stack, Typography } from '@mui/material';
import OkyCalendar from 'src/components/calendar/OkyCalendar';
import { isEqual } from 'lodash';

function convertToTimestamp(date) {
  let timestamp;

  if (date instanceof Date) {
    timestamp = date.getTime();
  } else if (typeof date === 'string') {
    timestamp = Date.parse(date);
  } else if (typeof date === 'object' && date?.seconds && typeof date?.seconds === 'number') {
    timestamp = date?.seconds * 1000; // Convert seconds to milliseconds
  } else {
    //console.log(date);
    return null;
    //throw new Error('Unsupported date format');
  }

  // Create a new Date object from the timestamp
  let dateObject = new Date(timestamp);

  // Set the hours and minutes to 00
  dateObject.setHours(0, 0, 0, 0);

  // Get the new timestamp
  timestamp = dateObject.getTime();

  return timestamp;
}

/**
 *
 * @param {{task : TaskType, onUpdate: function, disabled: boolean, enabledDataInterval: boolean}} props
 * @returns
 */
export default function TaskDueDate({ task, onUpdate, disabled = false, enabledDataInterval = null }) {

  const { startTime, endTime, onChangeDueDate, openPicker, onOpenPicker, onClosePicker, dueDate, isSameDays, isSameMonths } = useDatePicker({
    date: dateConverter(task?.due)
  });

  //console.log({ startTime, endTime });

  const { enqueueSnackbar } = useSnackbar();
  const [openExtend, showExtend, HideExtend] = useToggleV2();
  const projectKey = task?.projectKey;;
  const { project } = useProjectData(projectKey);
  const [isStartTimeSelected, setIsStartTimeSelected] = React.useState(null);
  const [tempDate, setTempDate] = React.useState(null);
  const iniTaskDate = useMemo(() => { return task?.due; }, [task]);
  const [isLoading, setIsLoading] = React.useState(false);
  const anchorEl = React.useRef(null);


  const onOpenPickerDisabled = () => {
    if (disabled) {
      return;
    }
    onOpenPicker();
  };
  const currentUser = getCurrentUserAccess();

  const handleClosePicker = () => {
    HideExtend();
    onClosePicker();
  };

  const handleCloseExtend = () => {
    //reset startTime and endTime
    setTempDate(null);
    HideExtend();
  };
  const currentUserRights = useMemo(() => {
    if (project?.createBy?.id === currentUser.id) return { delete: true, edit: true, create: true };

    if (project?.canAccessId?.includes(currentUser?.id)) {
      return (
        project?.canAccess?.find((user) => user?.id === currentUser?.id)?.rights ||
        project?.managers?.find((user) => user?.id === currentUser?.id)?.rights
      );
    }

    return { delete: false, edit: false, create: true };
  }, [project, currentUser]);


  const extendDate = () => {
    let start = tempDate?.isStartTimeSelected ? tempDate?.dates[0] : enabledDataInterval?.start || new Date();

    let end = tempDate?.isStartTimeSelected ? enabledDataInterval?.end || new Date() : tempDate?.dates[1]

    if (!currentUserRights.edit) {
      enqueueSnackbar("Vous n'avez pas les droits pour modifier la date du projet", { variant: 'error' });
      dispatch(ProjectDeadlineExtendsPermissionNotification({ project, deadline: tempDate?.date }));
      return;
    }

    //console.log(enabledDataInterval);
    //tempDate  = new Date('2023-09-01T17:22:50.000Z')

    setIsLoading(true);
    dispatch(updateProject(
      {
        ...project, dataInterval: {
          start,
          end
        }
      },
      project,
      () => {
        enqueueSnackbar(`La date a été prolongée du ${isStartTimeSelected
          ? convertTimestampToHumanReadable(
            convertToTimestamp(enabledDataInterval?.start)
          )
          : convertTimestampToHumanReadable(
            convertToTimestamp(enabledDataInterval?.end)
          )} vers le  
            ${convertTimestampToHumanReadable(convertToTimestamp(tempDate?.date))}`, { variant: 'success' }
        );
        //isStartTimeSelected ? onChangeStartTime(date) : onChangeEndTime(date);
        HideExtend();
        onSave();
        onChangeDueDate(tempDate?.dates);
        dispatch(ProjectDeadlineExtendNotification({ project, deadline: tempDate?.date }));
        dispatch(onUpdate(task, { due: tempDate?.dates }));
        setIsLoading(false);
      }
    ));

  }

  const handleDueDateChange = (_date) => {
    // console.log(_date);
    const date = _date.startDate && _date.endDate ? [_date.startDate, _date.endDate] : _date
    if (date[0] === null && date[1] === null) return;
    const isStartTimeSelected = convertToTimestamp(date[0]) !== convertToTimestamp(startTime);
    const dateIsInInterval = convertToTimestamp(date[1]) <= convertToTimestamp(enabledDataInterval?.end) && convertToTimestamp(date[1]) >= convertToTimestamp(enabledDataInterval?.start);
    if (!dateIsInInterval) {
      setTempDate({
        date: isStartTimeSelected ? date[0] : date[1],
        dates: date,
        isStartTimeSelected: isStartTimeSelected
      });
      showExtend();
      //onClosePicker();
      return;
    }
    onChangeDueDate(date);
    dispatch(onUpdate(task, { due: date }));

  };


  const onSave = () => {
    const _startTime = new Date(startTime).toISOString();
    const _endTime = new Date(endTime).toISOString();

    const values = [new Date(_startTime), new Date(_endTime)];
    dispatch(onUpdate(task, { due: values }));
  };

  function convertTimestampToHumanReadable(timestamp) {
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('fr-FR', options);
  }

  return (
    <>
      {startTime || endTime ? (
        <DisplayTime
          startTime={startTime}
          endTime={endTime}
          isSameDays={isSameDays}
          isSameMonths={isSameMonths}
          onOpenPicker={onOpenPickerDisabled}
          sx={{ typography: 'body2' }}
          anchor={anchorEl}
        />
      ) : (
        <IconButtonAnimate onClick={onOpenPickerDisabled} sx={{ p: 0.5 }} ref={anchorEl}>
          <Icon icon="clarity:calendar-line" color={themeColor.CARROT_ORANGE} />
        </IconButtonAnimate>
      )}

      {!disabled && openPicker && (
        <OkyCalendar
          dates={[startTime, endTime]}
          open={openPicker}
          onClose={handleClosePicker}
          onChange={handleDueDateChange}
          anchorEl={anchorEl.current}
          dataInterval={enabledDataInterval}
          disabled={disabled}
        />
      )}
      {(
        <ActionModal
          title={`Prolonger l'echeance du projet`}
          desc={
            <Stack spacing={2}>
              <Typography>
                {`Voulez-vous prolonger la date de ${convertToTimestamp(tempDate?.date) < convertToTimestamp(enabledDataInterval?.start)
                  ? 'début'
                  : convertToTimestamp(tempDate?.date) > convertToTimestamp(enabledDataInterval?.end)
                    ? 'fin'
                    : tempDate?.isStartTimeSelected ? 'début' : 'fin'
                  } du projet `}
                {
                  enabledDataInterval?.start && enabledDataInterval?.end &&
                  <>
                    {` du `}
                    <Typography component="span" color="#000" fontWeight={700}>
                      {tempDate?.isStartTimeSelected ? convertTimestampToHumanReadable(convertToTimestamp(enabledDataInterval?.start)) : convertTimestampToHumanReadable(convertToTimestamp(enabledDataInterval?.end))}
                    </Typography>
                  </>
                }
                {` au `}
                <Typography component="span" color="#000" fontWeight={700}>
                  {convertTimestampToHumanReadable(convertToTimestamp(tempDate?.date))}
                </Typography>
                {` ?`}
              </Typography>
            </Stack>
          }
          open={openExtend}
          onClose={handleCloseExtend}
          action={
            <LoadingButton
              color="warning"
              variant="contained"
              disabled={disabled}
              loading={isLoading}
              onClick={() => {
                extendDate();
              }}
            >
              Continuer
            </LoadingButton>
          }
        />
      )}
    </>
  );
}